import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import Button from "components/Button";

type Props = {
  open: boolean;
  onClose: any;
  onConfirm: () => void;
};

export default function ConfirmInActivateProjectDialog({
  open,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogContent>
        <div className="text-xl text-rw-blue font-semibold text-center">
          Are you sure you want to stop running your project?
        </div>

        <div className="font-medium text-center mt-8">
          Your project won&apos;t be visible to students.
        </div>

        <Button
          className="text-sm font-semibold px-6 w-full mt-4"
          onClick={onConfirm}
        >
          Stop Project
        </Button>

        <Button
          variant="secondary"
          className="text-sm font-semibold px-6 w-full mt-4"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
}
