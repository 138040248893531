import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { googleLogout } from "@react-oauth/google";
import * as amplitude from "@amplitude/analytics-browser";
// import { Divider } from "@mui/material";
// import Switch from "@mui/material/Switch";
import Link from "@mui/material/Link";
// import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import Button from "components/Button";
import TextField from "components/TextField";
// import UserDetails from "components/UserDetails";
import { useMergeState } from "utils/custom-hooks";
import { listPaymentMethods, updateProfile } from "api";
import { validateEmail } from "utils/string";
import { toBase64, wrapFullName, wrapNameTwoLetters } from "utils/common";
import { AccountType, RUNWAY_TOKEN } from "utils/constants";
import AddNewCardDialog from "components/AddNewCardDialog";

type Props = {
  user: any;
  manuBarExpend: boolean;
  setUser: any;
  manuBarToogle: () => void;
  profileImageUrl: string;
  goToSettings?: boolean;
};

// const breadcrumbs = [
//   <Link underline="hover" key="1" color="inherit" href="/settings">
//     <span className="text-rw-gray-75 font-medium">Profile</span>
//   </Link>,
//   <Link underline="hover" key="2" color="inherit" href="/settings/">
//     <span className="text-rw-blue font-semibold">Profile Details</span>
//   </Link>,
// ];

export default function BusinessProfileSettings({
  user,
  manuBarExpend,
  setUser,
  manuBarToogle,
  profileImageUrl,
  goToSettings = false,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    avatar: "",
    avatarFile: null,
    avatarBase64: null,
    companyLogoFile: null,
    companyLogoBase64: null,

    firstName: "",
    lastName: "",
    email: "",
    phone: "",

    companyName: "",

    notificationSettings: {
      email: {
        newInvoices: false,
        invoiceReminder: false,
        newCandidate: false,
      },
      sms: {},
    },

    isEditable: false,
    isDisableBtn: false,

    shouldShowAddNewCardDialog: false,

    isFetchingPaymentMethods: false,
    cards: [],
    activeTab: "Profile",

    errors: {},
  });

  const avatarRef = useRef<any>();
  const companyLogoRef = useRef<any>();
  const isProfileTab = state.activeTab === "Profile";

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleNotificationSettingsChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    try {
      const notificationSettings = {
        ...state?.notificationSettings,
        [type]: {
          ...state?.notificationSettings[type],
          [event?.target?.name]: event?.target?.checked,
        },
      };

      setState({
        notificationSettings,
      });

      const response = await updateProfile({
        notificationSettings,
      });

      if (response?.success) {
        enqueueSnackbar("Notification settings updated successfully.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleToggleEdit = () => {
    setState({ isEditable: !state?.isEditable });
  };

  const handleCompanyLogo = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event?.target?.files && event?.target?.files[0];

      if (!file) {
        return;
      }

      event.target.value = "";

      if (file?.name.includes("png") || file?.name.includes("jpg")) {
        const base64 = await toBase64(file);
        setState({
          companyLogoFile: file,
          companyLogoBase64: base64,
          errors: {
            ...state?.errors,
            companyLogo: "",
          },
        });
      } else {
        setState({
          companyLogoFile: null,
          companyLogoBase64: null,
          errors: {
            ...state?.errors,
            companyLogo: "Image type should be .png or .jpg",
          },
        });
      }

      // const base64 = await toBase64(file);

      // setState({
      //   companyLogoFile: file,
      //   companyLogoBase64: base64,
      // });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleCompanyLogoRef = () => {
    companyLogoRef?.current?.click();
  };

  // const handleAvatarChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   try {
  //     const file = event?.target?.files && event?.target?.files[0];

  //     if (!file) {
  //       return;
  //     }

  //     event.target.value = "";

  //     const base64 = await toBase64(file);

  //     // setState({
  //     //   avatarFile: file,
  //     //   avatarBase64: base64,
  //     // });

  //     const response = await updateProfile({
  //       avatar: state?.avatar,
  //       avatarBase64: base64,
  //     });

  //     if (response?.success) {
  //       const userInfo = response?.data?.user;
  //       userInfo.business = { name: state.companyName };
  //       userInfo.avatar = { ...userInfo.avatar, url: base64 };
  //       setState({
  //         avatarFile: file,
  //         avatarBase64: base64,
  //       });
  //       setUser(userInfo);
  //       enqueueSnackbar("Profile avatar updated successfully.", {
  //         variant: "success",
  //       });
  //     }
  //   } catch (error: any) {
  //     enqueueSnackbar(error?.message, { variant: "error" });
  //   }
  // };

  // const handleAvatarRef = () => {
  //   avatarRef?.current?.click();
  // };

  const fetchPaymentMethods = async () => {
    try {
      setState({ isFetchingPaymentMethods: true });

      const response = await listPaymentMethods();

      setState({
        cards: response?.data?.cards,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isFetchingPaymentMethods: false });
    }
  };

  const handleOpenAddNewCardDialog = () => {
    setState({ shouldShowAddNewCardDialog: true });
  };

  const handleCloseAddNewCardDialog = async (
    shouldFetchPaymentMethods?: boolean
  ) => {
    setState({ shouldShowAddNewCardDialog: false });

    if (shouldFetchPaymentMethods) {
      await fetchPaymentMethods();
    }
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (!state?.firstName) {
      isValid = false;
      errors = { ...errors, firstName: "First Name is required." };
    }

    if (!state?.lastName) {
      isValid = false;
      errors = { ...errors, lastName: "Last Name is required." };
    }

    if (!validateEmail(state?.email)) {
      isValid = false;
      errors = { ...errors, email: "Email is invalid." };
    }

    if (!state?.phone) {
      isValid = false;
      errors = { ...errors, phone: "Phone is required." };
    }

    if (!state?.companyName) {
      isValid = false;
      errors = { ...errors, phone: "Company Name is required." };
    }

    setState({ errors });

    return isValid;
  };

  const handleUpdateProfile = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isDisableBtn: true });

      const response = await updateProfile({
        firstName: state?.firstName,
        lastName: state?.lastName,
        email: state?.email,
        phone: state?.phone,
        companyName: state?.companyName,
        companyLogoBase64: state?.companyLogoBase64,
      });

      if (response?.success) {
        const userInfo = response?.data?.user;
        setState({ isEditable: false, isDisableBtn: false });
        userInfo.business = { name: state.companyName };
        setUser(response?.data?.user);
        enqueueSnackbar(response?.message, { variant: "success" });
      } else {
        setState({ isDisableBtn: false });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleLogout = async () => {
    const is_social_login = await localStorage.getItem("is_social_login");
    if (is_social_login && is_social_login === "true") {
      googleLogout();
      localStorage.removeItem("is_social_login");
    }
    localStorage.removeItem(RUNWAY_TOKEN);
    amplitude.reset();
    window.location.href = "/";
  };

  const init = async () => {
    try {
      await fetchPaymentMethods();

      setState({
        avatar: user?.avatar,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        notificationSettings: user?.notificationSettings,

        // business
        companyName: user?.business?.name,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (goToSettings) {
      setState({ activeTab: "Settings" });
    }
  }, [goToSettings]);

  const profileLatters = (
    <div className="profile_image" style={{ width: 75, height: 75 }}>
      <p className="profileText" style={{ fontSize: 30 }}>
        {wrapNameTwoLetters(user?.firstName, user?.lastName)}
      </p>
    </div>
  );

  const isProfileImage = state?.avatar?.url || state?.avatarBase64;

  return (
    <div>
      <header
        className={`header ${manuBarExpend ? "body_pd" : ""}`}
        id="header"
      >
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        <p className="fw-bold fs-5 ms-4 mb-0 lh-sm">My Profile</p>
      </header>
      <div className="px-0 px-md-3 w-100 mt-0 mt-md-3 mb-3">
        <div className="p-3 p-md-4 bg_white justify-content-center border border_gray border_r_16px border_sm_r_0">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              {profileImageUrl?.length > 0 ? (
                <img
                  src={profileImageUrl}
                  style={{ width: 75, height: 75, borderRadius: 100 }}
                />
              ) : (
                profileLatters
              )}
              <div className="ms-3">
                <p className="fs-4 fw-bold mb-0">{`${user?.firstName || ""} ${
                  user?.lastName || ""
                }`}</p>
                <p className="fs-6 text_primary mb-0">
                  {user?.business?.name || ""}
                </p>
              </div>
            </div>
            {!state.isEditable ? (
              <div className="mt-2 mt-md-0 w_sm_100">
                <button
                  type="button"
                  className="btn btn_small bg_secondary w_sm_100 my-2 equal_btn mr-1"
                  onClick={handleToggleEdit}
                >
                  Edit Profile
                </button>
                <button
                  type="button"
                  className="btn btn_small bg_light_redish text_redish w_sm_100 my-2 equal_btn"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="mt-2 mt-md-0 w_sm_100">
                <button
                  type="button"
                  className="btn btn_small bg-white border border_secondary text_secondary w_sm_100 my-2 equal_btn mr-1"
                  onClick={handleToggleEdit}
                >
                  Cancel
                </button>
                <button
                  disabled={state.isDisableBtn}
                  type="button"
                  className={`btn btn_small bg_secondary text_white w_sm_100 my-2 equal_btn ${
                    state.isDisableBtn ? "bg-rw-disable" : ""
                  }`}
                  onClick={handleUpdateProfile}
                >
                  Save Profile
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-3 px-md-4 pb-2 w-100 mb-2 mb-md-0 pb-2">
        <ul className="nav nav-tabs custom_nav_tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link border-0 ${isProfileTab ? "active" : ""}`}
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
              onClick={() => setState({ activeTab: "Profile" })}
            >
              Profile{" "}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link border-0 ${!isProfileTab ? "active" : ""}`}
              id="setting-tab"
              data-bs-toggle="tab"
              data-bs-target="#setting"
              type="button"
              role="tab"
              aria-controls="setting"
              aria-selected="false"
              onClick={() => setState({ activeTab: "Settings" })}
            >
              Settings
            </button>
          </li>
        </ul>
      </div>
      <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-4 pb-md-4 overflow-hidden">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade w-100 px-0 px-md-3 py-1 show active">
            <div className="row">
              {isProfileTab ? (
                <>
                  <div className="col-12 col-md-12 col-lg-6 mb-3 mb-lg-0">
                    <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                      <p className="fw-bold text_black fs-5 pb-4">
                        Profile Details
                      </p>
                      <label className="mb-2">Email</label>
                      <TextField
                        type="text"
                        name="email"
                        value={state?.email}
                        onChange={handleChange}
                        error={state?.errors?.email}
                        // disabled={!state?.isEditable}
                        disabled
                        placeholder="ford@joinrunway.io"
                      />
                      <div className="d-flex justify-content-between">
                        <div className="w-100 me-2">
                          <label className="mb-2">First Name</label>
                          <TextField
                            type="text"
                            name="firstName"
                            value={state?.firstName}
                            onChange={handleChange}
                            error={state?.errors?.firstName}
                            disabled={!state?.isEditable}
                            placeholder="Ford"
                          />
                        </div>
                        <div className="w-100 ms-2">
                          <label className="mb-2">Last Name</label>
                          <TextField
                            type="text"
                            name="lastName"
                            value={state?.lastName}
                            onChange={handleChange}
                            error={state?.errors?.lastName}
                            disabled={!state?.isEditable}
                            placeholder="Coleman"
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <label className="mb-2">Phone</label>
                        <TextField
                          type="number"
                          name="phone"
                          value={state?.phone}
                          onChange={handleChange}
                          error={state?.errors?.phone}
                          // disabled={!state?.isEditable}
                          disabled
                          onWheel={(event: any) => event.target.blur()}
                          onInput={(event: any) => {
                            if (event?.target?.value) {
                              event.target.value = Math.max(
                                0,
                                parseInt(event.target.value, 10)
                              )
                                .toString()
                                .slice(0, 10);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                      <p className="fw-bold text_black fs-5 pb-4">
                        Company Details
                      </p>

                      <label className="mb-2">Company Name</label>
                      <TextField
                        type="text"
                        name="companyName"
                        value={state?.companyName}
                        onChange={handleChange}
                        error={state?.errors?.companyName}
                        disabled={!state?.isEditable}
                        placeholder="Runway Services"
                      />
                      <label className="mb-2">Company Logo</label>
                      {state?.companyLogoBase64 ? (
                        <div className="flex justify-center mb-12">
                          <div className="bg-rw-blue rounded-full w-24 h-24 flex justify-center items-center relative">
                            <span className="text-white text-xl font-semibold">
                              <img
                                className="rounded-full object-cover"
                                src={state?.companyLogoBase64}
                              />
                            </span>

                            <button
                              className="absolute bottom-0 right-0 bg-white rounded-full flex justify-center items-center text-rw-blue w-6 h-6 border-[1px] border-rw-blue"
                              onClick={handleCompanyLogoRef}
                            >
                              <EditOutlinedIcon
                                sx={{ width: 14, height: 14 }}
                              />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={handleCompanyLogoRef}
                          className={`border border_dark_gray dashed p-5 text-center border_r_8px ${
                            state?.isEditable ? "cursor_pointer" : ""
                          }`}
                        >
                          <label
                            className={
                              state?.isEditable ? "cursor_pointer" : ""
                            }
                          >
                            <p className="fs-4 text_light_primary">
                              <i className="fa-light fa-cloud-arrow-up" />
                            </p>
                            <p className="text_light_primary fw-bold fs-6">
                              {" "}
                              UPLOAD JPG or PNG FILE{" "}
                            </p>
                            <p className="text_light_primary font_10">
                              Drag and drop files here
                            </p>
                          </label>
                        </div>
                      )}
                      <input
                        disabled={!state?.isEditable}
                        type="file"
                        name="companyLogo"
                        id="file"
                        accept="image/png, image/jpg"
                        ref={companyLogoRef}
                        onChange={handleCompanyLogo}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-12 col-lg-6 mb-3 mb-lg-0">
                    <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                      <div className="d-flex justify-content-between pb-4">
                        <p className="fw-bold text_black fs-5">Payment</p>
                        {state?.cards?.length === 0 && (
                          <button
                            type="button"
                            className="btn btn_small bg_secondary"
                            onClick={handleOpenAddNewCardDialog}
                          >
                            Add New Card
                          </button>
                        )}
                      </div>
                      <table className="table card_payment">
                        <thead>
                          <th className="fs-6 fw-normal">Card Number</th>
                          <th className="fs-6 fw-normal">Expiration Date</th>
                        </thead>
                        <tbody>
                          {state?.cards?.map((card: any) => (
                            <tr>
                              <td className="py-3">
                                <span className="fw-bold">
                                  {`**** **** **** ${card?.lastFourDigits}`}
                                </span>
                                {/* <span className="text_secondary ms-4">
                                  Set as default
                                </span> */}
                              </td>
                              <td className="py-3 fw_500 text_black">
                                {card?.expiryMonth}/{card?.expiryYear}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0 mb-4">
                      <p className="fs-5 fw-bold pb-4">Email Notifcations</p>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p>New Invoices</p>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="newInvoices"
                            checked={
                              state?.notificationSettings?.email?.newInvoices
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleNotificationSettingsChange(event, "email")
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <p>Invoice Reminder</p>
                          <p className="font_12 text_light_primary">
                            2 days before invoice is autopaid
                          </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="invoiceReminder"
                            checked={
                              state?.notificationSettings?.email
                                ?.invoiceReminder
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleNotificationSettingsChange(event, "email")
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p>New Applicants</p>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="newCandidate"
                            checked={
                              state?.notificationSettings?.email?.newCandidate
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleNotificationSettingsChange(event, "email")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {state?.shouldShowAddNewCardDialog && (
        <AddNewCardDialog
          user={user}
          open={state?.shouldShowAddNewCardDialog}
          onClose={handleCloseAddNewCardDialog}
        />
      )}
    </div>
  );
}
