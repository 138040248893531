import React from "react";
import plusIcon from "../../../../../assets/imgs/plus_icon.svg";
import InterviewTimesBox from "../InterviewTimesBox";

type Props = {
  interviewWindow: [];
  goTONextRenderState: (value: number) => void;
  setInterviewWindow: (index: number) => void;
};
const SelectInterviewWindow = ({
  interviewWindow,
  goTONextRenderState,
  setInterviewWindow,
}: Props) => {
  const generateIndex = (value: number) => {
    const slots = [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ];
    const newVal = (value / 60 - 9) * 2;
    return slots[newVal];
  };
  return (
    <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-5 pb-md-0 mt-3">
      <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mb-4 mb-md-0 p-4">
        <p className="fs-4 fw-bold text_primary mb-4">
          Select interview Windows
        </p>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
            <div
              className="interview_window d-flex align-items-center justify-content-center"
              onClick={() => goTONextRenderState(1)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <div className="text-center d-flex justify-content-center">
                  <img src={plusIcon} />
                </div>
                <p className="font_14 fw-bold text_primary mt-3 text-center">
                  Create Interview Windows
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
            <div
              className="interview_window d-flex align-items-center justify-content-center"
              onClick={() => goTONextRenderState(3)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <div className="text-center d-flex justify-content-center">
                  <img src={plusIcon} />
                </div>
                <p className="font_14 fw-bold text_primary mt-3 text-center">
                  Add External Calendar Link
                </p>
              </div>
            </div>
          </div>
          {/* window start */}

          {interviewWindow.map((data: any, index: number) => (
            <InterviewTimesBox
              data={data}
              index={index}
              setInterviewWindow={setInterviewWindow}
            />
            // <div key={index} className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
            //   <div className="interview_window p-0 bg_light">
            //     <div className="d-flex justify-content-between p-3 bg_white">
            //       <p className="font_14 fw-bold text_primary">{data.name}</p>
            //       <div className="form-check">
            //         <input
            //           className="form-check-input"
            //           type="checkbox"
            //           value=""
            //           id="flexCheckDefault"
            //         />
            //       </div>
            //     </div>

            //     <div className="p-3 border_r_8px bg_light">
            //       {data.monday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Monday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.monday.times[0])} -{" "}
            //             {generateIndex(data.monday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.tuesday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Tuesday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.tuesday.times[0])} -{" "}
            //             {generateIndex(data.tuesday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.wednesday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Wednesday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.wednesday.times[0])} -{" "}
            //             {generateIndex(data.wednesday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.thursday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Thursday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.thursday.times[0])} -{" "}
            //             {generateIndex(data.thursday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.friday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Friday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.friday.times[0])} -{" "}
            //             {generateIndex(data.friday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.saturday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Saturday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.saturday.times[0])} -{" "}
            //             {generateIndex(data.saturday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //       {data.sunday.enabled && (
            //         <div className="d-flex justify-content-between mb-1 flex-wrap">
            //           <p className="text_primary fw-bold font_14">Sunday</p>
            //           <p className="text_rgba_50 font_14 fw-bold">
            //             {generateIndex(data.sunday.times[0])} -{" "}
            //             {generateIndex(data.sunday.times[1])}
            //           </p>
            //         </div>
            //       )}
            //     </div>
            //   </div>
            // </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectInterviewWindow;
