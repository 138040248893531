import Images from "assets/images";

export const RUNWAY_TOKEN = "RUNWAY_TOKEN";

export const RUNWAY_CONTACT_EMAIL = "contact@joinrunway.io";

export const RUNWAY_CALENDLY_URL = "https://calendly.com/joinrunway";

export const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const AccountType = {
  STUDENT: "STUDENT",
  BUSINESS: "BUSINESS",
};

export const ProjectStatus = {
  ACTIVE: "ACTIVE", // LIVE
  IN_ACTIVE: "IN_ACTIVE", // OFFLINE
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  DRAFT: "DRAFT",
};

export const StudentProjectStatus = {
  APPLIED: "APPLIED",
  REQUESTED_TO_INTERVIEW: "REQUESTED_TO_INTERVIEW",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
};

export const StudentProjectIntent = {
  APPLY: "APPLY",
  UN_APPLY: "UN_APPLY",
};

export const ProjectType = {
  DEVELOPER: "DEVELOPER",
  DESIGNER: "DESIGNER",
  ENGINEER: "ENGINEER",
  BUSINESS: "BUSINESS",
};

export const StudentTypes = [
  {
    icon: Images.Developer,
    title: "Developer",
    description: "Front-end, back-end, full-stack, etc",
    value: ProjectType.DEVELOPER,
  },
  {
    icon: Images.Designer,
    title: "Designer",
    description: "Digital Product, Graphic, UI/UX, etc",
    value: ProjectType.DESIGNER,
  },
  {
    icon: Images.Engineer,
    title: "Engineer",
    description: "Mechanical, Electrical, Computer, etc",
    value: ProjectType.ENGINEER,
  },
  {
    icon: Images.Business,
    title: "Business",
    description: "Sales, Marketing, Data Analytics, etc",
    value: ProjectType.BUSINESS,
  },
];

export const SampleSkills = [
  "REACT JS",
  "FULL STACK",
  "HUBSPOT",
  "FIGMA",
  "SELF SUFFICENT",
];

// export const HourlyRate = {
//   [ProjectType.DEVELOPER]: 28,
//   [ProjectType.DESIGNER]: 24,
//   [ProjectType.ENGINEER]: 24,
//   [ProjectType.BUSINESS]: 20,
// };

export const HourlyRate = {
  [ProjectType.DEVELOPER]: 15,
  [ProjectType.DESIGNER]: 24,
  [ProjectType.ENGINEER]: 15,
  [ProjectType.BUSINESS]: 15,
};

export const ProjectDurationType = {
  UNTIL_ENDED: "UNTIL_ENDED",
  PERIOD: "PERIOD",
};

export const BudgetType = {
  FULL_TIME: "FULL_TIME",
  RETAINER: "RETAINER",
  HOURLY_RATE: "HOURLY_RATE",
};

export const ProjectBudgetTypes = [
  {
    icon: Images.HourlyRate,
    title: "Hourly Rate",
    description: "Fund the project by the hour.",
    value: BudgetType.HOURLY_RATE,
  },
  {
    icon: Images.Retainer,
    title: "Retainer",
    description: "Fund the project on a lump sum basis.",
    value: BudgetType.RETAINER,
  },
  {
    icon: Images.FullTime,
    title: "Full Time Position",
    description: "Negotiate a salary after student selection.",
    value: BudgetType.FULL_TIME,
  },
];

export const InvoiceStatus = {
  PENDING: "PENDING",
  PAID: "PAID",
  FAILED: "FAILED",
};

export const PersonalityTestColors = [
  "bg-rw-orange-yellow",
  "bg-rw-baby-blue",
  "bg-rw-green-apple",
  "bg-rw-orchid-pink",
];

export const DiscountType = {
  PERCENTAGE: "PERCENTAGE",
  AMOUNT: "AMOUNT",
};

export const ATVOptInBullets = [
  {
    icon: Images.TopThreeCandidatesIcon,
    label: "Top 3 Candidates",
    desc: "Instantly sent top 3 suggested candidates using our AI matching algorithm from our pool of 1,500+ local students.",
  },
  {
    icon: Images.GPTIcon,
    label: "Skill & Personality Vetting",
    desc: "Access to view students GPT-based personality tests & access to view students GPT-based skills tests.",
  },
  {
    icon: Images.InterviewIcon,
    label: "Easy Interviewing",
    desc: "Seamlessly schedule interviews with students.",
  },
  {
    icon: Images.PayrollIcon,
    label: "Payroll & Timesheets",
    desc: "Manage your students payroll and timesheets via our Stripe integration.",
  },
];

export const ATVExamples = [
  {
    header: "Hourly Rate Example",
    projectName: "Example Project",
    projectNameSubtitle: "Business selects this rate",
    projectTotalCost: "$20/hr",
    dividedCost: [
      {
        title: "Student's Rate",
        subtitle: "90% of selected rate",
        cost: "$18/hr",
      },
      { title: "Runway Fee", subtitle: "10% of selected rate", cost: "$2/hr" },
      { title: "Total Rate", subtitle: "", cost: "$20/hr" },
    ],
  },
  {
    header: "Lump Sum Example",
    projectName: "Example Project",
    projectNameSubtitle: "Business selects this amount",
    projectTotalCost: "$1000",
    dividedCost: [
      {
        title: "Student's Pay",
        subtitle: "90% of selected amount",
        cost: "$900",
      },
      { title: "Runway Fee", subtitle: "10% of selected amount", cost: "$100" },
      { title: "Total Amount", subtitle: "", cost: "$1000" },
    ],
  },
];

export const USAUniversities = [
  "Abilene Christian University",
  "Academy of Art College",
  "Adams State College",
  "Adelphi University",
  "Adler School of Professional Psychology",
  "Adrian College",
  "Agnes Scott College",
  "Air Force Institute of Technology",
  "Alabama Agricultural and Mechanical University",
  "Alabama State University",
  "Alaska Bible College",
  "Alaska Pacific University",
  "Albany College of Pharmacy",
  "Albany Law School",
  "Albany Medical Center",
  "Albany State University",
  "Albertus Magnus College",
  "Albion College",
  "Albright College",
  "Alcorn State University",
  "Alderson Broaddus College",
  "Alfred Adler Graduate School",
  "Alfred University",
  "Alice Lloyd College",
  "Allegheny College",
  "Allen University",
  "Alma College",
  "Alvernia College",
  "Alverno College",
  "Ambassador University",
  "Amber University",
  "American Academy of Nutrition",
  "American Business & Technology University",
  "American Conservatory of Music",
  "American Conservatory Theater",
  "American-European School of Management",
  "American Film Institute Center for Advanced Film and Television Studies",
  "American Indian College",
  "American InterContinental University - Atlanta",
  "American InterContinental University - Ft Lauderdale",
  "American InterContinental University - Georgia",
  "American InterContinental University Online",
  "American International College",
  "American Jewish University",
  "American Military University",
  "American Public University",
  "American University",
  "American World University",
  "Amherst College",
  "Anderson College",
  "Anderson University",
  "Andon College - Modesto",
  "Andon College - Stockton",
  "Andrew Jackson University",
  "Andrews University",
  "Angelo State University",
  "Anna Maria College",
  "Antioch New England Graduate School",
  "Antioch University",
  "Antioch University Los Angeles",
  "Antioch University Santa Barbara",
  "Antioch University Seattle",
  "Appalachian Bible College",
  "Appalachian State University",
  "Aquinas College",
  "Arcadia University",
  "Argosy University",
  "Argosy University - Hawaii",
  "Arizona Christian University",
  "Arizona State University",
  "Arizona State University Downtown Phoenix Campus",
  "Arizona State University Polytechnic Campus",
  "Arizona State University Tempe Campus",
  "Arizona State University West Campus",
  "Arkansas State University",
  "Arkansas State University Beebe",
  "Arkansas State University Mountain Home",
  "Arkansas State University Newport",
  "Arkansas Tech University",
  "Arlington Baptist College",
  "Armstrong Atlantic State University",
  "Armstrong University",
  "Art Academy of Cincinnati",
  "Art Center College of Design",
  "Arthur D Little Management Education Institute",
  "Art Institute of Charlotte",
  "Art Institute of Southern California",
  "Asbury College",
  "Ashland University",
  "Assumption College",
  "Athenaeum of Ohio",
  "Athens State College",
  "Atlanta Christian College",
  "Atlanta College of Art",
  "Atlantic International University",
  "Atlantic Union College",
  "Atlantic University",
  "Auburn University",
  "Auburn University at Montgomery",
  "Audrey Cohen College",
  "Augsburg College",
  "Augustana College",
  "Augusta State University",
  "Aurora University",
  "Austin College",
  "Austin Community College",
  "Austin Peay State University",
  "Ave Maria University",
  "Averett College",
  "Avila College",
  "Azusa Pacific University",
  "Babson College",
  "Baker College of Auburn Hills",
  "Baker College of Cadillac",
  "Baker College of Flint",
  "Baker College of Mount Clemens",
  "Baker College of Muskegon",
  "Baker College of Owosso",
  "Baker College of Port Huron",
  "Baker University",
  "Baldwin-Wallace College",
  "Ball State University",
  "Baltimore Hebrew University",
  "Baltimore International College",
  "Bank Street College of Education",
  "Baptist Bible College of Missouri",
  "Baptist Bible College of Pennsylvania",
  "Barat College",
  "Barber-Scotia College",
  "Barclay College",
  "Bard College",
  "Bard Graduate Center for Studies in the Decorative Arts",
  "Barnard College - Columbia University",
  "Barry University",
  "Bartlesville Wesleyan College",
  "Barton College",
  "Bastyr University",
  "Bates College",
  "Bauder College",
  "Baylor College of Dentistry",
  "Baylor College of Medicine",
  "Baylor University",
  "Belhaven College",
  "Bellarmine College",
  "Bellevue University",
  "Bellin College of Nursing",
  "Belmont Abbey College",
  "Belmont University",
  "Beloit College",
  "Bemidji State Univeristy",
  "Benedict College",
  "Benedictine College",
  "Benedictine University",
  "Benedictine University Springfield College in Illinois",
  "Bennett College",
  "Bennington College",
  "Bentley College",
  "Berea College",
  "Berean University of the Assemblies of God",
  "Berklee College of Music",
  "Berne University",
  "Berry College",
  "Bethany College California",
  "Bethany College Kansas",
  "Bethany College West Virginia",
  "Bethel College McKenzie",
  "Bethel College Mishawaka",
  "Bethel College Newton",
  "Beth-El College of Nursing and Health Sciences",
  "Bethel College St Paul",
  "Bethune-Cookman College",
  "Biola University",
  "Birmingham-Southern College",
  "Blackburn College",
  "Black Hawk College",
  "Black Hills State University",
  "Blessing-Rieman College of Nursing",
  "Bloomfield College",
  "Bloomsburg University of Pennsylvania",
  "Bluefield College",
  "Bluefield State College",
  "Blue Mountain College",
  "Bluffton College",
  "Bob Jones University",
  "Boise Bible College",
  "Boise State University",
  "Boricua College",
  "Boston Architectural Center",
  "Boston College",
  "Boston University",
  "Bowdoin College",
  "Bowie State University",
  "Bowling Green State University",
  "Bowling Green State University Firelands",
  "Bradley University",
  "Brandeis University",
  "Brandman University",
  "Brenau University",
  "Brescia University",
  "Brevard College",
  "Brewton-Parker College",
  "Breyer State University (Virtual University)",
  "Briar Cliff College",
  "Bridgewater College",
  "Bridgewater State College",
  "Brigham Young University",
  "Brigham Young University Hawaii",
  "Brooklyn Law School",
  "Brooks Institute of Photography",
  "Brown University",
  "Brunswick Community College",
  "Bryan College",
  "Bryant and Stratton College",
  "Bryant University",
  "Bryn Athyn College of the New Church",
  "Bryn Mawr College",
  "Bucknell University",
  "Buena Vista University",
  "Burlington College",
  "Butler University",
  "Cabrini College",
  "Caldwell College",
  "California Baptist College",
  "California Coast University",
  "California College for Health Sciences",
  "California College of Arts and Crafts",
  "California College of Podiatric Medicine",
  "California College San Diego",
  "California Institute of Integral Studies",
  "California Institute of Technology",
  "California Institute of the Arts",
  "California Lutheran University",
  "California Maritime Academy",
  "California Polytechnic State University - San Luis Obispo",
  "California School of Professional Psychology - BerkleyAlameda",
  "California School of Professional Psychology - Fresno",
  "California School of Professional Psychology - Los Angels",
  "California School of Professional Psychology - San Diego",
  "California State Polytechnic University - Pomona",
  "California State University Bakersfield",
  "California State University Channel Islands",
  "California State University Chico",
  "California State University Dominguez Hills",
  "California State University Fresno",
  "California State University Fullerton",
  "California State University Hayward",
  "California State University Long Beach",
  "California State University Los Angeles",
  "California State University Monterey Bay",
  "California State University Northridge",
  "California State University Sacramento",
  "California State University San Bernadino",
  "California State University San Marcos",
  "California State University Stanislaus",
  "alifornia University of Management and Sciences",
  "California University of Pennsylvania",
  "California Western School of Law",
  "Calumet College of St Joseph",
  "Calvary Bible College",
  "Calvin College",
  "Cambridge College",
  "Cameron University",
  "Campbellsville College",
  "Campbell University",
  "Canisius College",
  "Capella University",
  "Capital University",
  "Capital University Law School",
  "Capitol College",
  "Cardinal Stritch University",
  "Carleton College",
  "Carlos Albizu University",
  "Carlow College",
  "Carnegie Mellon University",
  "Carroll College Helena",
  "Carroll College Waukesha",
  "Carson-Newman College",
  "Carthage College",
  "Case Western Reserve University",
  "Castleton State College",
  "Catawba College",
  "Catholic Theological Union",
  "Cedar Crest College",
  "Cedarville College",
  "Centenary College",
  "Centenary College of Louisiana",
  "Center for Humanistic Studies",
  "Central Baptist College",
  "Central Bible College",
  "Central Christian College of the Bible",
  "Central College",
  "Central Connecticut State University",
  "Central Methodist College",
  "Central Michigan University",
  "Central State University",
  "Central Washington University",
  "Centre College",
  "Chadron State College",
  "Chaminade University of Honolulu",
  "Champlain College",
  "Chapman University",
  "Charles R Drew University of Medicine and Science",
  "Charleston Southern University",
  "Charter Oak State College",
  "Chatham College",
  "Chestnut Hill College",
  "Cheyney University of Pennsylvania",
  "Chicago State University",
  "Chowan College",
  "Christendom College",
  "Christian Brothers University",
  "Christian Heritage College",
  "Christopher Newport University",
  "Circleville Bible College",
  "City University",
  "City University of New York Bernard M Baruch College",
  "City University of New York Brooklyn College",
  "City University of New York City College",
  "City University of New York College of Staten Island",
  "City University of New York (CUNY) System",
  "City University of New York Graduate School and University Center",
  "City University of New York Hunter College",
  "City University of New York John Jay College of Criminal Justice",
  "City University of New York Lehman College",
  "City University of New York Medgar Evers College",
  "City University of New York Medical School  Sophie Davis School of Biomedical Education",
  "City University of New York New York City Technical College",
  "City University of New York Queens College",
  "City University of New York School of Law at Queens College",
  "City University of New York York College",
  "Claflin College",
  "Claremont Graduate University",
  "Claremont Lincoln University",
  "Claremont McKenna College",
  "Clarion University",
  "Clark Atlanta University",
  "Clarke College",
  "Clarkson College",
  "Clarkson University",
  "Clark University",
  "Clayton College & State University",
  "Clearwater Christian College",
  "Cleary College",
  "Clemson University",
  "Cleveland Chiropractic College Kansas City",
  "Cleveland Chiropractic College Los Angeles",
  "Cleveland Institute of Art",
  "Cleveland Institute of Music",
  "Cleveland State University",
  "Coastal Carolina University",
  "Coe College",
  "Cogswell Polytechnical College",
  "Coker College",
  "Colby College",
  "Colby-Sawyer College",
  "Coleman College",
  "Colgate University",
  "CollegeAmerica Denver",
  "CollegeAmerica Phoenix",
  "College for Creative Studies",
  "College for Financial Planning",
  "College for Lifelong Learning",
  "College Misericordia",
  "College of Aeronautics",
  "College of Charleston",
  "College of DuPage",
  "College of Idaho",
  "College of Mount St Joseph",
  "College of Mount St Vincent",
  "College of New Rochelle",
  "College of Notre Dame",
  "College of Notre Dame of Maryland",
  "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
  "College of St Benedict and St John's University",
  "College of St Catherine",
  "College of St Elizabeth",
  "College of St Joseph",
  "College of St Mary",
  "College of St Rose",
  "College of St Scholastica",
  "College of the Atlantic",
  "College of the Holy Cross",
  "College of the Ozarks",
  "College of Visual Arts",
  "College of William and Mary",
  "Colorado Christian University",
  "Colorado College",
  "Colorado School of Mines",
  "Colorado State University",
  "Colorado State University-Pueblo",
  "Colorado Technical University",
  "Columbia College Chicago",
  "Columbia College Hollywood",
  "Columbia College of Missouri",
  "Columbia College South Carolina",
  "Columbia Commonwealth University",
  "Columbia International University",
  "Columbia Southern University",
  "Columbia Union College",
  "Columbia University",
  "Columbus College of Art and Design",
  "Columbus State University",
  "Columbus University",
  "Community College of Denver",
  "oncord College",
  "Concordia College Ann Arbor",
  "Concordia College Bronxville",
  "Concordia College Moorhead",
  "Concordia College Selma",
  "Concordia College Seward",
  "Concordia College St Paul",
  "Concordia University Austin",
  "Concordia University Irvine",
  "Concordia University Mequon",
  "Concordia University Portland",
  "Concordia University River Forest",
  "Connecticut College",
  "Converse College",
  "Conway School of Landscape Design",
  "Coppin State College",
  "Cornell College",
  "Cornell University",
  "Cornish College of the Arts",
  "Cosmopolitan University",
  "Covenant College",
  "Cranbrook Academy of Art",
  "Creighton University",
  "Crichton College",
  "Crown College",
  "Culver-Stockton College",
  "Cumberland College",
  "Cumberland University",
  "Curry College",
  "Daemen College",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Dallas Baptist University",
  "Dallas Christian College",
  "Dana College",
  "Daniel Webster College",
  "Danville Area Community College",
  "Dartmouth College",
  "Darton College",
  "Davenport College of Business Grand Rapids",
  "Davenport College of Business Kalamazoo",
  "Davenport College of Business Lansing",
  "Davidson College",
  "Davis and Elkins College",
  "Deaconess College of Nursing",
  "Delaware State University",
  "Delaware Valley College",
  "Delta International University",
  "Delta State University",
  "Denison University",
  "Denver Paralegal Institute",
  "Denver Technical College",
  "DePaul University",
  "DePauw University",
  "DeSales University",
  "Design Institute of San Diego",
  "Detroit College of Business",
  "Detroit College of Business - Flint",
  "Detroit College of Business - Warren",
  "Detroit College of Law",
  "DeVry Institute of Technology Chicago",
  "DeVry Institute of Technology Columbus",
  "DeVry Institute of Technology Decatur",
  "DeVry Institute of Technology DuPage",
  "DeVry Institute of Technology Irving",
  "DeVry Institute of Technology Kansas City",
  "DeVry Institute of Technology Phoenix",
  "DeVry Institute of Technology Pomona",
  "Dickinson College",
  "Dickinson State University",
  "Dillard University",
  "Divine Word College",
  "Dixie College",
  "Doane College",
  "Dominican College",
  "Dominican College of San Rafael",
  "Dominican School of Philosophy and Theology",
  "Dominican University",
  "Dominion College",
  "Dordt College",
  "Dowling College",
  "Drake University",
  "Drew University",
  "Drexel University",
  "Drury College",
  "Duke University",
  "Duluth Business University",
  "Duquesne University",
  "D'Youville College",
  "Earlham College",
  "EarthNet Institute",
  "East Carolina University",
  "East Central University",
  "Eastern College",
  "Eastern Connecticut State University",
  "Eastern Conservatory of Music",
  "Eastern Illinois University",
  "Eastern Kentucky University",
  "Eastern Mennonite University",
  "Eastern Michigan University",
  "Eastern Nazarene College",
  "Eastern New Mexico University",
  "Eastern Oregon University",
  "Eastern Virginia Medical School",
  "Eastern Washington University",
  "East Stroudsburg State University",
  "East Tennessee State University",
  "East Texas Baptist University",
  "East-West University",
  "Eckerd College",
  "Edgewood College",
  "Edinboro University of Pennsylvania",
  "Edison Community College",
  "Edward Waters College",
  "Elizabeth City State University",
  "Elizabethtown College",
  "Elmhurst College",
  "Elmira College",
  "Elms College",
  "Elon College",
  "Embry-Riddle Aeronautical University",
  "Embry Riddle Aeronautical University Prescott",
  "Emerson College",
  "Emmanuel College",
  "Emmanuel College Georgia",
  "Emmaus Bible College",
  "Emory & Henry College",
  "Emory University",
  "Emporia State University",
  "Erskine College",
  "Eugene Bible College",
  "Eureka College",
  "Evangel University",
  "Evergreen State College",
  "Excel College",
  "Excelsior College",
  "Fairfield University",
  "Fairleigh Dickinson University Florham-Madison Campus",
  "Fairleigh Dickinson University Teaneck-Hackensack Campus",
  "Fairmont State College",
  "Fashion Institute of New York",
  "Faulkner University",
  "Fayetteville State University",
  "Felician College",
  "Ferris State University",
  "Ferrum College",
  "Fielding Institute",
  "Finch University of Health Sciences - The Chicago Medical School",
  "Finlandia University",
  "Fisher College",
  "Fisk University",
  "Fitchburg State College",
  "Flagler College",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida Christian College",
  "Florida Community College at Jacksonville",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Florida Memorial College",
  "Florida Metropolitan University Fort Lauderdale College",
  "Florida Metropolitan University Tampa College",
  "Florida Metropolitan University Tampa College Brandon",
  "Florida Metropolitan University Tampa College Lakeland",
  "Florida Southern College",
  "Florida State University",
  "Florida University of Medicine",
  "Fontbonne College",
  "Foothill-De Anza Community College District",
  "Fordham University",
  "Forest Institute of Professional Psychology",
  "Fort Hays State University",
  "Fort Lewis College",
  "Fort Valley State College",
  "Framingham State College",
  "Franciscan School of Theology",
  "Franciscan University of Steubenville",
  "Francis Marion University",
  "Franklin and Marshall College",
  "Franklin College",
  "Franklin Pierce College",
  "Franklin Pierce Law Center",
  "Franklin University",
  "Franklin W Olin College of Engineering",
  "Frank Lloyd Wright School of Architecture",
  "Fred Hutchinson Cancer Research Center",
  "Freed-Hardeman University",
  "Free University (Virginia Theological University)",
  "Free Will Baptist Bible College",
  "Fresno City College",
  "Fresno Pacific University",
  "Friends University",
  "Frostburg State University",
  "Full Sail University",
  "Furman University",
  "Gallaudet University",
  "Gannon University",
  "Gardner Webb University",
  "Gemological Institute of America",
  "Geneva College",
  "George Fox University",
  "George Mason University",
  "Georgetown College",
  "Georgetown University",
  "George Washington University",
  "George Wythe College",
  "Georgia Baptist College of Nursing",
  "Georgia College & State University",
  "Georgia Health Sciences University",
  "Georgia Institute of Technology",
  "Georgian Court College",
  "Georgia School of Professional Psychology",
  "Georgia Southern University",
  "Georgia Southwestern University",
  "Georgia State University",
  "Gettysburg College",
  "Glendale University College of Law",
  "Glenville State College",
  "Goddard College",
  "God's Bible School and College",
  "Golden Gate University",
  "Goldey-Beacom College",
  "Gonzaga University",
  "Gordon College",
  "Gordon Conwell Theological Seminary",
  "Goshen College",
  "Goucher College",
  "Governors State University",
  "Grace Bible College",
  "Grace College",
  "Graceland College",
  "Grace University",
  "Graduate Theological Union",
  "Grambling State University",
  "Grand Canyon University",
  "Grand Valley State University",
  "Grand View College",
  "Grantham University",
  "Gratz College",
  "Great Lakes Christian College",
  "Green Mountain College",
  "Greensboro College",
  "Greenville College",
  "Grinnell College",
  "Grove City College",
  "Guilford College",
  "Gustavus Adolphus College",
  "Gwynedd-Mercy College",
  "Hagerstown Community College",
  "Hamilton College",
  "Hamilton Technical College",
  "Hamline University",
  "Hampden-Sydney College",
  "Hampshire College",
  "Hampton College",
  "Hampton University",
  "Hannibal-Lagrange College",
  "Hanover College",
  "Harding University",
  "Harding University Graduate School of Religion",
  "Hardin-Simmons University",
  "Harrington Institute of Interior Design",
  "Harris-Stowe State University",
  "Hartford College for Women",
  "Hartford Graduate Center (Rensselaer at Hartford)",
  "Hartwick College",
  "Harvard University",
  "Harvey Mudd College",
  "Hastings College",
  "Haverford College",
  "Hawaii Pacific University",
  "Heartland Baptist Bible College",
  "Hebrew College",
  "Heidelberg College",
  "Henderson State Univerisity",
  "Hendrix College",
  "Heritage College",
  "Hesser College",
  "High Point University",
  "Hilbert College",
  "Hillsdale College",
  "Hiram College",
  "Hobart and William Smith Colleges",
  "Hobe Sound Bible College",
  "Hodges University",
  "Hofstra University",
  "Hollins University",
  "Holy Apostles College",
  "Holy Cross College",
  "Holy Family College",
  "Holy Names College",
  "Hood College",
  "Hope College",
  "Hope International University",
  "Houghton College",
  "Houston Baptist University",
  "Howard Payne University",
  "Howard University",
  "Humboldt State University",
  "Humphreys College",
  "Huntington College",
  "Huron University",
  "Husson College",
  "Huston-Tillotson College",
  "ICI University",
  "ICT College",
  "Idaho State University",
  "Iglobal University",
  "Illinois Benedictine University",
  "Illinois College",
  "Illinois College of Optometry",
  "Illinois Institute of Technology",
  "Illinois School of Professional Psychology - Chicago Campus",
  "Illinois School of Professional Psychology - Meadows Campus",
  "Illinois State University",
  "Illinois Valley Community College",
  "Illinois Wesleyan University",
  "Immaculata University",
  "IMPAC University",
  "Indiana Institute of Technologyy",
  "Indiana State University",
  "Indiana University at Bloomington",
  "Indiana University at Kokomo",
  "Indiana University at South Bend",
  "Indiana University - East",
  "Indiana University - Northwest",
  "Indiana University of Pennsylvania",
  "Indiana University-Purdue University at Columbus",
  "Indiana University-Purdue University at Fort Wayne",
  "Indiana University-Purdue University at Indianapolis",
  "Indiana University - Southeast",
  "Indiana University (System)",
  "Indiana Wesleyan University",
  "Institute of Clinical Social Work",
  "Institute of Paper Science and Technology",
  "Institute of Textile Technology",
  "Institute of Transpersonal Psychology",
  "IntelliTec College - Grand Junction",
  "International Academy of  Merchandising and Design Chicago",
  "International Academy of  Merchandising and Design Tampa",
  "International Bible College",
  "International College",
  "Iona College",
  "Iowa State University of Science and Technology",
  "Iowa Wesleyan College",
  "Ithaca College",
  "ITT Technical Institute Fort Wayne",
  "ITT Technical Institute Indianapolis",
  "ITT Technical Institute Maitland",
  "ITT Technical Institute Portland",
  "ITT Technical Institute West Covina",
  "Jackson State University",
  "Jacksonville State University",
  "Jacksonville University",
  "James Madison University",
  "Jamestown College",
  "Jarvis Christian College",
  "John Brown University",
  "John Carroll University",
  "John F Kennedy University",
  "John Marshall Law School",
  "John Paul the Great Catholic University",
  "Johns Hopkins University",
  "Johnson Bible College",
  "Johnson County Community College",
  "Johnson C Smith University",
  "Johnson State College",
  "Johnson & Wales University",
  "Johnson & Wales University Charleston",
  "John Wesley College",
  "Jones College",
  "Jones International University",
  "Judson College Elgin",
  "Judson College Marion",
  "Juniata College",
  "Kalamazoo College",
  "Kankakee Community College",
  "Kansas City Art Institute",
  "Kansas State University",
  "Kansas Wesleyan University",
  "Kaplan University",
  "Katharine Gibbs School",
  "Kean University of New Jersey",
  "Keck Graduate Institute of Applied Life Sciences",
  "Keene State College",
  "Keller Graduate School of Management",
  "Kendall College",
  "Kendall College of Art and Design",
  "Kennesaw State University",
  "Kent State University",
  "Kent State University - Ashtabula",
  "Kent State University - East Liverpool",
  "Kent State University - Salem",
  "Kent State University - Stark",
  "Kent State University - Trumbull",
  "Kent State University - Tuscarawas",
  "Kentucky Christian College",
  "Kentucky State University",
  "Kentucky Wesleyan College",
  "Kenyon College",
  "Kettering University (GMI)",
  "Keuka College",
  "King College",
  "King's College",
  "Kirksville College of Osteopathic Medicine",
  "Kirkwood Community College",
  "Knox College",
  "Knoxville College",
  "Kutztown University of Pennsylvania",
  "Laboratory Institute of Merchandising",
  "Lafayette College",
  "Lagrange College",
  "Lake Erie College",
  "Lake Forest College",
  "Lake Forest Graduate School of Management",
  "Lakeland College",
  "Lake Superior State University",
  "Lakeview College of Nursing",
  "Lamar University",
  "Lamar University - Port Arthur",
  "Lambuth University",
  "Lancaster Bible College",
  "Lander University",
  "Lane College",
  "Langston University",
  "La Roche College",
  "La Salle University",
  "Lasell College",
  "La Sierra University",
  "Laurus Technical Institute",
  "Lawrence Technological University",
  "Lawrence University",
  "Lebanon Valley College",
  "Lees-McRae College",
  "Lee University",
  "Lehigh Univervsity",
  "Le Moyne College",
  "Le Moyne-Owen College",
  "Lenoir-Rhyne College",
  "Lesley University",
  "Letourneau University",
  "Lewis and Clark College",
  "Lewis & Clark Community College",
  "Lewis-Clark State College",
  "Lewis University",
  "Liberty University",
  "Life Chiropractic College West",
  "Life University",
  "Limestone College",
  "Lincoln Memorial University",
  "Lincoln University Missouri",
  "Lincoln University Pennsylvania",
  "Lincoln University San Francisco",
  "Lindenwood University",
  "Lindsey Wilson College",
  "Linfield College",
  "Lipscomb University",
  "Livingstone College",
  "Lock Haven University of Pennsylvania",
  "Logan College of Chiropractic",
  "Loma Linda University",
  "Long Island University",
  "Long Island University CW Post Campus",
  "Long Island University Southampton College",
  "Longwood College",
  "Loras College",
  "Los Angeles College of Chiropractic",
  "Louisiana Baptist University",
  "Louisiana College",
  "Louisiana State University and Agricultural and Mechanical College",
  "Louisiana State University at Alexandria",
  "Louisiana State University at Eunice",
  "Louisiana State University Health Sciences Center New Orleans",
  "Louisiana State University in Shreveport",
  "Louisiana Tech University",
  "Lourdes College",
  "Loyola College in Maryland",
  "Loyola Marymount University",
  "Loyola University New Orleans",
  "Loyola University of Chicago",
  "Lubbock Christian University",
  "Lutheran Bible Institute of Seattle",
  "Luther College",
  "Lycoming College",
  "Lynchburg College",
  "Lyndon State College",
  "Lynn University",
  "Lyon College",
  "Macalester College",
  "MacMurray College",
  "Macon State College",
  "Madison University (Distance Education)",
  "Madonna University",
  "Maharishi University of Management",
  "Maine College of Art",
  "Maine Maritime Academy",
  "Malone College",
  "Manchester College",
  "Manhattan Christian College",
  "Manhattan College",
  "Manhattan School of Music",
  "Manhattanville College",
  "Mankato State University",
  "Mansfield University of Pennsylvania",
  "Maranatha Baptist Bible College",
  "Marian College",
  "Marian College of Fond Du Lac",
  "Marietta College",
  "Marist College",
  "Marlboro College",
  "Marquette University",
  "Marshall University",
  "Mars Hill College",
  "Martin Luther College",
  "Martin Methodist College",
  "Martin University",
  "Mary Baldwin College",
  "Marycrest International University",
  "Marygrove College",
  "Marylhurst University",
  "Marymount College New York",
  "Marymount Manhattan College",
  "Marymount University",
  "Maryville College",
  "Maryville University of St Louis",
  "Mary Washington College",
  "Marywood University",
  "Massachusetts College of Art",
  "Massachusetts College of Liberal Arts",
  "Massachusetts College of Pharmacy and Allied Health Sciences",
  "Massachusetts Institute of Technology",
  "Massachusetts Maritime Academy",
  "Massachusetts School of Professional Psychology",
  "Mayo Graduate School",
  "Mayo Medical School",
  "Mayville State University",
  "McKendree College",
  "McMurry University",
  "McNeese State University",
  "McPherson College",
  "Medaille College",
  "Medcenter One College of Nursing",
  "Medical College of Georgia",
  "Medical College of Ohio",
  "Medical College of Pennsylvania and Hahnemann University",
  "Medical College of Wisconsin",
  "Medical University of South Carolina",
  "Meharry Medical College",
  "Memphis College of Art",
  "Menlo College",
  "Mennonite College of Nursing",
  "Mercer University",
  "Mercer University Cecil B Day Campus",
  "Mercy College",
  "Mercyhurst College",
  "Meredith College",
  "Merrimack College",
  "Mesa State College",
  "Messiah College",
  "Methodist College",
  "Metropolitan State College of Denver",
  "Metropolitan State University",
  "MGH Institute of Health Professions",
  "Miami Dade College",
  "Miami University of Ohio",
  "Miami University of Ohio - Hamilton",
  "Miami University of Ohio - Middletown",
  "Michigan School of Professional Psychology",
  "Michigan State University",
  "Michigan Technological University",
  "Mid-America Nazarene University",
  "Mid-American Bible College",
  "Mid-Continent Baptist Bible College",
  "Middlebury College",
  "Middle Tennessee State University",
  "Midland Lutheran College",
  "Midway College",
  "Midwestern State University",
  "Midwestern University",
  "Miles College",
  "Millennia Atlantic University",
  "Millersville University of Pennsylvania",
  "Milligan College",
  "Millikin University",
  "Millsaps College",
  "Mills College",
  "Mills Grae University",
  "Milwaukee Institute of Art and Design",
  "Milwaukee School of Engineering",
  "Minneapolis College of Art and Design",
  "Minnesota Bible College",
  "Minnesota School of Professional Psychology",
  "Minot State University",
  "Mississippi College",
  "Mississippi State University",
  "Mississippi University for Women",
  "Mississippi Valley State University",
  "Missouri Baptist College",
  "Missouri Southern State College",
  "Missouri Tech",
  "Missouri University of Science and Technology",
  "Missouri Valley College",
  "Missouri Western State College",
  "Molloy College",
  "Monmouth University",
  "Montana State University",
  "Montana State University - Billings",
  "Montana State University - Northern",
  "Montana Tech",
  "Montclair State University",
  "Monterey Institute of International Studies",
  "Montreat College",
  "Montserrat College of Art",
  "Moody Bible Institute",
  "Moore College of Art and Design",
  "Moorhead State University",
  "Moraine Valley Community College",
  "Moravian College",
  "Morehead State University",
  "Morehouse College",
  "Morehouse School of Medicine",
  "Morgan State University",
  "Morningside College",
  "Morris Brown College",
  "Morris College",
  "Morrison College",
  "Mountain State University",
  "Mount Aloysius College",
  "Mount Carmel College of Nursing",
  "Mount Holyoke College",
  "Mount Ida College",
  "Mount Marty College",
  "Mount Mary College",
  "Mount Mercy College",
  "Mount Olive College",
  "Mount Senario College",
  "Mount Sinai School of Medicine",
  "Mount St Clare College",
  "Mount St Mary College",
  "Mount St Mary's College California",
  "Mount St Mary's College Maryland",
  "Mount Union College",
  "Mount Vernon College",
  "Mount Vernon Nazarene College",
  "Muhlenberg College",
  "Multnomah Bible College",
  "Murray State University",
  "Muskingum College",
  "NAES College",
  "National American University",
  "National American University Albuquerque",
  "National American University Colorado Springs",
  "National American University Denver",
  "National American University Kansas City",
  "National American University Roseville",
  "National American University Sioux Falls",
  "National College of Chiropractic",
  "National College of Naturopathic Medicine",
  "National Defense University",
  "National Hispanic University",
  "National-Louis University",
  "National Technological University",
  "National Theatre Conservatory",
  "National University",
  "Naval Postgraduate School",
  "Nazarene Bible College",
  "Nazareth College",
  "Nebraska Christian College",
  "Nebraska Methodist College of Nursing and Allied Health",
  "Nebraska Wesleyan University",
  "Neumann College",
  "Newberry College",
  "New College of California",
  "New College of Florida",
  "New England College",
  "New England College of Optometry",
  "New England Conservatory of Music",
  "New England Institute of Technology",
  "New England School of Art and Design",
  "New England School of Communications",
  "New England School of Law",
  "New Hampshire College",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "Newman University",
  "New Mexico Highlands University",
  "New Mexico Institute of Mining and Technology",
  "New Mexico State University",
  "Newschool of Architecture and Design",
  "New York Academy of Art Graduate School of Figurative Art",
  "New York Chiropractic College",
  "New York College of Podiatric Medicine",
  "New York Film Academy",
  "New York Institute of Technology",
  "New York Law School",
  "New York Medical College",
  "New York School of Interior Design",
  "New York University",
  "Niagara University",
  "Nicholls State University",
  "Nichols College",
  "Norfolk State University",
  "North Carolina Agricultural and Technical State University",
  "North Carolina Central University",
  "North Carolina School of the Arts",
  "North Carolina State University",
  "North Carolina Wesleyan College",
  "North Central Bible College",
  "North Central College",
  "Northcentral University",
  "North Dakota State University",
  "Northeastern Illinois University",
  "Northeastern Ohio University College of Medicine",
  "Northeastern State University",
  "Northeastern University",
  "Northern Arizona University",
  "Northern Illinois University",
  "Northern Kentucky University",
  "Northern Michigan University",
  "Northern State University",
  "Northern Virginia Community College",
  "Northface University",
  "North Georgia College",
  "North Greenville College",
  "Northland College",
  "North Park University",
  "Northwest Christian College",
  "Northwest College of Art",
  "Northwestern College Iowa",
  "Northwestern College of Chiropractic",
  "Northwestern College St Paul",
  "Northwestern Oklahoma State University",
  "Northwestern State University of Louisiana",
  "Northwestern University",
  "Northwest Missouri State University",
  "Northwest Nazarene University",
  "Northwest University",
  "Northwood University",
  "Northwood University Florida Campus",
  "Northwood University Texas Campus",
  "Norwich University",
  "Notre Dame College",
  "Notre Dame College of Ohio",
  "Notre Dame de Namur University",
  "Nova Southeastern University",
  "Nyack College",
  "Oakland City University",
  "Oakland University",
  "Oakton Community College",
  "Oakwood College",
  "Oberlin College",
  "Occidental College",
  "Oglala Lakota College",
  "Oglethorpe University",
  "Ohio College of Podiatric Medicine",
  "Ohio Dominican College",
  "Ohio Northern University",
  "Ohio State University",
  "Ohio State University - Lima",
  "Ohio State University - Mansfield",
  "Ohio State University - Marion",
  "Ohio State University - Newark",
  "Ohio University",
  "Ohio University - Chillicothe",
  "Ohio University - Eastern",
  "Ohio University - Lancaster",
  "Ohio University - Southern",
  "Ohio University - Zanesville",
  "Ohio Valley College",
  "Ohio Wesleyan University",
  "Oklahoma Baptist University",
  "Oklahoma Christian University",
  "Oklahoma City University",
  "Oklahoma Panhandle State University",
  "Oklahoma State University",
  "Oklahoma State University Center for Health Sciences",
  "Oklahoma State University - Institute of Technology",
  "Oklahoma State University - Oklahoma City",
  "Oklahoma State University - Tulsa",
  "Old Dominion University",
  "Olive-Harvey College",
  "Olivet College",
  "Olivet Nazarene University",
  "O'More College of Design",
  "Oral Roberts University",
  "Oregon College of Arts and Crafts",
  "Oregon Graduate Institute of Science and Technology",
  "Oregon Health Sciences University",
  "Oregon Institute of Technology",
  "Oregon State University",
  "Otis College of Art & Design",
  "Ottawa University",
  "Otterbein College",
  "Ouachita Baptist University",
  "Our Lady of Holy Cross College",
  "Our Lady of the Lake University",
  "Ozark Christian College",
  "Pace University",
  "Pace University  PleasantvilleBriarcliff",
  "Pacifica Graduate Institute",
  "Pacific College of Oriental Medicine",
  "Pacific Graduate School of Psychology",
  "Pacific Lutheran University",
  "Pacific Northwest College of Art",
  "Pacific Oaks College",
  "Pacific Union College",
  "Pacific University",
  "Paier College of Art",
  "Paine College",
  "Palm Beach Atlantic University",
  "Palm Beach State College",
  "Palmer College of Chiropractic",
  "Palmer College of Chiropractic West",
  "Park College",
  "Parsons School of Design",
  "Paul Quinn College",
  "Peace College",
  "Pebble Hills University",
  "Pennsylvania Academy of the Fine Arts",
  "Pennsylvania College of Optometry",
  "Pennsylvania Institute of Technology",
  "Pennsylvania State University",
  "Pennsylvania State University - Abington",
  "Pennsylvania State University - Altoona",
  "Pennsylvania State University at Erie - Behrend College",
  "Pennsylvania State University at Harrisburg - The Capital College",
  "Pennsylvania State University - Berks-Lehigh Valley College",
  "Pennsylvania State University Delaware County",
  "Pennsylvania State University Great Valley",
  "Pennsylvania State University - Lehigh Valley",
  "Pennsylvania State University - Milton SHershey Medical Center",
  "Pennsylvania State University - Schuylkill",
  "Pepperdine University",
  "Peru State College",
  "Pfeiffer University",
  "Philadelphia College of Bible",
  "Philadelphia College of Osteopathic Medicine",
  "Philadelphia University",
  "Philander Smith College",
  "Phillips Graduate Institute",
  "Phillips University",
  "Piedmont Baptist College",
  "Piedmont College",
  "Pikeville College",
  "Pillsbury Baptist Bible College",
  "Pittsburg State University",
  "Pitzer College",
  "Plymouth State College",
  "Point Loma Nazarene College",
  "Point Park College",
  "Polytechnic University",
  "Polytechnic University Long Island Campus",
  "Polytechnic University Westchester Graduate Center",
  "Pomona College",
  "Portland Community College",
  "Portland State University",
  "Post University of Waterbury",
  "Prairie View Agricultural and Mechanical University",
  "Pratt Institute",
  "Presbyterian College",
  "Prescott College",
  "Preston University",
  "Princeton University",
  "Principia College",
  "Providence College",
  "Puget Sound Christian College",
  "Purdue University",
  "Purdue University Calumet",
  "Purdue University North Central",
  "Quantum-Veritas International University",
  "Queens College",
  "Quincy University",
  "Quinnipiac College",
  "Radford University",
  "Ramapo College of New Jersey",
  "Rand Graduate School of Policy Studies",
  "Randolph-Macon College",
  "Randolph-Macon Woman's College",
  "Rasmussen College",
  "Rasmussen College Florida Campuses",
  "Rasmussen College Illinois Campuses",
  "Rasmussen College Minnesota Campuses",
  "Rasmussen College North Dakota Campuses",
  "Rasmussen College Wisconsin Campuses",
  "Reed College",
  "Reformed Bible College",
  "Regent International University",
  "Regent University",
  "Regis College",
  "Regis University",
  "Reinhardt College",
  "Rensselaer Polytechnic Institute",
  "Research College of Nursing - Rockhurst University",
  "Rhode Island College",
  "Rhode Island School of Design",
  "Rhodes College",
  "Rice University",
  "Richard Stockton College of New Jersey",
  "Rider University",
  "Ringling College of Art and Design",
  "Ripon College",
  "Rivier College",
  "Roanoke Bible College",
  "Roanoke College",
  "Robert Morris College",
  "Robert Morris College of Chicago",
  "Roberts Wesleyan College",
  "Rochester College",
  "Rochester Institute of Technology",
  "Rockford College",
  "Rockhurst College",
  "Rock Valley College",
  "Rocky Mountain College",
  "Rocky Mountain College of Art and Design",
  "Rogers State University",
  "Roger Williams University",
  "Rollins College",
  "Roosevelt University",
  "Rose-Hulman Institute of Technology",
  "Rosemont College",
  "Ross University School of Medicine",
  "Rowan University",
  "Rush University",
  "Russel Sage College",
  "Rust College",
  "Rutgers The State University of New Jersey",
  "Rutgers The State University of New Jersey - Camden",
  "Rutgers The State University of New Jersey - Newark",
  "Sacred Heart University",
  "Sage Graduate School",
  "Saginaw Valley State University",
  "Salem College",
  "Salem International University",
  "Salem State College",
  "Salem Teikyo University",
  "Salisbury State University",
  "Salve Regina University",
  "Samford University",
  "Sam Houston State University",
  "Samuel Merritt College",
  "San Diego State University",
  "San Diego University for Integrative Studies",
  "Sanford-Brown Institute",
  "San Francisco Art Institute",
  "San Francisco Conservatory of Music",
  "San Francisco State University",
  "San Joaquin College of Law",
  "San Jose Christian College",
  "San Jose State University",
  "Santa Clara University",
  "Sarah Lawrence College",
  "Savannah College of Art and Design",
  "Savannah State University",
  "Saybrook Institute",
  "Schiller International University",
  "Scholl College of Podiatric Medicine",
  "School for International Training",
  "School of the Museum of Fine Arts",
  "School of the Visual Arts",
  "Schreiner College",
  "Scripps College",
  "Seattle Pacific University",
  "Seattle University",
  "Seton Hall University",
  "Seton Hill College",
  "Shawnee Community College",
  "Shawnee State University",
  "Shaw University",
  "Sheldon Jackson College",
  "Shenandoah University",
  "Shepherd College",
  "Sherman College of Straight Chiropractic",
  "Shimer College",
  "Shippensburg University of Pennsylvania",
  "Shoreline Community College",
  "Shorter College",
  "Siena College",
  "Siena Heights University",
  "Sierra Nevada College",
  "Silver Lake College",
  "Simmons College",
  "Simon's Rock College",
  "Simpson College",
  "Simpson College Iowa",
  "Sinte Gleska University",
  "Skadron College",
  "Skidmore College",
  "Slippery Rock University",
  "Smith College",
  "Sojourner-Douglass College",
  "Soka University of America",
  "Sonoma State University",
  "South Carolina State University",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "Southeastern Baptist College",
  "Southeastern Bible College",
  "Southeastern College of the Assemblies of God",
  "Southeastern Louisiana University",
  "Southeastern Oklahoma State University",
  "Southeastern University",
  "Southeast Missouri State University",
  "Southern Adventist University",
  "Southern Arkansas University",
  "Southern California College",
  "Southern California College of Optometry",
  "Southern California Institute of Architecture",
  "Southern College of Optometry",
  "Southern Connecticut State University",
  "Southern Illinois University at Carbondale",
  "Southern Illinois University at Edwardsville",
  "Southern Methodist University",
  "Southern Nazarene University",
  "Southern New Hampshire University",
  "Southern Oregon University",
  "Southern Polytechnic State Univerisity",
  "Southern University - Baton Rouge",
  "Southern University - New Orleans",
  "Southern University - Shreveport",
  "Southern Utah University",
  "Southern Vermont College",
  "Southern Wesleyan University",
  "South Florida Bible College & Theological Seminary",
  "South Texas College of Law",
  "Southwest Baptist University",
  "Southwestern Adventist University",
  "Southwestern Assemblies of God University",
  "Southwestern Baptist Theological Seminary",
  "Southwestern Christian College",
  "Southwestern Christian University",
  "Southwestern College Kansas",
  "Southwestern College Santa Fe",
  "Southwestern Oklahoma State University",
  "Southwestern University",
  "Southwestern University School of Law",
  "Southwest Missouri State University",
  "Southwest Missouri State University - West Plains",
  "Southwest State University",
  "Southwest Texas State University",
  "Southwest University",
  "Spalding University",
  "Spelman College",
  "Spertus Institute of Jewish Studies",
  "Spring Arbor College",
  "Springfield College",
  "Spring Hill College",
  "St Ambrose University",
  "Standford Online University",
  "Standford University",
  "St Andrews Presbyterian College",
  "Stanford University",
  "St Anselm College",
  "St Anthony College of Nursing",
  "State University of New York at Albany",
  "State University of New York at Binghamton",
  "State University of New York at Buffalo",
  "State University of New York at New Paltz",
  "State University of New York at Oswego",
  "State University of New York at Stony Brook",
  "State University of New York College at Brockport",
  "State University of New York College at Cortland",
  "State University of New York College at Fredonia",
  "State University of New York College at Geneseo",
  "State University of New York College at Old Westbury",
  "State University of New York College at Oneonta",
  "State University of New York College at Plattsburgh",
  "State University of New York College at Potsdam",
  "State University of New York College at Purchase",
  "State University of New York College of Agriculture and Technology at Cobleskill",
  "State University of New York College of Environmental Science and Forestry",
  "State University of New York College of Optometry",
  "State University of New York College of Technology at Alfred",
  "State University of New York College of Technology at Farmingdale",
  "State University of New York Downstate Medical Center",
  "State University of New York Empire State College",
  "State University of New York Health Sience Centre Syracuse",
  "State University of New York Institute of Technology at UticaRome",
  "State University of New York Maritime College",
  "State University of New York School of Engineering and Applied Sciences",
  "State University of New York (SUNY)",
  "State University of New York Upstate Medical University ",
  "State University of West Georgia",
  "St Augustine's College North Carolina",
  "St Bernard's Institute",
  "St Bonaventure University",
  "St Cloud State University",
  "St Edwards University",
  "Stefan University",
  "Stephen F Austin State University",
  "Stephens College",
  "Sterling College",
  "Stetson University",
  "Stevens Institute of Technology",
  "St Francis College Brooklyn Heights",
  "St Francis College Fort Wayne",
  "St Francis College Loretto",
  "St Francis Medical Center College of Nursing",
  "St George's University",
  "Stillman College",
  "St John Fisher College",
  "St John's College Maryland",
  "St John's College New Mexico",
  "St John's Seminary",
  "St John's University",
  "St Joseph College",
  "St Joseph College of Nursing",
  "St Joseph's College",
  "St Joseph's College New York",
  "St Joseph's College New York Suffolk Campus",
  "St Joseph's College of Maine",
  "St Joseph's University",
  "St Lawrence University",
  "St Leo College",
  "St Louis Christian College",
  "St Louis College of Pharmacy",
  "St Louis University",
  "St Luke's College",
  "St Martin's College",
  "St Mary College",
  "St Mary-of-the-Woods College",
  "St Mary's College Indiana",
  "St Mary's College of California",
  "St Mary's College of Maryland",
  "St Mary's University of Minnesota",
  "St Mary's University of San Antonio",
  "St Meinrad College",
  "St Michael's College",
  "St Norbert College",
  "St Olaf College",
  "Stonehill College",
  "St Paul's College",
  "St Petersburg College",
  "St Peter's College",
  "Strayer University",
  "St Thomas Aquinas College",
  "St Thomas University",
  "St Vincent College",
  "St Xavier University",
  "Suffolk University",
  "Sullivan College",
  "Sul Ross State University",
  "Susquehanna University",
  "Swarthmore College",
  "Sweet Briar College",
  "Syracuse University",
  "Tabor College",
  "Talladega College",
  "Tarleton State University",
  "Taylor University",
  "Taylor University Fort Wayne Campus",
  "Teachers College Columbia University",
  "Temple University",
  "Temple University School of Podiatric Medicine",
  "Tennessee State University",
  "Tennessee Technological University",
  "Tennessee Temple University",
  "Tennessee Wesleyan College",
  "Texas A&M International University",
  "Texas A&M University",
  "Texas A&M University - Commerce",
  "Texas A&M University - Corpus Christi",
  "Texas A&M University - Galveston",
  "Texas A&M University - Kingsville",
  "Texas A&M University - Texarkana",
  "Texas Chiropractic College",
  "Texas Christian University",
  "Texas College",
  "Texas College of Osteopathic Medicine",
  "Texas Lutheran University",
  "Texas Southern University",
  "Texas Tech University",
  "Texas Tech University Health Science Center",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "The American College",
  "The Art Institute of Boston",
  "The Art Institutes International Portland",
  "The Art Institutes International San Francisco",
  "The Boston Conservatory",
  "The Catholic University of America",
  "The Chicago School of Professional Psychology",
  "The College of Insurance",
  "The College of New Jersey",
  "The College of Santa Fe",
  "The College of St Scholastica",
  "The College of Westchester",
  "The College of Wooster",
  "The Cooper Union for the Advancement of Science and Art",
  "The Corcoran College of Art",
  "The Curtis Institute of Music",
  "The Defiance College",
  "The Dickinson School of Law",
  "The Illinois Institute of Art-Chicago",
  "The Johns Hopkins University",
  "The Juilliard School",
  "The Leadership Institute of Seattle",
  "The Maryland Institute College of Art",
  "The Master's College",
  "The McGregor School of Antioch University",
  "The Naropa Institute",
  "The New School",
  "The Rockefeller University",
  "The School of the Art Institute of Chicago",
  "The Scripps Research Institute",
  "The Southern Christian University",
  "The Tulane University of New Orleans",
  "The Union Institute",
  "Thiel College",
  "Thomas A Edison State College",
  "Thomas Aquinas College",
  "Thomas College Maine",
  "Thomas Jefferson University",
  "Thomas More College",
  "Thomas More College of Liberal Arts",
  "Thomas University",
  "Thunderbird School of Global Management",
  "Tiffin University",
  "Toccoa Falls College",
  "Tomball College",
  "Tougaloo College",
  "Touro College",
  "Touro University",
  "Towson University",
  "Transylvania University",
  "Trevecca Nazarene University",
  "Tri-College University",
  "Trident University",
  "Trinity Bible College",
  "Trinity Christian College",
  "Trinity College Connecticut",
  "Trinity College of Florida",
  "Trinity College of Vermont",
  "Trinity International University",
  "Trinity International University (Excel) Miami",
  "Trinity University",
  "Tri-State University",
  "Triton College",
  "Troy University",
  "Troy University Dothan",
  "Troy University Montgomery",
  "Troy University Phenix City",
  "Troy University Troy",
  "Truman College",
  "Truman State University",
  "Tufts University",
  "Tui Online University",
  "Tusculum College",
  "Tuskegee University",
  "Uniformed Services Universty of the Health Sciences",
  "Union College",
  "Union College Kentucky",
  "Union College Nebraska",
  "Union Theological Seminary (UTS)",
  "Union University",
  "United States Air Force Academy",
  "United States Coast Guard Academy",
  "United States International University",
  "United States Merchant Marine Academy",
  "United States Military Academy",
  "United States Naval Academy",
  "United States Sports Academy",
  "Unity College",
  "University of Advancing Technology (UAT)",
  "University of Akron",
  "University of Alabama - Birmingham",
  "University of Alabama - Huntsville",
  "University of Alabama - Tuscaloosa",
  "University of Alanta",
  "University of Alaska - Anchorage",
  "University of Alaska - Fairbanks",
  "University of Alaska - Southeast",
  "University of Alaska (System)",
  "University of Arizona",
  "University of Arkansas at Fayetteville",
  "University of Arkansas at Little Rock",
  "University of Arkansas at Monticello",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas for Medical Sciences",
  "University of Arkansas (System)",
  "University of Baltimore",
  "University of Bridgeport",
  "University of California Berkeley",
  "University of California Davis",
  "University of California Hastings College of Law",
  "University of California Irvine",
  "University of California Los Angeles",
  "University of California Merced",
  "University of California Oakland",
  "University of California Riverside",
  "University of California San Diego",
  "University of California San Francisco",
  "University of California Santa Barbara",
  "University of California Santa Cruz",
  "University of California System",
  "University of Central Arkansas",
  "University of Central Florida",
  "University of Central Missouri",
  "University of Central Oklahoma",
  "University of Central Texas",
  "University of Charleston",
  "University of Charleston South Carolina",
  "University of Chicago",
  "University of Cincinnati",
  "University of Colorado at Boulder",
  "University of Colorado at Colorado Springs",
  "University of Colorado at Denver",
  "University of Colorado Health Sciences Center",
  "University of Connecticut",
  "University of Connecticut at Avery Point",
  "University of Connecticut at Hartford",
  "University of Connecticut at Stamford",
  "University of Connecticut at Waterbury",
  "University of Connecticut Health Center",
  "University of Dallas",
  "University of Dayton",
  "University of Delaware",
  "University of Denver",
  "University of Detroit Mercy",
  "University of Dubuque",
  "University of Evansville",
  "University of Findlay",
  "University of Florida",
  "University of Georgia",
  "University of Great Falls",
  "University of Hartford",
  "University of Hawaii - Hilo",
  "University of Hawaii - Manoa",
  "University Of Hawaii - System",
  "University of Hawaii - West Oahu",
  "University of Health Sciences",
  "University of Houston",
  "University of Houston Clear Lake",
  "University of Houston Downtown",
  "University of Houston Victoria",
  "University of Idaho",
  "University of Illinois",
  "University of Illinois at Chicago",
  "University of Illinois at Springfield",
  "University of Illinois at Urbana-Champaign",
  "University of Indianapolis",
  "University of Iowa",
  "University of Kansas",
  "University of Kentucky",
  "University of La Verne",
  "University of Louisiana at Lafayette",
  "University of Louisiana at Monroe",
  "University of Louisville",
  "University of Maine Augusta",
  "University of Maine Farmington",
  "University of Maine Fort Kent",
  "University of Maine Machias",
  "University of Maine Orono",
  "University of Maine Presque Isle",
  "University of Maine (System)",
  "University of Management & Technology",
  "University of Mary",
  "University of Mary Hardin-Baylor",
  "University of Maryland at Baltimore",
  "University of Maryland at College Park",
  "University of Maryland Baltimore County",
  "University of Maryland Eastern Shore",
  "University of Maryland Medicine",
  "University of Maryland (System)",
  "University of Maryland University College",
  "University of Massachusetts at Amherst",
  "University of Massachusetts at Boston",
  "University of Massachusetts at Dartmouth",
  "University of Massachusetts at Lowell",
  "University of Massachusetts Medical Center at Worcester",
  "University of Massachusetts (System)",
  "University of Medicine and Dentistry of New Jersey",
  "University of Memphis",
  "University of Miami",
  "University of Michigan - Ann Arbor",
  "University of Michigan - Dearborn",
  "University of Michigan - Flint",
  "University of Minnesota - Crookston",
  "University of Minnesota - Duluth",
  "University of Minnesota - Morris",
  "University of Minnesota - Twin Cities Campus",
  "University of Mississippi",
  "University of Mississippi Medical Center",
  "University of Missouri - Columbia",
  "University of Missouri - Kansas City",
  "University of Missouri - Saint Louis",
  "University of Mobile",
  "University of Montana",
  "University of Montana Western",
  "University of Montevallo",
  "University of Nebraska - Kearney",
  "University of Nebraska - Lincoln",
  "University of Nebraska Medical Center",
  "University of Nebraska - Omaha",
  "University of Nebraska (System)",
  "University of Nevada - Las Vegas",
  "University of Nevada - Reno",
  "University of New England",
  "University of New England Westbrook College Campus",
  "University of New Hampshire",
  "University of New Hampshire - Manchester",
  "University of New Haven",
  "University of New Mexico",
  "University of New Orleans",
  "University of North Alabama",
  "University of North America",
  "University of North Carolina at Asheville",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina at Charlotte",
  "University of North Carolina at Greensboro",
  "University of North Carolina at Pembroke",
  "University of North Carolina at Wilmington",
  "University of North Dakota",
  "University of Northern Colorado",
  "University of Northern Iowa",
  "University of Northern Virginia",
  "University of Northern Washington",
  "University of North Florida",
  "University of North Texas",
  "University of North Texas Health Science Center at Fort Worth",
  "University of NorthWest",
  "University of Notre Dame",
  "University of Oklahoma",
  "University of Oklahoma Health Sciences Center",
  "University of Oregon",
  "University of Osteopathic Medicine and Health Science",
  "University of Pennsylvania",
  "University of Phoenix",
  "University of Pittsburgh",
  "University of Pittsburgh at Bradford",
  "University of Pittsburgh at Greensburg",
  "University of Pittsburgh at Johnstown",
  "University of Portland",
  "University of Puget Sound",
  "University of Redlands",
  "University of Rhode Island",
  "University of Richmond",
  "University of Rio Grande",
  "University of Rochester",
  "University of San Diego",
  "University of San Francisco",
  "University of Science and Arts of Oklahoma",
  "University of Scranton",
  "University of Sioux Falls",
  "University of South Alabama",
  "University of South Carolina",
  "University of South Carolina - Aiken",
  "University of South Carolina - Beaufort",
  "University of South Carolina - Lancaster",
  "University of South Carolina - Salkehatchie",
  "University of South Carolina - Spartanburg",
  "University of South Carolina - Sumter",
  "University of South Carolina - Union",
  "University of South Dakota",
  "University of Southern California",
  "University of Southern Indiana",
  "University of Southern Maine",
  "University of Southern Mississippi",
  "University of South Florida",
  "University of St Francis",
  "University of St Thomas Houston",
  "University of St Thomas St Paul",
  "University of Tampa",
  "University of Tennessee - Chattanooga",
  "University of Tennessee - Knoxville",
  "University of Tennessee - Martin",
  "University of Tennessee - Memphis",
  "University of Tennessee Space Institute",
  "University of Texas",
  "University of Texas at Arlington",
  "University of Texas at Austin",
  "University of Texas at Brownsville",
  "University of Texas at Dallas",
  "University of Texas at El Paso",
  "University of Texas at San Antonio",
  "University of Texas at Tyler",
  "University of Texas Health Center at Houston",
  "University of Texas Health Center at Tyler",
  "University of Texas Health Science Center at San Antonio",
  "University of Texas MD Anderson Cancer Center",
  "University of Texas Medical Branch Galveston",
  "University of Texas of the Permian Basin",
  "University of Texas Pan American",
  "University of Texas Southwestern Medical Center at Dallas",
  "University of the Arts",
  "University of the District of Columbia",
  "University of the Incarnate World",
  "University of the Ozarks",
  "University of the Pacific",
  "University of the Sciences in Philadelphia",
  "University of the South",
  "University of the Southwest",
  "University of Toledo",
  "University of Tulsa",
  "University of Utah",
  "University of Vermont",
  "University of Virginia",
  "University of Virginia College at Wise",
  "University of Washington",
  "University of Washington Tacoma",
  "University of West Alabama",
  "University of West Florida",
  "University of West Los Angeles",
  "University of Wisconsin - Eau Claire",
  "University of Wisconsin - Green Bay",
  "University of Wisconsin - La Crosse",
  "University of Wisconsin - Madison",
  "University of Wisconsin - Milwaukee",
  "University of Wisconsin - Oshkosh",
  "University of Wisconsin - Parkside",
  "University of Wisconsin - Platteville",
  "University of Wisconsin - River Falls",
  "University of Wisconsin - Stevens Point",
  "University of Wisconsin - Stout",
  "University of Wisconsin - Superior",
  "University of Wisconsin - Whitewater",
  "University of Wyoming",
  "Upper Iowa University",
  "Urbana University",
  "Ursinus College",
  "Ursuline College",
  "Utah State University",
  "Utah Valley State College",
  "Utica College",
  "Valdosta State University",
  "Valley City State University",
  "Valley Forge Christian College",
  "Valparaiso University",
  "Vanderbilt University",
  "VanderCook College of Music",
  "Vanguard University of Southern California",
  "Vassar College",
  "Vennard College",
  "Vermont Law School",
  "Vermont Technical College",
  "Villa Julie College",
  "Villanova University",
  "Virginia College",
  "Virginia Commonwealth University",
  "Virginia Intermont College",
  "Virginia International University",
  "Virginia Military Institute",
  "Virginia Polytechnic Institute and State University (Virginia Tech)",
  "Virginia State University",
  "Virginia Union University",
  "Virginia Wesleyan College",
  "Viterbo College",
  "Voorhees College",
  "Wabash College",
  "Wagner College",
  "Wake Forest University",
  "Walden University",
  "Walla Walla College",
  "Walsh College of Accountancy and Business Administration",
  "Walsh University",
  "Warner Pacific College",
  "Warner Southern College",
  "Warren Wilson College",
  "Wartburg College",
  "Washburn University",
  "Washington and Lee University",
  "Washington Bible College",
  "Washington College",
  "Washington State University",
  "Washington State University Spokane",
  "Washington State University Tri-Cities",
  "Washington State University Vancouver",
  "Washington University in St Louis",
  "Wayland Baptist University",
  "Waynesburg College",
  "Wayne State College",
  "Wayne State University",
  "Webber College",
  "Webb Institute",
  "Weber State University",
  "Webster University",
  "Webster University North Florida",
  "Weill Medical College of Cornell University",
  "Wellesley College",
  "Wells College",
  "Wentworth Institute of Technology",
  "Wesleyan College",
  "Wesleyan University",
  "Wesley College",
  "Wesley College Mississippi",
  "Westbrook University ",
  "West Chester University of Pennsylvania",
  "West Coast University",
  "Western Baptist College",
  "Western Bible College",
  "Western Carolina University",
  "Western Connecticut State University",
  "Western Governors University",
  "Western Illinois University",
  "Western International University",
  "Western Kentucky University",
  "Western Maryland College",
  "Western Michigan University",
  "Western New England College",
  "Western New Mexico University",
  "Western Oregon University",
  "Western State College",
  "Western States Chiropractic College",
  "Western State University College of Law",
  "Western State University College of Law - Orange County",
  "Western Washington University",
  "Westfield State College",
  "West Liberty State College",
  "Westminster College Fulton",
  "Westminster College New Wilmington",
  "Westminster College of Salt Lake City",
  "Westmont College",
  "West Suburban College of Nursing",
  "West Texas A&M University",
  "West Virginia School of Osteopathic Medicine",
  "West Virginia State College",
  "West Virginia University",
  "West Virginia University Institute of Technology",
  "West Virginia Wesleyan College",
  "Westwood College",
  "Wheaton College Massachusetts",
  "Wheeling Jesuit University",
  "Wheelock College",
  "Whitman College",
  "Whittier College",
  "Whitworth College",
  "Wichita State University",
  "Widener University",
  "Wilberforce University",
  "Wilbur Wright College",
  "Wiley College",
  "Wilkes University",
  "Willamette University",
  "William Carey College",
  "William Jewell College",
  "William Mitchell College of Law",
  "William Paterson University",
  "William Penn College",
  "Williams Baptist College",
  "Williams College",
  "William Tyndale College",
  "William Woods University",
  "Wilmington College",
  "Wilson College",
  "Wingate University",
  "Winona State University",
  "Winston-Salem State University",
  "Winthrop University",
  "Wisconsin Lutheran College",
  "Wisconsin School of Professional Psychology",
  "Wittenberg University",
  "Wofford College",
  "Woodbury University",
  "Worcester Polytechnic Institute",
  "Worcester State College",
  "Wright Institute",
  "Wright State University",
  "Xavier University",
  "Xavier University of Louisiana",
  "Yale University",
  "Yeshiva University",
  "York College Nebraska",
  "York College of Pennsylvania",
  "Yorker International University",
  "York University",
  "Youngstown State University",
];

export const PersonalityTests: any = {
  designer: {
    questions: [
      [
        {
          question: "I feel the most satisfied when I...",
          choices: {
            "Successfully mediate between different team perspectives.":
              "Collaborator",
            "Meet or beat a challenging deadline.": "Driven Achiever",
            "Come up with a novel design concept.": "Innovator",
            "Achieve a perfect, polished final design.": "The Perfectionist",
          },
        },
        {
          question: "My favorite part of the design process is...",
          choices: {
            "Seeing results quickly come to life.": "Driven Achiever",
            "Brainstorming and experimenting.": "Innovator",
            "Ensuring every detail is accurate and in place.":
              "The Perfectionist",
            "Working together with others to finalize a design.":
              "Collaborator",
          },
        },
        {
          question: "When I encounter a problem in a project, I prefer to...",
          choices: {
            "Carefully analyze the problem and its details.":
              "The Perfectionist",
            "Tackle the challenge head-on to keep the project moving.":
              "Driven Achiever",
            "Collaborate with the team to find a solution.": "Collaborator",
            "Explore various creative solution.": "Innovator",
          },
        },
      ],
      [
        {
          question: "I feel energized in a work environment that is...",
          choices: {
            "Fast-paced and dynamic.": "Driven Achiever",
            "Collaborative and team-oriented.": "Collaborator",
            "Open and creative.": "Innovator",
            "Quet and focused, allowing for deep concentration.":
              "The Perfectionist",
          },
        },
        {
          question: "My ideal role in a team is...",
          choices: {
            "The project manager or leader.": "Driven Achiever",
            "The creative idea generator.": "Innovator",
            "The communicator or mediator.": "Collaborator",
            "The quality assurance specialist.": "The Perfectionist",
          },
        },
        {
          question: "I handle feedback by...",
          choices: {
            "Using it to adapt my strategy and stay on track.":
              "Driven Achiever",
            "Seeing it as an opportunity to come up with new ideas.":
              "Innovator",
            "Facilitating discussions around it with the team.": "Collaborator",
            "Using it to refine and perfect my work.": "The Perfectionist",
          },
        },
      ],
      [
        {
          question: "When learning a new tool or technique, I tend to...",
          choices: {
            "Learn in a group or workshop setting.": "Collaborator",
            "Play around and experiment with it.": "Innovator",
            "Learn it quickly so I can apply it to my work right away.":
              "Driven Achiever",
            "Study it thoroughly and practice until I perfect it.":
              "The Perfectionist",
          },
        },
        {
          question: "My approach to meeting deadlines it to...",
          choices: {
            "Prioritize and work hard to deliver on time.": "Driven Achiever",
            "Double-check every detail to ensure the final product is perfect.":
              "The Perfectionist",
            "Communicate regularly with the team to keep everyone on the same page.":
              "Collaborator",
            "Adjust and evolve my ideas as I go along.": "Innovator",
          },
        },
        {
          question: "I prefer projects that...",
          choices: {
            "Are high-stakes and deadline-driven.": "Driven Achiever",
            "Require meticulous attention to detail.": "The Perfectionist",
            "Allow me to think creatively and try new things.": "Innovator",
            "Involve a lot of teamwork and collaboration.": "Collaborator",
          },
        },
      ],
      [
        {
          question: "When confronted with a complex problem, I...",
          choices: {
            "Formulate a strategic plan to tackle it efficiently.":
              "Driven Achiever",
            "Break it down into smaller parts and come up with creative solutions.":
              "Innovator",
            "Discuss it with my team to gather different perspectives.":
              "Collaborator",
            "Dive into the details to understand it thoroughly.":
              "The Perfectionist",
          },
        },
        {
          question: "My strength in a team is...",
          choices: {
            "Keeping the team focused and on schedule.": "Driven Achiever",
            "Enhancing communication and cohesion.": "Collaborator",
            "Bringing in fresh ideas and perspectives.": "Collaborator",
            "Ensuring high quality and precision.": "The Perfectionist",
          },
        },
        {
          question: "My attitude towards change and new trends is...",
          choices: {
            "Adapting to them swiftly to stay ahead.": "Driven Achiever",
            "Facilitating discussion about them within the team.":
              "Collaborator",
            "Assessing their impact on the details of my work.":
              "The Perfectionist",
            "Embracing them as opportunities for new ideas.": "Innovator",
          },
        },
      ],
    ],
    icon: Images.Designer,

    results: {
      Innovator: {
        text: "They love to solve problems and come up with new ideas. You’re open to learning about new things and work well on a range of design tasks.",
        icon: Images.Innovator,
      },
      Collaborator: {
        text: "They work well in teams and have strong communication skills. They can take on feedback and work well with clients and teammates. This makes them great for roles where they need to interact with different people and work in a team.",
        icon: Images.Collaborator,
      },
      "The Perfectionist": {
        text: "They pay close attention to the small details and focus on producing high-quality work. They're great for projects that need a deep understanding of complex systems or a high level of detail.",
        icon: Images.Perfectionist,
      },
      "Driven Achiever": {
        text: "They work well under pressure and can manage tight deadlines. They're good at planning their work and delivering results quickly. This makes them great for roles in fast-moving environments or where they need to handle lots of tasks at once.",
        icon: Images.Achiever,
      },
    },
  },
  developer: {
    questions: [
      [
        {
          question: "When I encounter a bug in my code, I tend to...",
          choices: {
            "Dive into the details to understand and resolve it.":
              "Problem Solver",
            "Learn from the experience to prevent similar issues in the future.":
              "Adaptive Learner",
            "Collaborate with team members to find a solution.":
              "Collaborative Team Player",
            "Carefully test and debug to ensure no other issues arise.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "In a group project, my preferred role is to...",
          choices: {
            "Learn and implement new technologies as required.":
              "Problem Solver",
            "Solve complex problems or issues that arise.": "Adaptive Learner",
            "Coordinate with team members and facilitate communication.":
              "Collaborative Team Player",
            "Review and fine-tune the project details.":
              "Detail-Oriented Developer",
          },
        },
        {
          question:
            "When learning a new programming language or framework, I prefer to...",
          choices: {
            "Understand it thoroughly by solving complex problems.":
              "Problem Solver",
            "Experiment and adapt quickly to it.": "Adaptive Learner",
            "Learn it with a team or study group.": "Collaborative Team Player",
            "Pay attention to the details and master it slowly.":
              "Detail-Oriented Developer",
          },
        },
      ],
      [
        {
          question:
            "During a coding challenge, I feel most confident when I am...",
          choices: {
            "Debugging and solving complex problems.": "Problem Solver",
            "Adapting to unexpected changes or issues.": "Adaptive Learner",
            "Working with a team to brainstorm and implement solutions.":
              "Collaborative Team Player",
            "Ensuring the code is perfect down to the last detail.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "My approach to coding is...",
          choices: {
            "Taking the time to understand the problem deeply before coding.":
              "Problem Solver",
            "Quickly adapting my approach based on the requirements.":
              "Adaptive Learner",
            "Collaborating with others and incorporating their feedback.":
              "Collaborative Team Player",
            "arefully planning and executing the code.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "I feel most fulfilled in a project when I...",
          choices: {
            "Solve a particularly challenging problem.": "Problem Solver",
            "Learn a new technology or framework.": "Adaptive Learner",
            "Collaborate effectively with my team.":
              "Collaborative Team Player",
            "Ensure the final product is free of errors and issues.":
              "Detail-Oriented Developer",
          },
        },
      ],
      [
        {
          question: "During the development process, I tend to focus on...",
          choices: {
            "The big picture, and how all the pieces fit together.":
              "Problem Solver",
            "Continually learning and growing with the project.":
              "Adaptive Learner",
            "The team dynamics and communication.": "Collaborative Team Player",
            "The small details and ensuring everything is in order.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "My attitude towards new technology trends is...",
          choices: {
            "Analyzing them and understanding how they can solve complex problems.":
              "Problem Solver",
            "Learning and adapting quickly to incorporate them into my work.":
              "Adaptive Learner",
            "Discussing and exploring them with my team.":
              "Collaborative Team Player",
            "Cautiously examining and understanding all the details before adopting them.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "When I code, I enjoy...",
          choices: {
            "Tackling complex logical problems.": "Problem Solver",
            "Coordinating and communicating with a team.": "Adaptive Learner",
            "Allow me to think creatively and try new things.":
              "Collaborative Team Player",
            "Ensuring that every line of my code is perfect.":
              "Detail-Oriented Developer",
          },
        },
      ],
      [
        {
          question: "In a software development course, I would excel in...",
          choices: {
            "Projects involving complex problem solving.": "Problem Solver",
            "Courses that frequently introduce new technologies or frameworks.":
              "Adaptive Learner",
            "Group projects or collaborative activities.":
              "Collaborative Team Player",
            "Tasks that require thorough attention to detail and planning.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "When faced with an error in my code, I typically...",
          choices: {
            "Delve into the problem and dissect it until I understand it.":
              "Problem Solver",
            "Adapt my approach based on the error message and what I learn from it.":
              "Adaptive Learner",
            "Collaborate with others to solve the error.":
              "Collaborative Team Player",
            "Double-check my code and fix any discrepancies.":
              "Detail-Oriented Developer",
          },
        },
        {
          question: "When I work on a project, I feel most satisfied when I...",
          choices: {
            "Have solved a complex or challenging problem.": "Problem Solver",
            "Have successfully learned and implemented a new technology or framework.":
              "Adaptive Learner",
            "Have contributed positively to the team and project.":
              "Collaborative Team Player",
            "Have ensured the project is free from errors and runs smoothly.":
              "Detail-Oriented Developer",
          },
        },
      ],
    ],
    icon: Images.Developer,
    results: {
      "Problem Solver": {
        text: "They like figuring out tricky problems and use logic to do so. They know many different computer languages and can build many kinds of software. They enjoy digging into code to understand and fix issues.",
        icon: Images.Innovator,
      },
      "Adaptive Learner": {
        text: "They are open to new ideas and can quickly adjust to new technologies. They love learning, which helps them work on many kinds of software projects. They can learn fast from their own experiences and are not stuck to using just one technology.",
        icon: Images.Achiever,
      },
      "Detail-Oriented Developer": {
        text: "They pay very close attention to details and like to plan carefully before they start working. They are good at managing all parts of making software, including maintaining and updating it. They make sure they don't miss any small details, whether it's in the code or in the process.",
        icon: Images.Perfectionist,
      },
      "Collaborative Team Player": {
        text: "They work well with others and help create a positive team atmosphere. They can work well with other developers on different kinds of projects. They like sharing their ideas and are open to learning from others.",
        icon: Images.Collaborator,
      },
    },
  },
  engineer: {
    questions: [
      [
        {
          question:
            "When faced with a complex engineering problem, I tend to...",
          choices: {
            "Break it down methodically and analyze each part.":
              "Analytical Thinker",
            "Come up with innovative solutions and new approaches.":
              "Innovative Visionary",
            "Use practical and hands-on methods to solve it.":
              "Practical Implementer",
            "Communicate with others to get different perspectives.":
              "Effective Communicator",
          },
        },
        {
          question: "I feel most comfortable in a project when I am...",
          choices: {
            "Managing and interpreting a lot of data.": "Analytical Thinker",
            "Experimenting with new ideas and technologies.":
              "Innovative Visionary",
            "Implementing and testing practical solutions.":
              "Practical Implementer",
            "Ensuring everyone on the team is updated and on the same page.":
              "Effective Communicator",
          },
        },
        {
          question: "My approach to a group project is usually to...",
          choices: {
            "Take on tasks that require technical skills and attention to detail.":
              "Analytical Thinker",
            "Propose and try out new ideas.": "Innovative Visionary",
            "Focus on practical implementation and results.":
              "Practical Implementer",
            "Facilitate communication and collaboration.":
              "Effective Communicator",
          },
        },
      ],
      [
        {
          question:
            "When I'm learning a new concept in class, I find it helpful to...",
          choices: {
            "Analyze the concept in detail and look for patterns.":
              "Analytical Thinker",
            "Think of innovative applications for the concept.":
              "Innovative Visionary",
            "Apply the concept in a practical project or experiment.":
              "Practical Implementer",
            "Discuss the concept with classmates and instructors.":
              "Effective Communicator",
          },
        },
        {
          question: "I feel most successful in my studies when I...",
          choices: {
            "Solve complex problems using logical analysis.":
              "Analytical Thinker",
            "Create something new or improve on existing designs.":
              "Innovative Visionary",
            "See practical results from my work.": "Practical Implementer",
            "Successfully communicate or present an idea or project.":
              "Effective Communicator",
          },
        },
        {
          question: "In a team project, I usually take the role of...",
          choices: {
            "The data manager or technical analyst.": "Analytical Thinker",
            "The idea generator or designer.": "Innovative Visionary",
            "The builder or implementer.": "Practical Implementer",
            "The coordinator or communicator.": "Effective Communicator",
          },
        },
      ],
      [
        {
          question: "When working on a project, I get excited about...",
          choices: {
            "Finding patterns and insights in data.": "Analytical Thinker",
            "Trying new technologies or experimental ideas.":
              "Innovative Visionary",
            "Making and testing prototypes.": "Practical Implementer",
            "Explaining the project and its progress to others.":
              "Effective Communicator",
          },
        },
        {
          question:
            "During a challenging project, I usually deal with difficulties by...",
          choices: {
            "Analyzing the issue in depth to find a solution.":
              "Analytical Thinker",
            "Coming up with creative solutions or alternatives.":
              "Innovative Visionary",
            "Applying practical methods to overcome the problem.":
              "Practical Implementer",
            "Discussing the issue with others to gain insights.":
              "Effective Communicator",
          },
        },
        {
          question: "I enjoy classes where I get to...",
          choices: {
            "Analyze complex problems and data.": "Analytical Thinker",
            "Create and innovate new designs or concepts.":
              "Innovative Visionary",
            "Apply theories and concepts in real-world settings.":
              "Practical Implementer",
            "Communicate and collaborate with peers.": "Effective Communicator",
          },
        },
      ],
      [
        {
          question: "In my engineering studies, I excel in...",
          choices: {
            "Tasks requiring a high level of technical skills and accuracy.":
              "Analytical Thinker",
            "Projects that demand creativity and innovation.":
              "Innovative Visionary",
            "Practical applications and hands-on tasks.":
              "Practical Implementer",
            "Roles that involve a lot of communication and collaboration.":
              "Effective Communicator",
          },
        },
        {
          question: "I tend to contribute most to team projects by...",
          choices: {
            "Analyzing data and making informed decisions.":
              "Analytical Thinker",
            "Proposing innovative ideas and designs.": "Innovative Visionary",
            "Taking on practical tasks and making things happen.":
              "Practical Implementer",
            "Keeping the team updated and facilitating communication.":
              "Effective Communicator",
          },
        },
        {
          question: "My strength in an engineering project is...",
          choices: {
            "Analyzing and interpreting complex data.": "Analytical Thinker",
            "Generating innovative solutions and designs.":
              "Innovative Visionary",
            "Implementing practical solutions and achieving results.":
              "Practical Implementer",
            "Communicating effectively with the team and other stakeholders.":
              "Effective Communicator",
          },
        },
      ],
    ],
    icon: Images.Engineer,
    results: {
      "Analytical Thinker": {
        text: "These students excel in solving problems and making sense of complex information. They enjoy digging into data, making decisions, and fine-tuning procedures. With a knack for organization and a keen eye for detail, they can handle challenging tasks effectively.",
        icon: Images.AnalyticThinker,
      },
      "Innovative Visionary": {
        text: "These students are all about creativity and trying new things. They're comfortable with cutting-edge tech and love bringing fresh ideas to the table. They thrive when they're developing new designs or algorithms, or finding innovative tech solutions.",
        icon: Images.Achiever,
      },
      "Practical Implementer": {
        text: "These students are hands-on problem solvers who focus on achieving results. They're comfortable with a range of practical tasks, from building hardware to setting up networks. They have a solid understanding of various tools and platforms and can apply their knowledge in useful ways.",
        icon: Images.Collaborator,
      },
      "Effective Communicator": {
        text: "These students stand out with their excellent communication and team skills. They're great at explaining complex ideas simply and clearly, and they make sure everyone in the group is updated. They excel in roles that require talking with others and ensuring the team works well together.",
        icon: Images.RelationshipBuilder,
      },
    },
  },
  business: {
    questions: [
      [
        {
          question: "I tend to approach a new project by...",
          choices: {
            "Brainstorming innovative strategies.": "Creative Innovator",
            "Breaking down the project details and planning the process.":
              "Analytical Thinker",
            "Setting clear goals and outlining steps to achieve them.":
              "Strategic Planner",
            "Assessing team dynamics and fostering communication.":
              "Relationship Builder",
          },
        },
        {
          question: "When assigned a complex task, I prefer to...",
          choices: {
            "Approach it strategically and develop a plan.":
              "Strategic Planner",
            "Analyze the data and details to find a solution.":
              "Analytical Thinker",
            "Foster collaboration within my team to brainstorm solutions.":
              "Relationship Builder",
            "Use creative thinking to discover new solutions.":
              "Creative Innovator",
          },
        },
        {
          question: "My preferred method of studying for exams is to...",
          choices: {
            "Set up study groups to review and discuss the material.":
              "Relationship Builder",
            "Create a detailed study plan and follow it diligently.":
              "Analytical Thinker",
            "Use innovative techniques, like mind mapping or visual aids.":
              "Creative Innovator",
            "Break down the syllabus into sections and create a study timeline.":
              "Analytical Thinker",
          },
        },
      ],
      [
        {
          question: "During group projects, I tend to...",
          choices: {
            "Manage the timeline and ensure tasks are completed as planned.":
              "Strategic Planner",
            "Facilitate team communication and ensure everyone is heard.":
              "Relationship Builder",
            "Come up with new ideas and strategies for our project.":
              "Creative Innovator",
            "Analyze the project requirements and ensure all details are addressed.":
              "Analytical Thinker",
          },
        },
        {
          question: "I find I excel most in courses that require...",
          choices: {
            "Data analysis and logical reasoning.": "Analytical Thinker",
            "Group work and interpersonal communication.":
              "Relationship Builder",
            "Strategy development and project management.": "Strategic Planner",
            "Creative thinking and idea generation.": "Creative Innovator",
          },
        },
        {
          question: "When faced with a tight deadline, I tend to...",
          choices: {
            "Prioritize tasks strategically to maximize efficiency.":
              "Strategic Planner",
            "Break down tasks into manageable parts and systematically tackle them.":
              "Analytical Thinker",
            "Rally the team and ensure everyone is contributing effectively.":
              "Relationship Builder",
            "Come up with innovative solutions to accelerate the process.":
              "Creative Innovator",
          },
        },
      ],
      [
        {
          question: "I enjoy classes that offer opportunities to...",
          choices: {
            "Build relationships and interact with others.":
              "Relationship Builder",
            "Apply strategic thinking and leadership skills.":
              "Strategic Planner",
            "Use creative skills to solve problems or create something new.":
              "Creative Innovator",
            "Dive deep into complex theories or data sets.":
              "Analytical Thinker",
          },
        },
        {
          question: "My approach to a case study is to...",
          choices: {
            "Analyze the data and examine the situation from multiple angles.":
              "Analytical Thinker",
            "Develop a structured plan to solve the problem.":
              "Strategic Planner",
            "Leverage team collaboration to explore different viewpoints.":
              "Relationship Builder",
            "Apply creative thinking to imagine innovative solutions.":
              "Creative Innovator",
          },
        },
        {
          question: "I feel most comfortable in roles that require me to...",
          choices: {
            "Use my creativity and innovative thinking.": "Creative Innovator",
            "Manage relationships and foster collaboration.":
              "Relationship Builder",
            "Develop and execute a strategic plan.": "Strategic Planner",
            "Analyze complex information and provide insightful conclusions.":
              "Analytical Thinker",
          },
        },
      ],
      [
        {
          question: "In a school club or organization, I prefer to...",
          choices: {
            "Plan events and manage projects.": "Strategic Planner",
            "Foster community and facilitate communication.":
              "Creative Innovator",
            "Generate fresh ideas for activities or improvements.":
              "Relationship Builder",
            "Analyze the organization's performance and identify areas for improvement.":
              "Analytical Thinker",
          },
        },
        {
          question: "In group discussions, I tend to...",
          choices: {
            "Dive into the details and provide in-depth analyses.":
              "Analytical Thinker",
            "Encourage and facilitate open communication.":
              "Relationship Builder",
            "Present innovative perspectives and ideas.": "Creative Innovator",
            "Drive the discussion towards our goals and objectives.":
              "Strategic Planner",
          },
        },
        {
          question: "I find satisfaction in tasks that involve...",
          choices: {
            "Setting and achieving measurable goals.": "Strategic Planner",
            "Innovating and thinking creatively.": "Creative Innovator",
            "Building relationships and fostering teamwork.":
              "Relationship Builder",
            "Analyzing complex information and finding patterns.":
              "Analytical Thinker",
          },
        },
      ],
    ],
    icon: Images.Business,
    results: {
      "Analytical Thinker": {
        text: "These individuals are good at explaining things and working with others. They can explain complex concepts in a simple way. They're skilled at working with clients and team members to make sure everyone understands the project goals.",
        icon: Images.AnalyticThinker,
      },
      "Creative Innovator": {
        text: "These individuals thrive in creative and innovative settings, unafraid to explore new ideas. They excel in roles like content creation, strategy brainstorming, and growth identification, often stemming from fields such as marketing, advertising, or design.",
        icon: Images.Innovator,
      },
      "Relationship Builder": {
        text: "Embodying strong interpersonal skills, these individuals excel at building and maintaining relationships. They are empathetic, approachable, and exhibit excellent communication abilities, making them proficient in customer outreach and team collaboration roles. They thrive in roles involving client relationships and team collaborations.",
        icon: Images.RelationshipBuilder,
      },
      "Strategic Planner": {
        text: "Goal-oriented and decisive, these individuals excel at planning and executing strategies. They are comfortable leading projects and making strategic decisions, demonstrating strengths in setting up campaigns, coordinating events, and managing diverse project elements. Their background often lies in business or project management.",
        icon: Images.Achiever,
      },
    },
  },
};

export const ProjectTemplates = [
  {
    appliedPromoCode: null,
    budgetType: "HOURLY_RATE",
    companySite: "",
    description:
      "<p><strong>Position Overview:</strong></p><p>The Business Development Representative will focus on lead generation efforts, assisting the business development team in identifying, qualifying, and nurturing potential business opportunities. This role offers a unique chance for a college student to gain real-world experience in the field of business development and sales.</p><p><br></p><p><strong>Benefits:</strong></p><ul><li>Valuable experience in a real-world business development environment.</li><li>Mentorship from experienced business development professionals.</li><li>Opportunity to contribute to and impact real company projects and initiatives.</li><li>Flexible schedule to accommodate academic commitments.</li><li>Potential for future full-time employment.</li></ul>",
    docs: [],
    duration: 1,
    durationType: "UNTIL_ENDED",
    hourlyRate: 25,
    hoursPerWeek: 10,
    keyResponsibilities:
      "<p>1) Lead Generation:</p><ul><li>Conduct market research to identify potential leads and target markets.</li><li>Utilize tools, databases, and software to source and qualify potential leads.</li><li>Attend industry events, webinars, and workshops to generate leads and gather industry intelligence.</li></ul><p><br></p><p>2) Engagement &amp; Outreach:</p><ul><li>Reach out to potential leads through cold calls, emails, and social media outreach.</li><li>Assist in the development and execution of targeted marketing campaigns.</li><li>Track and document all lead generation activities using CRM software.</li></ul><p><br></p><p>3) Collaboration &amp; Teamwork:</p><ul><li>Work closely with the business development and marketing teams to align lead generation efforts.</li><li>Provide feedback and insights on lead quality and sources to optimize lead generation strategies.</li><li>Attend team meetings to report on progress, share insights, and learn about the overall business development strategy.</li></ul><p><br></p><p>4) Continuous Learning:</p><ul><li>Stay updated on industry best practices and emerging trends in lead generation.</li><li>Participate in internal training and development sessions.</li></ul><p><br></p>",
    qualifications:
      "<ul><li>Currently enrolled in a Bachelor’s program, preferably in Business, Marketing, Communications, or a related field.</li><li>Strong communication and interpersonal skills.</li><li>Basic understanding of sales processes and lead generation techniques.</li><li>Proficiency in Microsoft Office Suite and/or Google Workspace. Familiarity with CRM platforms is a plus.</li><li>Proactive, self-starter with a strong desire to learn and achieve.</li></ul>",
    retainerAmount: 0,
    skills: [
      "ANALYTICAL THINKING",
      "VERBAL AND WRITTEN COMMUNICATION",
      "MARKET RESEARCH",
      "CRM TOOL UTILIZATION",
      "NETWORKING",
      "ADAPTABILITY",
      "TEAM COLLABORATION",
      "TIME MANAGEMENT",
      "MICROSOFT OFFICE",
    ],
    studentHourlyRate: 20,
    studentPersonalityRanking: [
      "Analytical Thinker",
      "Creative Innovator",
      "Relationship Builder",
      "Strategic Planner",
    ],
    studentRetainerAmount: 0,
    title: "Business Development Rep",
    type: "BUSINESS",
  },
  {
    appliedPromoCode: null,
    budgetType: "HOURLY_RATE",
    companySite: "",
    description:
      "<p><strong>Position Overview:</strong></p><p>The Operations Representative will be integral in providing top-tier support to the CEO, executing a variety of administrative tasks while offering a fresh perspective on optimizing daily operations. This unique internship offers the opportunity for a college student to immerse themselves in the executive operations of a leading company.</p><p><br></p><p><strong>Benefits:</strong></p><ul><li>Direct experience supporting executive-level leadership.</li><li>Mentorship opportunities from the CEO and senior management team.</li><li>Exposure to high-level decision-making and strategy.</li><li>Flexible scheduling to accommodate academic commitments.</li><li>Potential for future full-time employment opportunities.</li></ul><p><br></p>",
    docs: [],
    duration: 1,
    durationType: "UNTIL_ENDED",
    hourlyRate: 25,
    hoursPerWeek: 10,
    keyResponsibilities:
      "<p>1) Organizational Support:</p><ul><li>Efficiently manage the CEO's calendar, coordinating appointments, meetings, and events.</li><li>Prepare materials and presentations for meetings on behalf of the CEO.</li><li>Handle incoming and outgoing communications, ensuring timely responses.</li></ul><p><br></p><p>2) Administrative Assistance:</p><ul><li>Assist with travel arrangements, event planning, and other logistical tasks.</li><li>Organize, file, and manage important documents and records.</li><li>Take detailed notes during meetings and provide summaries when necessary.</li></ul><p><br></p><p>3) Project Management:</p><ul><li>Collaborate on special initiatives assigned by the CEO, contributing a fresh perspective.</li><li>Coordinate with various departments, ensuring alignment on projects and tasks.</li><li>Track and report on the progress of ongoing initiatives.</li></ul><p><br></p><p>4) Continuous Improvement:</p><ul><li>Proactively identify opportunities for process enhancement in executive operations.</li><li>Stay informed on the latest tools and best practices for executive support and administration.</li></ul><p><br></p>",
    qualifications:
      "<ul><li>Currently enrolled in a Bachelor’s program, preferably in Business Administration, Communications, or a related field.</li><li>Exceptional organizational and multitasking abilities.</li><li>Strong attention to detail and problem-solving skills.</li><li>Proficiency in Microsoft Office Suite and/or Google Workspace.</li><li>Strong interpersonal and communication skills.</li></ul>",
    retainerAmount: 0,
    skills: [
      "TIME MANAGEMENT",
      "WRITTEN AND VERBAL COMMUNICATION",
      "PROJECT COORDINATION",
      "ORGANIZATIONAL PROFICIENCY",
      "RESEARCH AND ANALYSIS",
      "OFFICE SOFTWARE PROFICIENCY",
      "INITIATIVE AND PROACTIVE THINKING",
      "CONFIDENTIALITY AND DISCRETION",
      "OPERATIONS",
    ],
    studentHourlyRate: 20,
    studentPersonalityRanking: [
      "Analytical Thinker",
      "Creative Innovator",
      "Relationship Builder",
      "Strategic Planner",
    ],
    studentRetainerAmount: 0,
    title: "Admin Assistant",
    type: "BUSINESS",
  },
  {
    appliedPromoCode: null,
    budgetType: "HOURLY_RATE",
    companySite: "",
    description:
      "<p><strong>Position Overview:</strong></p><p>The Digital Marketing Representative will dive deep into the dynamic world of modern marketing, focusing on both social media channels and classic marketing strategies. This role provides a golden opportunity for a college student to garner real-world experience, from managing a brand's presence on platforms like Instagram, LinkedIn, and TikTok to crafting engaging newsletters and email campaigns.</p><p><br></p><p><strong>Benefits:</strong></p><ul><li>Gain hands-on experience in various facets of digital marketing.</li><li>Work closely with experienced marketing professionals.</li><li>Be at the forefront of marketing strategy and execution for a leading brand.</li><li>Flexible scheduling to accommodate academic commitments.</li><li>Potential for future full-time employment opportunities.</li></ul><p><br></p>",
    docs: [],
    duration: 1,
    durationType: "UNTIL_ENDED",
    hourlyRate: 25,
    hoursPerWeek: 10,
    keyResponsibilities:
      "<p>1) Social Media Management:</p><ul><li>Create, curate, and manage published content across various social platforms.</li><li>Engage with our online community, responding to comments, direct messages, and mentions to build brand loyalty.</li><li>Monitor and analyze performance metrics to gauge the effectiveness of the content.</li></ul><p><br></p><p>2) Newsletter &amp; Email Campaigns:</p><ul><li>Assist in the creation, design, and distribution of newsletters.</li><li>Develop and execute email marketing campaigns, ensuring content is optimized for desktop and mobile viewing.</li><li>Analyze email performance metrics, recommending adjustments as needed.</li></ul><p><br></p><p>3) Content Creation:</p><ul><li>Collaborate with the marketing team to produce compelling visual and written content for various digital channels.</li><li>Stay updated on industry trends to ensure the creation of relevant and innovative content.</li></ul><p><br></p><p>4) Marketing Strategy Support:</p><ul><li>Contribute ideas for promotional campaigns and marketing strategies.</li><li>Support the team in the implementation and tracking of marketing campaigns.</li><li>Engage in brainstorming sessions, bringing fresh and innovative ideas to the table.</li></ul>",
    qualifications:
      "<ul><li>Currently enrolled in a Bachelor’s program, preferably in Marketing, Business, Communications, or a related field.</li><li>Familiarity with major social media platforms and their respective best practices.</li><li>Basic understanding of email marketing tools and techniques.</li><li>Strong written and visual communication skills.</li></ul>",
    retainerAmount: 0,
    skills: [
      "CRM PLATFORM PROFICIENCY",
      "SOCIAL MEDIA PLATFORM EXPERTISE",
      "EMAIL MARKETING",
      "GRAPHIC DESIGN TOOLS",
      "UI/UX DESIGN TOOLS",
      "CONTENT MANAGEMENT SYSTEMS",
      "SEO TOOLS AND BEST PRACTICES",
      "DATA ANALYTICS TOOLS",
      "VIDEO EDITING SOFTWARE",
      "A/B TESTING METHODOLOGIES",
    ],
    studentHourlyRate: 20,
    studentPersonalityRanking: [
      "Analytical Thinker",
      "Creative Innovator",
      "Relationship Builder",
      "Strategic Planner",
    ],
    studentRetainerAmount: 0,
    title: "Digital Marketing Rep",
    type: "BUSINESS",
  },
];
