import React from "react";
import { wrapHTMLContent } from "utils/string";

type Props = {
  description: string;
  responsibilties: string[];
  qualifications: string[];
  skills: string[];
};

const ProjectDetails = ({
  description,
  responsibilties,
  qualifications,
  skills,
}: Props) => {
  // dangerouslySetInnerHTML={{
  //   __html: wrapHTMLContent(
  //     state.selectedProject?.keyResponsibilities
  //   ),
  // }}
  return (
    <div>
      <p className="font_18 fw-bold text_primary mb-2">Description</p>
      <p
        className="text_primary fs-6"
        dangerouslySetInnerHTML={{ __html: wrapHTMLContent(description) }}
      />
      <p className="font_18 fw-bold text_primary mb-2 mt-4">
        Key Responsibilities
      </p>
      <ul className="list_none">
        {responsibilties.map((data: string, index: number) => (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: wrapHTMLContent(data) }}
          />
        ))}
      </ul>
      <p className="font_18 fw-bold text_primary mb-2 mt-4">Qualifications</p>
      <ul className="list_none">
        {qualifications.map((data, index) => (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: wrapHTMLContent(data) }}
          />
        ))}
      </ul>
      <p className="font_18 fw-bold text_primary mb-2 mt-4">Skills</p>
      <div className="d-flex flex-wrap mt-4">
        {skills.map((data, index) => (
          <span key={index} className="tag">
            {data}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetails;
