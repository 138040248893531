import { useSearchParams } from "react-router-dom";
import { AccountType } from "utils/constants";
import BusinessSettings from "components/Settings/BusinessSettings";
import StudentSettings from "components/Settings/StudentSettings";
import BusinessProfileSettings from "components/Settings/BusinessSettings/BusinessProfileSettings";
import Profile from "components/Profile";
import { getSignedUrlOfS3 } from "api";
import { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  user: any;
  manuBarExpend: boolean;
  setUser: any;
  onLogout: any;
  manuBarToogle: () => void;
};

export default function SettingsContainer({
  user,
  manuBarExpend,
  setUser,
  onLogout,
  manuBarToogle,
}: Props) {
  const [searchParams] = useSearchParams();
  const [state, setState] = useMergeState({ profileImageUrl: "" });

  const projectId = searchParams.get("project") || "";

  const studentId = searchParams.get("student") || "";

  const showRTI = searchParams.get("showRTI");

  const goToSettings = searchParams.get("goto") === "settings" || false;

  const isBusinessView = projectId && studentId ? true : false;

  const checkAvatar = async () => {
    if (user?.avatar) {
      const res = await getSignedUrlOfS3(user?.avatar?.key);
      if (res.success) {
        setState({ profileImageUrl: res?.data?.url });
      }
    }
  };

  useEffect(() => {
    checkAvatar();
  }, []);

  return (
    <div>
      {user?.accountType === AccountType.STUDENT || isBusinessView ? (
        <Profile
          user={user}
          manuBarExpend={manuBarExpend}
          setUser={setUser}
          projectId={projectId}
          studentId={studentId}
          isBusinessView={isBusinessView}
          showRTI={showRTI}
          manuBarToogle={manuBarToogle}
          profileImageUrl={state?.profileImageUrl}
        />
      ) : // <StudentSettings
      //   user={user}
      //   setUser={setUser}
      //   projectId={projectId}
      //   studentId={studentId}
      //   isBusinessView={isBusinessView}
      //   showRTI={showRTI}
      // />
      user?.accountType === AccountType.BUSINESS ? (
        // <BusinessSettings user={user} setUser={setUser} />
        <BusinessProfileSettings
          user={user}
          manuBarExpend={manuBarExpend}
          setUser={setUser}
          manuBarToogle={manuBarToogle}
          profileImageUrl={state?.profileImageUrl}
          goToSettings={goToSettings}
        />
      ) : null}
    </div>
  );
}
