import React from "react";

const ProfileSettings = ({
  user,
  state,
  isBusinessView,
  handleCreateConnectAccount,
  handleNotificationSettingsChange,
}: {
  user: any;
  isBusinessView: any;
  state: any;
  handleCreateConnectAccount: any;
  handleNotificationSettingsChange: any;
}) => {
  return (
    <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-4 pb-md-4 overflow-hidden">
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane show active fade px-0 px-md-3 py-1 w-100"
          id="setting"
          role="tabpanel"
          aria-labelledby="setting-tab"
        >
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 mb-3 mb-lg-0">
              <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                <div className="d-flex justify-content-between pb-4">
                  <p className="fw-bold text_black fs-5">Payouts</p>
                  {!user?.payoutsEnabled && (
                    <button
                      type="button"
                      className="btn btn_small bg_secondary"
                      onClick={handleCreateConnectAccount}
                    >
                      Connect Payout
                    </button>
                  )}
                </div>
                {/* <p>Account Number</p>
                <div className="border border_gray my-3" />
                <div className="d-flex">
                  <p className="fw-bold">**** **** **** 3219</p>
                  <p className="text_secondary ps-4 fw_500">Set as default</p>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                <p className="fs-5 fw-bold pb-4">SMS Notifcations</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Posted</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectPosted"
                      checked={
                        state?.notificationSettings?.email?.newProjectPosted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Requested to Interview</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="requestedToInterview"
                      checked={
                        state?.notificationSettings?.email?.requestedToInterview
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Started</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectStarted"
                      checked={
                        state?.notificationSettings?.email?.newProjectStarted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Timesheet Reminders</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="timesheetReminders"
                      checked={
                        state?.notificationSettings?.email?.timesheetReminders
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0 mt-3">
                <p className="fs-5 fw-bold pb-4">Email Notifcations</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Posted</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectPosted"
                      checked={
                        state?.notificationSettings?.sms?.newProjectPosted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Requested to Interview</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="requestedToInterview"
                      checked={
                        state?.notificationSettings?.sms?.requestedToInterview
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Started</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectStarted"
                      checked={
                        state?.notificationSettings?.sms?.newProjectStarted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Timesheet Reminders</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="timesheetReminders"
                      checked={
                        state?.notificationSettings?.sms?.timesheetReminders
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
