import moment from "moment";
import {
  BudgetType,
  DiscountType,
  PersonalityTests,
  ProjectDurationType,
} from "utils/constants";
import { formatDate } from "./date";

export const shouldShowPremiumFeatures = (user: any) => {
  if (user?.business?.atv?.isATV) {
    return user?.business?.atv?.optedIn;
  }
  return true;
};

export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const validatePhone = (phone: string) =>
  phone && phone?.match(/\d/g)?.length === 10;

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const isValidUrl = (url: string) => {
  let isValid = false;

  try {
    new URL(url); // eslint-disable-line
    isValid = true;
  } catch (_) {
    return false;
  }

  return isValid;
};

export const wrapFullName = (firstName: string, lastName?: string) =>
  `${firstName}${lastName ? ` ${lastName}` : ""}`;

export const wrapNameTwoLetters = (firstName: string, lastName?: string) =>
  firstName
    ? `${Array.from(firstName)[0]}${lastName ? Array.from(lastName)[0] : ""}`
    : "";

export const scrollToTop = () => {
  // eslint-disable-next-line
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const openInNewTab = (url: string) => window.open(url, "_blank");

export const calculateProjectCost = ({
  hourlyRate,
  hoursPerWeek,
  duration,
  durationType,
  retainerAmount,
  budgetType,
  promo = null,
}: {
  hourlyRate: number;
  hoursPerWeek: number;
  duration: number;
  durationType: string;
  retainerAmount: number;
  budgetType: string;
  promo: any;
}) => {
  let totalCost: number = 0;

  if (budgetType === BudgetType.HOURLY_RATE) {
    totalCost = hourlyRate * hoursPerWeek;

    if (durationType === ProjectDurationType.PERIOD) {
      totalCost *= duration;
    }
  }

  if (budgetType === BudgetType.RETAINER) {
    totalCost = retainerAmount;
  }

  const studentCompensation: number = totalCost * 0.8;

  const platformFee: number = totalCost * 0.2;

  let discountAmount = 0;

  if (promo?._id) {
    discountAmount = promo.discountAmount;

    if (promo.discountType === DiscountType.PERCENTAGE) {
      discountAmount = Number(totalCost * (Number(promo.discountAmount) / 100));
    }

    totalCost = Number(totalCost - discountAmount);
  }

  return { studentCompensation, platformFee, totalCost, discountAmount };
};

export const timesAgo = (time: Date) =>
  moment.utc(time).local().startOf("seconds").fromNow();

export const titleCaseText = (text: string) =>
  `${text.charAt(0).toUpperCase() + text.substring(1)}`;

export const projectHasStudent = ({
  project,
  studentId,
}: {
  project: any;
  studentId: string;
}) => {
  let hasStudent = false;

  const studentProject = project?.studentProjects?.find(
    (elem: any) => elem?.student?._id === studentId && elem.status
  );

  if (studentProject) {
    hasStudent = true;
  }

  return { hasStudent, studentProject };
};

const getWeekDays = (week: any) => {
  const weekStart = week.startOf("isoWeek");

  const days = [];

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
  }

  return days;
};

export const getProjectTimesheets = ({
  project,
  studentId,
  weekStart,
}: {
  project: any;
  studentId: string;
  weekStart?: any;
}) => {
  const studentProject = project?.studentProjects?.find(
    (elem: any) => elem?.student?._id === studentId
  );

  if (!weekStart) {
    weekStart = moment().startOf("isoWeek");
  }

  if (!studentProject || !studentProject.timesheets.length) {
    const newTimesheet = {
      weekStart,
      hasTimesheet: false,
      timesheet: getWeekDays(weekStart).map((day: any) => ({
        day,
        hours: "",
      })),
      totalHours: 0,
      studentTotalAmount: 0,
      totalAmount: 0,
    };

    return {
      timesheets: [newTimesheet],
      currentTimesheet: newTimesheet,
    };
  }

  let currentTimesheet = studentProject.timesheets.find(
    (elem: any) =>
      formatDate(elem.weekStart, "YYYY-MM-DD") ===
      formatDate(weekStart, "YYYY-MM-DD")
  );

  if (!currentTimesheet) {
    currentTimesheet = {
      weekStart,
      hasTimesheet: false,
      timesheet: getWeekDays(weekStart).map((day: any) => ({
        day,
        hours: "",
      })),
      totalHours: 0,
      studentTotalAmount: 0,
      totalAmount: 0,
    };
  } else {
    currentTimesheet = {
      _id: currentTimesheet?._id,
      weekStart,
      hasTimesheet: true,
      timesheet: currentTimesheet.timesheet,
      totalHours: currentTimesheet.totalHours,
      studentTotalAmount: currentTimesheet.studentTotalAmount,
      totalAmount: currentTimesheet.totalAmount,
    };
  }

  return {
    timesheets: studentProject.timesheets.map((timesheet: any) => ({
      ...timesheet,
      hasTimesheet: true,
    })),
    currentTimesheet,
  };
};

export const getInvoices = ({
  timesheets,
  weekStart,
}: {
  timesheets: Array<any>;
  weekStart?: any;
}) => {
  let invoices: any = {};

  const weekStarts: Array<string> = [];

  timesheets.forEach((timesheet: any) => {
    const formattedWeekStart = formatDate(timesheet.weekStart, "YYYY-MM-DD");

    if (!weekStarts.includes(formattedWeekStart)) {
      weekStarts.push(formattedWeekStart);
    }
  });

  weekStarts.forEach((weekStart: string) => {
    const weeklyTimesheets = timesheets.filter(
      (elem: any) => formatDate(elem.weekStart, "YYYY-MM-DD") === weekStart
    );

    let studentTotalAmount = 0;
    let totalAmount = 0;
    let totalHours = 0;

    weeklyTimesheets.forEach((timesheet: any) => {
      studentTotalAmount += timesheet.studentTotalAmount;
      totalAmount += timesheet.totalAmount;
      totalHours += timesheet.totalHours;
    });

    invoices[weekStart] = {
      weekStart,
      studentTotalAmount,
      totalAmount,
      totalHours,
      timesheets: weeklyTimesheets,
    };
  });

  invoices = Object.keys(invoices)
    .sort((a: string, b: string) => {
      const currentDate1: any = new Date(a);
      const currentDate2: any = new Date(b);
      return currentDate2 - currentDate1;
    })
    .reduce((netObj: any, key: any) => {
      return { ...netObj, [key]: invoices[key] };
    }, {});

  if (!weekStart) {
    weekStart = moment(Object.keys(invoices)[0]).startOf("isoWeek");
  }

  const currentTimesheets = timesheets.filter(
    (elem: any) =>
      formatDate(elem.weekStart, "YYYY-MM-DD") ===
      formatDate(weekStart, "YYYY-MM-DD")
  );

  let currentInvoice = {};

  if (currentTimesheets.length) {
    let studentTotalAmount = 0;
    let totalAmount = 0;
    let totalHours = 0;

    currentTimesheets.forEach((timesheet: any) => {
      studentTotalAmount += timesheet.studentTotalAmount;
      totalAmount += timesheet.totalAmount;
      totalHours += timesheet.totalHours;
    });

    currentInvoice = {
      weekStart,
      studentTotalAmount,
      totalAmount,
      totalHours,
      timesheets: currentTimesheets,
    };
  }

  return {
    invoices,
    currentInvoice,
    weekStart,
  };
};

export const formatTime = (minutes: any) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${mins.toString().padStart(2, "0")} ${ampm}`;
};

export const getStudentHourlyRate = (
  hourlyRate: number,
  shouldShowPremiumFeatures: boolean,
  promo?: any
) => {
  if (!shouldShowPremiumFeatures) {
    return Number(hourlyRate);
  }
  if (promo?.discountAmount) {
    let discountAmount = promo?.discountAmount;
    const discountType = promo?.discountType;
    if (discountType === "PERCENTAGE") {
      discountAmount /= 100;
    } else if (discountType === "AMOUNT") {
      // implement later
    }
    return Number(Number(hourlyRate) * (1 - discountAmount)).toFixed(2);
  }
  return Number(Number(hourlyRate) * 0.8).toFixed(2);
};

export const getStudentRetainerAmount = (
  retainerAmount: number,
  shouldShowPremiumFeatures: boolean,
  promo?: any
): number => {
  if (!shouldShowPremiumFeatures) {
    return Number(retainerAmount);
  }
  if (promo?.discountAmount) {
    let discountAmount = promo?.discountAmount;
    const discountType = promo?.discountType;
    if (discountType === "PERCENTAGE") {
      discountAmount /= 100;
    } else if (discountType === "AMOUNT") {
      // implement later
    }
    return Number(
      Number(Number(retainerAmount) * (1 - discountAmount)).toFixed(2)
    );
  }
  return Number(Number(Number(retainerAmount) * 0.8).toFixed(2));
};

export const getPersonalityRanking = (
  studentPersonalityType: string | undefined
) => {
  if (!studentPersonalityType) {
    return [];
  }

  const personalityTests =
    PersonalityTests[studentPersonalityType.toLowerCase()];

  const personalityRanking: Array<{
    title: string;
    description: string;
    icon: string;
  }> = [];

  Object.keys(personalityTests.results).forEach((elem: any) => {
    personalityRanking.push({
      title: elem,
      description: personalityTests.results[elem].text,
      icon: personalityTests.results[elem].icon,
    });
  });

  return personalityRanking;
};

export const offsetDates = (date: string, offset: number): Date => {
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() + offset);
  return startDate;
};

export const generateRattingArray = (ratting = 2.2) => {
  let arr = Array(5).fill(0);
  const ceiling = Math.ceil(ratting);
  if (ceiling === ratting) {
    arr = arr.fill(1, 0, ratting);
  } else {
    arr = arr.fill(1, 0, ratting);
    arr = arr.fill(2, ratting, ratting + 1);
  }
  return arr;
};
