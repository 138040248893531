import React, { useEffect } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import ProjectStatusComp from "../ProjectStatus";
import Overview from "./Overview";
import SubmitHours from "./SubmitHours";
import { useMergeState } from "utils/custom-hooks";
import {
  createTimesheets,
  getTimesheets,
  getProjectDetails,
  listInvoices,
  payInvoice,
} from "api";
import PayoutTable from "./PayoutTable";
// import ProjectStartedTips from "./ProjectStartedTips";
// import { HourlyRate, ProjectStatus } from "../../../utils/constants";
import TimeSheetDetails from "./TimeSheetDetails";
import {
  calculateProjectCost,
  getInvoices,
  getProjectTimesheets,
} from "utils/common";
import { formatDate } from "utils/date";
import { InvoiceStatus } from "utils/constants";

const tabs = ["Overview", "Submit Hours", "Payouts"];

type Props = {
  user: any;
  firstName: string;
  lastName: string;
  projectId: string;
  hourlyRate: number;
  hoursPerWeek: number;
  title: string;
  type: string;
  duration: number;
  jobDocumentUrl: string;
  project?: any;
  handleUpdateProject: (project: any) => any;
};

const OngoingProject = ({
  firstName,
  lastName,
  user,
  projectId,
  hourlyRate,
  hoursPerWeek,
  title,
  type,
  duration = 0,
  jobDocumentUrl = "",
  project = {},
  handleUpdateProject,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useMergeState({
    selectedTab: 0,
    timesheets: [],
    invoices: {},
    currentInvoice: {},
    invoice: [],
    invoicesList: [],
    currentTimesheet: {},
    isStudent: user.accountType === "STUDENT" ? true : false,
    totalAmount: 0,
    totalHours: 0,
    inProgressStudents: [],
  });

  const handleStudentTimesheets = async (projectInfo: any) => {
    const { timesheets, currentTimesheet } = getProjectTimesheets({
      project: projectInfo,
      studentId: user?._id,
    });
    const timesheetIds = timesheets.map((item: any) => item._id);

    let invoicesResponse = await listInvoices({ id: projectId });
    if (invoicesResponse?.success) {
      invoicesResponse = invoicesResponse.data.filter((invoice: any) =>
        invoice.timesheets.some((timesheet: any) =>
          timesheetIds.includes(timesheet)
        )
      );
    } else {
      invoicesResponse = [];
    }

    const { totalAmount, totalHours } = timesheets.reduce(
      (total: any, item: any) => ({
        totalAmount: item.studentTotalAmount + (total?.totalAmount || 0),
        totalHours: item.totalHours + (total?.totalHours || 0),
      }),
      {}
    );
    const inProgressStudents = projectInfo.studentProjects.filter(
      (item: any) =>
        !item.suggested &&
        item.status === "IN_PROGRESS" &&
        item?.student?._id === user?._id
    );
    setState({
      timesheets,
      currentTimesheet,
      totalAmount,
      totalHours,
      inProgressStudents,
      invoicesList: invoicesResponse,
    });
  };

  const init = async () => {
    if (state.isStudent) {
      handleStudentTimesheets(project);
      // const { timesheets, currentTimesheet } = getProjectTimesheets({
      //   project,
      //   studentId: user?._id,
      // });
      // const timesheetIds = timesheets.map((item: any) => item._id);

      // let invoicesResponse = await listInvoices({ id: projectId });
      // if (invoicesResponse?.success) {
      //   invoicesResponse = invoicesResponse.data.filter((invoice: any) =>
      //     invoice.timesheets.some((timesheet: any) =>
      //       timesheetIds.includes(timesheet)
      //     )
      //   );
      // } else {
      //   invoicesResponse = [];
      // }

      // const { totalAmount, totalHours } = timesheets.reduce(
      //   (total: any, item: any) => ({
      //     totalAmount: item.studentTotalAmount + (total?.totalAmount || 0),
      //     totalHours: item.totalHours + (total?.totalHours || 0),
      //   }),
      //   {}
      // );
      // const inProgressStudents = project.studentProjects.filter(
      //   (item: any) =>
      //     !item.suggested &&
      //     item.status === "IN_PROGRESS" &&
      //     item?.student?._id === user?._id
      // );
      // setState({
      //   timesheets,
      //   currentTimesheet,
      //   totalAmount,
      //   totalHours,
      //   inProgressStudents,
      //   invoicesList: invoicesResponse,
      // });
    } else {
      const inProgressStudents = project.studentProjects.filter(
        (item: any) => !item.suggested && item.status === "IN_PROGRESS"
      );
      const timesheetsResponse = await getTimesheets({
        id: projectId,
      });
      let timesheets = [];
      if (timesheetsResponse.success) {
        timesheets = timesheetsResponse.data;
      }

      const { totalAmount, totalHours } = timesheets.reduce(
        (total: any, item: any) => ({
          totalAmount: item.totalAmount + (total?.totalAmount || 0),
          totalHours: item.totalHours + (total?.totalHours || 0),
        }),
        { totalAmount: 0, totalHours: 0 }
      );

      const { invoices, currentInvoice, weekStart } = getInvoices({
        timesheets,
      });
      const invoicesResponse = await listInvoices({ id: projectId });
      setState({
        invoice: invoicesResponse.data,
        timesheets,
        invoices,
        currentInvoice,
        invoicesList: invoicesResponse.data,
        inProgressStudents,
        totalAmount,
        totalHours,
      });
    }
  };

  useEffect(() => {
    init();

    if (state?.isStudent === false) {
      tabs[1] = "View Timesheets";
    }
  }, []);

  const processingFees = +Number(
    state.currentInvoice.totalAmount * 0.03
  ).toFixed(2);

  // const {
  //   studentCompensation,
  //   platformFee,
  //   totalCost,
  //   discountAmount = 0,
  // } = calculateProjectCost({
  //   hourlyRate: project?.hourlyRate,
  //   hoursPerWeek: project?.hoursPerWeek,
  //   duration: project?.duration,
  //   durationType: project?.durationType,
  //   retainerAmount: project?.retainerAmount,
  //   budgetType: project?.budgetType,
  //   promo: project?.promo,
  // });

  const hasPrev = Object.keys(state?.invoices)?.length
    ? state?.invoices[
        formatDate(
          moment(state?.currentInvoice?.weekStart)
            .subtract(1, "week")
            .startOf("isoWeek"),
          "YYYY-MM-DD"
        )
      ]
      ? true
      : false
    : false;

  const hasNext = Object.keys(state?.invoices)?.length
    ? state?.invoices[
        formatDate(
          moment(state?.currentInvoice?.weekStart)
            .add(1, "week")
            .startOf("isoWeek"),
          "YYYY-MM-DD"
        )
      ]
      ? true
      : false
    : false;

  const handlePreviousWeek = () => {
    // if (!hasPrev) {
    //   console.log("NO PREV");
    //   return;
    // }

    if (state.isStudent) {
      const weekStart = moment(state?.currentTimesheet?.weekStart)
        .subtract(1, "week")
        .startOf("isoWeek");

      const { timesheets, currentTimesheet } = getProjectTimesheets({
        project,
        studentId: user?._id,
        weekStart,
      });

      setState({
        timesheets,
        currentTimesheet,
      });
    } else {
      const weekStart = moment(state?.currentInvoice?.weekStart)
        .subtract(1, "week")
        .startOf("isoWeek");

      const { invoices, currentInvoice } = getInvoices({
        timesheets: state?.timesheets,
        weekStart,
      });

      setState({
        invoices,
        currentInvoice,
      });
    }
  };

  const handleNextWeek = () => {
    // if (!hasNext) {
    //   console.log("NO NEXT");
    //   return;
    // }
    if (state.isStudent) {
      const weekStart = moment(state?.currentTimesheet?.weekStart)
        .add(1, "week")
        .startOf("isoWeek");

      const { timesheets, currentTimesheet } = getProjectTimesheets({
        project,
        studentId: user?._id,
        weekStart,
      });

      setState({
        timesheets,
        currentTimesheet,
      });
    } else {
      const weekStart = moment(state?.currentInvoice?.weekStart)
        .add(1, "week")
        .startOf("isoWeek");

      const { invoices, currentInvoice } = getInvoices({
        timesheets: state?.timesheets,
        weekStart,
      });

      setState({
        invoices,
        currentInvoice,
      });
    }
  };

  const getTimSheetOfInvoice = (id: any) => {
    const findedTimeSheet = state.timesheets.find(
      (timesheet: any) => timesheet._id === id
    );
    return findedTimeSheet;
  };

  const onCalculatePerHourRate = (timesheet: any) => {
    const totalAmount = Number(
      state.isStudent ? timesheet?.studentTotalAmount : timesheet?.totalAmount
    );
    const totalHours = Number(timesheet?.totalHours);
    return totalAmount ? totalAmount / totalHours : 0;
    // return totalAmount / totalHours;
  };

  const getHourlyRateOfTimeSheet = (id: any) => {
    const invoiceTimeSheet = getTimSheetOfInvoice(id);
    if (!invoiceTimeSheet) return 0;
    return onCalculatePerHourRate(invoiceTimeSheet);
  };

  const getTimeSheetHours = (id: any) => {
    const invoiceTimeSheet = getTimSheetOfInvoice(id);
    if (!invoiceTimeSheet) return 0;
    return Number(invoiceTimeSheet?.totalHours);
  };

  const onCalculateTotalAmountOfInvoice = (invoice: any) => {
    const amount = invoice.timesheets.reduce(
      (total: number, item: any) =>
        total + getTimeSheetHours(item) * getHourlyRateOfTimeSheet(item),
      0
    );
    return amount;
  };

  const invoiceStatus = () => {
    const invoice = state.invoicesList?.find(
      (elem: any) =>
        formatDate(elem.weekStart, "YYYY-MM-DD") ===
        formatDate(state.currentInvoice?.weekStart, "YYYY-MM-DD")
    );
    return invoice?.status;
  };

  const handleChangeHours = (index: number, hours: string) => {
    const updatedTimesheet = [...state.currentTimesheet.timesheet];

    updatedTimesheet[index].hours = hours;

    setState({
      currentTimesheet: {
        ...state?.currentTimesheet,
        timesheet: updatedTimesheet,
      },
    });
  };

  const handleClearTimesheet = () => {
    let updatedTimesheet = [...state.currentTimesheet.timesheet];

    updatedTimesheet = updatedTimesheet.map((timesheet: any) => ({
      day: timesheet.day,
      hours: "",
    }));

    setState({
      currentTimesheet: {
        ...state?.currentTimesheet,
        timesheet: updatedTimesheet,
      },
    });
  };

  const handleSubmitTimesheet = async (weeklySummary: string) => {
    try {
      setState({ isLoading: true });

      const payload = {
        id: projectId,
        weekStart: state?.currentTimesheet?.weekStart,
        weeklySummary,
        timesheet: state?.currentTimesheet?.timesheet?.map((elem: any) => ({
          day: elem.day,
          hours: elem.hours ? Number(elem.hours) : 0,
        })),
      };

      const response = await createTimesheets(payload);

      if (response?.success) {
        // const projectInfo = response.data?.project;

        const projectResponse = await getProjectDetails(projectId);
        if (projectResponse?.success) {
          const projectInfo = projectResponse.data;
          await handleStudentTimesheets(projectInfo);
          handleUpdateProject(projectInfo);
        }

        // const studentProjectIndex = projectInfo?.studentProjects?.findIndex(
        //   (elem: any) => elem?.student?._id === user?._id
        // );
        // const currentTimeSheetData = {
        //   ...state?.currentTimesheet,
        //   hasTimesheet: true,
        //   totalHours: response?.data?.totalHours,
        //   studentTotalAmount: response?.data?.studentTotalAmount,
        //   totalAmount: response?.data?.totalAmount,
        //   _id: Date.now(),
        // };
        // const studentInfo = projectInfo.studentProjects[studentProjectIndex];
        // projectInfo.studentProjects[studentProjectIndex] = {
        //   ...studentInfo,
        //   timesheets: [...studentInfo.timesheets, currentTimeSheetData],
        // };

        // const updatedTimesheets = [...state.timesheets, currentTimeSheetData];
        // console.log("updatedTimesheets ----", updatedTimesheets);
        // const timesheetIds = updatedTimesheets.map((item: any) => item._id);
        // console.log("timesheetIds ----", timesheetIds);
        // let invoicesResponse = await listInvoices({ id: projectId });
        // let currentInvoice = {};
        // if (invoicesResponse?.success) {
        //   currentInvoice =
        //     invoicesResponse.data[invoicesResponse.data.length - 1];
        //   console.log("invoicesResponse ----", invoicesResponse);
        //   invoicesResponse = invoicesResponse.data.filter((invoice: any) =>
        //     invoice.timesheets.some((timesheet: any) =>
        //       timesheetIds.includes(timesheet)
        //     )
        //   );
        // } else {
        //   invoicesResponse = [];
        // }

        // const { totalAmount, totalHours } = updatedTimesheets.reduce(
        //   (total: any, item: any) => ({
        //     totalAmount: item.studentTotalAmount + (total?.totalAmount || 0),
        //     totalHours: item.totalHours + (total?.totalHours || 0),
        //   }),
        //   {}
        // );
        // const inProgressStudents = project.studentProjects.filter(
        //   (item: any) => !item.suggested && item.status === "IN_PROGRESS"
        // );
        // setState({
        //   timesheets: updatedTimesheets,
        //   currentTimesheet: currentTimeSheetData,
        //   totalAmount,
        //   totalHours,
        //   inProgressStudents,
        //   invoicesList: [...invoicesResponse, currentInvoice],
        // });
        // handleUpdateProject(projectInfo);
        enqueueSnackbar(response?.message, { variant: "success" });
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handlePayInvoice = async () => {
    try {
      const invoice = state.invoicesList.find(
        (elem: any) =>
          formatDate(elem.weekStart, "YYYY-MM-DD") ===
          formatDate(state.currentInvoice.weekStart, "YYYY-MM-DD")
      );

      if (!invoice || invoice?.status !== InvoiceStatus.PENDING) {
        return;
      }

      const studentFees: number = +Number(
        state.currentInvoice.studentTotalAmount
      ).toFixed(2);

      const totalAmount: number = +Number(
        state.currentInvoice.totalAmount
      ).toFixed(2);

      const platformFees: number = +Number(totalAmount * 0.2).toFixed(2);

      const payload = {
        id: invoice._id,
        studentFees,
        platformFees,
        processingFees,
        totalAmount,
      };

      const response = await payInvoice(payload);

      if (response?.data?.nextAction) {
        window.location.href = response.data.nextAction.url;
        return;
      }

      enqueueSnackbar("Payout initiated successfully!", {
        variant: "success",
      });

      // window.location.href = `/projects/details?id=${projectId}&view=OVERVIEW`;
      setTimeout(() => {
        window.location.href = `/projects/details?id=${projectId}&view=OVERVIEW`;
      }, 2000);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleChangeTab = (index: number) => {
    setState({ selectedTab: index });
  };

  const handleViewInvoice = (weekStart: string) => {
    const { invoices, currentInvoice } = getInvoices({
      timesheets: state?.timesheets,
      weekStart,
    });

    setState({
      invoices,
      currentInvoice,
    });
  };

  const goToInvoiceView = (weekStart: string) => {
    if (state.isStudent)
      window.open("https://dashboard.stripe.com/login", "_blank");
    else {
      handleViewInvoice(weekStart);
      setState({ selectedTab: 1 });
    }
  };

  // const isOngoingStatus = project?.status === ProjectStatus.IN_PROGRESS;
  const isCapRate = project?.isCapRate ? true : false;

  return (
    <div>
      <div className="p-4 bg-white w-100 border border_gray border_r_16px">
        <ProjectStatusComp
          type={2}
          buttonTitle={
            !state.isStudent
              ? `${
                  state.inProgressStudents.length > 1 ? "Students" : "Student"
                }: ${state.inProgressStudents
                  .map(
                    (item: any) =>
                      `${item.student.firstName} ${item.student.lastName}`
                  )
                  .join(", ")}`
              : `Manager: ${firstName} ${lastName}`
          }
          hourlyRate={hourlyRate}
          title={title}
          titleType={type}
          jobDocumentUrl={jobDocumentUrl}
          duration={duration}
          // buttonOnClick={() => console.log("click")}
        />
      </div>
      {/* main div */}

      <div className="bg-white w-100 border border_gray border_r_16px mt-4 mb-4 mb-md-0">
        <ul
          className="nav nav-tabs nav_project_tabs px-2 pt-2"
          id="myTab"
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <li
              key={index}
              className="nav-item"
              role="presentation"
              onClick={() => handleChangeTab(index)}
            >
              <button
                className={`nav-link ${
                  index === state?.selectedTab ? "active" : ""
                }`}
                id="overview-tab"
                type="button"
                role="tab"
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active w-100 p-4">
            {state?.selectedTab === 0 ? (
              <Overview
                firstName={firstName}
                lastName={lastName}
                user={user}
                studentProjects={state.inProgressStudents}
                totalHours={state.totalHours}
                totalEarning={state.totalAmount}
                // totalHours={
                //   state.isStudent
                //     ? state.totalHours
                //     : hoursPerWeek * state.invoice.length
                // }
                // totalEarning={
                //   state.isStudent
                //     ? state.totalAmount
                //     : hourlyRate * hoursPerWeek * state.invoice.length
                // }
                handleChangeTab={handleChangeTab}
                goToInvoiceView={goToInvoiceView}
              />
            ) : state?.selectedTab === 1 && state?.isStudent ? (
              <SubmitHours
                // projectId={projectId}
                // hourlyRate={hourlyRate}
                // platformFee={platformFee}
                isCapRate={isCapRate}
                hoursPerWeek={project.hoursPerWeek}
                currentTimesheet={state.currentTimesheet}
                handlePreviousWeek={handlePreviousWeek}
                handleNextWeek={handleNextWeek}
                handleChangeHours={handleChangeHours}
                handleClearTimesheet={handleClearTimesheet}
                handleSubmitTimesheet={handleSubmitTimesheet}
              />
            ) : state?.selectedTab === 1 && !state?.isStudent ? (
              <TimeSheetDetails
                invoice={state?.invoice}
                hasPrev={hasPrev}
                hasNext={hasNext}
                projectId={projectId}
                currentInvoice={state.currentInvoice}
                hourlyRate={hourlyRate}
                handlePreviousWeek={handlePreviousWeek}
                handleNextWeek={handleNextWeek}
                onCalculatePerHourRate={onCalculatePerHourRate}
                invoiceStatus={invoiceStatus}
                handlePayInvoice={handlePayInvoice}
              />
            ) : state.selectedTab === 2 ? (
              <>
                <PayoutTable
                  invoice={state?.invoice}
                  totalEarning={state.totalAmount}
                  hourlyRate={hourlyRate}
                  hoursPerWeek={hoursPerWeek}
                  isStudent={state?.isStudent}
                  invoicesList={state.invoicesList}
                  goToInvoiceView={goToInvoiceView}
                  getTimeSheetHours={getTimeSheetHours}
                  getHourlyRateOfTimeSheet={getHourlyRateOfTimeSheet}
                  onCalculateTotalAmountOfInvoice={
                    onCalculateTotalAmountOfInvoice
                  }
                />
                <div className="text-center mt-4 d-block d-md-none">
                  <button
                    type="button"
                    className="btn bg_secondary mob_100 mx-auto"
                  >
                    View Stripe
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* Tips */}
      {/* <ProjectStartedTips /> */}
    </div>
  );
};

export default OngoingProject;
