import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { ThemeProvider } from "@mui/material/styles";
import { IntlProvider } from "react-intl";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";

// MUI theme
import theme from "mui";

// containers
import Routes from "routes";

export default function App() {
  React.useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" defaultLocale="en">
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          hideIconVariant
          preventDuplicate
        >
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SnackbarProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}
