import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { googleLogout } from "@react-oauth/google";
import * as amplitude from "@amplitude/analytics-browser";
import { useMergeState } from "utils/custom-hooks";
import ProfileBar from "./ProfileBar";
import ProfileDetails from "./ProfileDetails";
import ProfileSettings from "./ProfileSettings";
import { validateEmail, wrapHTMLContent } from "utils/string";
import {
  PersonalityTests,
  StudentTypes,
  PersonalityTestColors,
  RUNWAY_TOKEN,
} from "utils/constants";
import {
  isValidUrl,
  openInNewTab,
  shouldShowPremiumFeatures,
  titleCaseText,
  toBase64,
  wrapFullName,
  wrapNameTwoLetters,
} from "utils/common";
import {
  getStudentDetails,
  updateProfile,
  getStudentProfileStats,
  createConnectedAccount,
  getConnectedAccount,
  getSignedUrlOfResume,
  getSignedUrlOfS3,
} from "api";

type Props = {
  user: any;
  manuBarExpend: boolean;
  setUser: any;
  projectId?: string;
  studentId?: string;
  isBusinessView: boolean;
  showRTI: string | null;
  manuBarToogle: () => void;
  onRequestToInterview?: () => void;
  profileImageUrl: string;
};
const Profile = ({
  user,
  manuBarExpend,
  setUser,
  projectId,
  studentId,
  isBusinessView,
  showRTI,
  manuBarToogle,
  onRequestToInterview,
  profileImageUrl,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    avatar: "",
    avatarFile: null,
    avatarBase64: null,

    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    university: "",
    resume: {},
    resumeFile: null,
    resumeBase64: null,
    website: "",
    bio: "",
    keySkills: "",
    skills: [],
    profileTypes: [],
    showProfile: true,

    notificationSettings: {
      email: {
        newProjectPosted: false,
        requestedToInterview: false,
        newProjectStarted: false,
        timesheetReminders: false,
      },
      sms: {
        newProjectPosted: false,
        requestedToInterview: false,
        newProjectStarted: false,
        timesheetReminders: false,
      },
    },
    personalityTests: {},

    // profile stats
    reviews: [],
    stats: {
      projects: {
        finished: 0,
      },
      reviews: {
        total: 0,
        overallRating: 0,
      },
    },
    payment: {
      payoutsEnabled: false,
      chargesEnabled: false,
      detailsSubmitted: false,
    },

    isDisableBtn: false,
    isEditable: false,

    errors: {},
  });
  const [personalityTestState, setPersonalityTestState] = useState<any>({
    showPersonalityInfographicPopup: false,
    personalityInfographicType: "",
    personalityResultDescriptions: {
      business: PersonalityTests.business.results,
      engineer: PersonalityTests.engineer.results,
      developer: PersonalityTests.developer.results,
      designer: PersonalityTests.designer.results,
    },
  });

  const avatarRef = useRef<any>();

  const [showPremiumFeatures, setShowPremiumFeatures] =
    useState<boolean>(false);

  const togglePremiumFeatures = () => {
    setShowPremiumFeatures((prevState) => !prevState);
  };

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleChangeBio = (bio: string) => {
    setState({
      bio,
      errors: {
        ...state?.errors,
        bio: "",
      },
    });
  };

  const resumeRef = useRef<any>();

  const handleResumeRef = () => {
    resumeRef?.current?.click();
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file) {
      return;
    }

    event.target.value = "";

    if (
      file?.name.includes("pdf") ||
      file?.name.includes("doc") ||
      file?.name.includes("docx")
    ) {
      const base64 = await toBase64(file);

      setState({
        resumeFile: file,
        resumeBase64: base64,
        errors: {
          ...state?.errors,
          resume: "",
        },
      });
    } else {
      setState({
        resumeFile: null,
        resumeBase64: null,
        errors: {
          ...state?.errors,
          resume: "File type should be .pdf or .docx",
        },
      });
    }

    // const base64 = await toBase64(file);

    // setState({
    //   resumeFile: file,
    //   resumeBase64: base64,
    // });
  };

  const handleRemoveResume = () => {
    setState({
      resume: null,
      resumeBase64: null,
    });
  };

  const handleNotificationSettingsChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    try {
      const notificationSettings = {
        ...state?.notificationSettings,
        [type]: {
          ...state?.notificationSettings[type],
          [event?.target?.name]: event?.target?.checked,
        },
      };

      setState({
        notificationSettings,
      });

      const response = await updateProfile({
        notificationSettings,
      });

      if (response?.success) {
        enqueueSnackbar("Notification settings updated successfully.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleToggleEdit = () => {
    setState({ isEditable: !state?.isEditable });
  };

  const handleChangeProfileType = (profileType: string) => {
    const updatedProfileTypes = [...state.profileTypes];

    const index = state.profileTypes.findIndex(
      (elem: string) => elem === profileType
    );

    if (index === -1) {
      updatedProfileTypes.push(profileType);
    } else {
      updatedProfileTypes.splice(index, 1);
    }

    setState({
      profileTypes: updatedProfileTypes,
      errors: {
        ...state?.errors,
        profileTypes: "",
      },
    });
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event?.target?.files && event?.target?.files[0];

      if (!file) {
        return;
      }

      event.target.value = "";

      const base64 = await toBase64(file);

      // setState({
      //   avatarFile: file,
      //   avatarBase64: base64,
      // });

      const response = await updateProfile({
        avatar: state?.avatar,
        avatarBase64: base64,
      });

      if (response?.success) {
        const userInfo = response?.data?.user;
        userInfo.avatar = { ...userInfo.avatar, url: base64 };
        setState({
          avatarFile: file,
          avatarBase64: base64,
        });
        setUser(userInfo);
        enqueueSnackbar("Profile avatar updated successfully.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleAvatarRef = () => {
    avatarRef?.current?.click();
  };

  const handleChangeKeySkills = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keySkills = event?.target?.value;

    const keySkillsArray = keySkills?.split(",");

    const skills = keySkillsArray.map((elem: string) =>
      String(elem)?.trim()?.toUpperCase()
    );

    setState({
      keySkills,
      skills,
      errors: {
        keySkills: "",
      },
    });
  };

  const handleBack = () => {
    if (isBusinessView) {
      navigate(-1);
      return;
    }

    navigate("/projects");
  };

  const handleRedirectWebsite = () => {
    openInNewTab(state?.website);
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (!state?.profileTypes?.length) {
      isValid = false;
      errors = { ...errors, profileTypes: "Profile Type is required." };
    }

    if (!state?.firstName) {
      isValid = false;
      errors = { ...errors, firstName: "First Name is required." };
    }

    if (!state?.lastName) {
      isValid = false;
      errors = { ...errors, lastName: "Last Name is required." };
    }

    if (!validateEmail(state?.email)) {
      isValid = false;
      errors = { ...errors, email: "Email is invalid." };
    }

    if (!state?.phone) {
      isValid = false;
      errors = { ...errors, phone: "Phone is required." };
    }

    if (!state?.keySkills) {
      isValid = false;
      errors = { ...errors, phone: "Key Skillsets is required." };
    }

    if (!state?.bio) {
      isValid = false;
      errors = { ...errors, phone: "Bio is required." };
    }

    if (!state?.resume && !state?.resumeBase64) {
      isValid = false;
      errors = { ...errors, resume: "Resume is required." };
    }

    if (state?.website && !isValidUrl(state?.website)) {
      isValid = false;
      errors = { ...errors, website: "Website is invalid." };
    }

    setState({ errors });

    return isValid;
  };

  const handleStart = (type: any) => {
    navigate(`/personality-tests/persona?type=${type}`);
  };

  const handleRemoveSkills = (skill: any) => {
    let skillsCopy = state.skills.slice();
    skillsCopy = skillsCopy.filter((elem: string) => elem !== skill);

    setState({
      keySkills: skillsCopy.map((elem: string) => elem.toLowerCase()).join(","),
      skills: skillsCopy,
      errors: {
        keySkills: "",
      },
    });
  };

  const handleUpdateProfile = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isDisableBtn: true });
      const response = await updateProfile({
        profileTypes: state?.profileTypes,
        firstName: state?.firstName,
        lastName: state?.lastName,
        email: state?.email,
        phone: state?.phone,
        website: state?.website,
        bio: state?.bio,
        skills: state?.skills,
        resume: state?.resume?._id,
        resumeBase64: state?.resumeBase64,
      });

      if (response?.success) {
        setState({ isEditable: false, isDisableBtn: false });
        setUser(response?.data?.user);
        enqueueSnackbar(response?.message, { variant: "success" });
      } else {
        setState({ isDisableBtn: false });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleRequestToInterview = () => {
    if (onRequestToInterview) {
      onRequestToInterview();
    }
  };

  const handleCreateConnectAccount = async () => {
    try {
      const response = await createConnectedAccount();

      if (response?.success) {
        window.location.href = response?.data?.url;
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async () => {
    try {
      let initialState: any = {};

      const id = isBusinessView ? studentId : user?._id;

      const profileStatsResponse = await getStudentProfileStats({
        id,
      });

      initialState = {
        reviews: profileStatsResponse?.data?.reviews,
        stats: profileStatsResponse?.data?.stats,
      };

      if (isBusinessView && studentId) {
        const response = await getStudentDetails({ id: studentId });
        const data = response?.data;
        if (data && data?.resume?.key) {
          const signedUrlRes = await getSignedUrlOfResume(data?.resume?.key);
          if (signedUrlRes?.success) {
            data.resume = {
              ...data.resume,
              url: signedUrlRes.data.url,
            };
          }
        }
        initialState = {
          ...initialState,
          avatar: data?.avatar,
          profileTypes: data?.profileTypes,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phone: data?.phone,
          university: data?.university,
          resume: data?.resume,
          website: data?.website,
          bio: data?.bio,
          keySkills: Array(data?.skills)?.join(),
          skills: data?.skills,
          personalityTests: data?.personality,
          notificationSettings: data?.notificationSettings,
          javaCertified: data?.javaCertified,
        };
      } else {
        const connectAccountDetailsResponse = await getConnectedAccount();
        if (user && user?.resume?.key) {
          const signedUrlRes = await getSignedUrlOfResume(user?.resume?.key);
          if (signedUrlRes?.success) {
            user.resume = {
              ...user.resume,
              url: signedUrlRes.data.url,
            };
          }
        }
        initialState = {
          ...initialState,
          avatar: user?.avatar,
          profileTypes: user?.profileTypes,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          university: user?.university,
          resume: user?.resume,
          website: user?.website,
          bio: user?.bio,
          keySkills: Array(user?.skills)?.join(),
          skills: user?.skills,
          personalityTests: user?.personality,
          notificationSettings: user?.notificationSettings,
          javaCertified: user?.javaCertified,
        };

        if (connectAccountDetailsResponse?.data) {
          initialState = {
            ...initialState,
            payment: connectAccountDetailsResponse.data,
          };
        }
      }

      setState(initialState);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleLogout = async () => {
    const is_social_login = await localStorage.getItem("is_social_login");
    if (is_social_login && is_social_login === "true") {
      googleLogout();
      localStorage.removeItem("is_social_login");
    }
    localStorage.removeItem(RUNWAY_TOKEN);
    amplitude.reset();
    window.location.href = "/";
  };

  const togglePersonalityInfographicPopup = (selectedProfileType: string) => {
    setPersonalityTestState({
      ...personalityTestState,
      showPersonalityInfographicPopup: !state.showPersonalityInfographicPopup,
      personalityInfographicType: selectedProfileType,
    });
  };

  const closePersonalityInfographicPopup = () => {
    setPersonalityTestState({
      ...personalityTestState,
      showPersonalityInfographicPopup: false,
      personalityInfographicType: "",
    });
  };

  useEffect(() => {
    init();
  }, []);

  const profileLatters = (
    <div className="profile_image" style={{ width: 75, height: 75 }}>
      <p className="profileText" style={{ fontSize: 30 }}>
        {wrapNameTwoLetters(user?.firstName, user?.lastName)}
      </p>
    </div>
  );

  const isProfileImage = state?.avatar?.url || state?.avatarBase64;

  return (
    <div>
      <header
        className={`header ${manuBarExpend ? "body_pd" : ""}`}
        id="header"
      >
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        {/* <p className="fw-bold fs-5 ms-4 mb-0 lh-sm">Hi, {user.firstName}</p> */}
        <p className="fw-bold fs-5 ms-4 mb-0 lh-sm">My Profile</p>
      </header>
      <div className="px-0 px-md-3 w-100 mt-0 mt-md-3 mb-3">
        <div className="p-3 p-md-4 bg_white justify-content-center border border_gray border_r_16px border_sm_r_0">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              {profileImageUrl?.length > 0 ? (
                <img
                  src={profileImageUrl}
                  style={{ width: 75, height: 75, borderRadius: 100 }}
                />
              ) : (
                profileLatters
              )}
              <div className="ms-3">
                <p className="fs-4 fw-bold mb-0">{`${user.firstName} ${user.lastName}`}</p>
                <p className="fs-6 text_primary mb-0">{user.university}</p>
              </div>
            </div>
            {!state.isEditable ? (
              <div className="mt-2 mt-md-0 w_sm_100">
                <button
                  type="button"
                  className="btn btn_small bg_secondary w_sm_100 my-2 mr-1"
                  onClick={handleToggleEdit}
                >
                  Edit Profile
                </button>
                <button
                  type="button"
                  className="btn btn_small bg_light_redish text_redish w_sm_100 my-2"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="mt-2 mt-md-0 w_sm_100">
                <button
                  type="button"
                  className="btn btn_small bg-white border border_secondary text_secondary w_sm_100 my-2 equal_btn mr-1"
                  onClick={handleToggleEdit}
                >
                  Cancel
                </button>
                <button
                  disabled={state.isDisableBtn}
                  type="button"
                  className={`btn btn_small bg_secondary text_white w_sm_100 my-2 equal_btn ${
                    state.isDisableBtn ? "bg-rw-disable" : ""
                  }`}
                  onClick={handleUpdateProfile}
                >
                  Save Profile
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* tabs */}
      <div className="px-3 px-md-4 pb-2 w-100 mb-2 mb-md-0 pb-2">
        <ul className="nav nav-tabs custom_nav_tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link border-0 ${
                state.showProfile ? "active" : ""
              } border-0`}
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
              onClick={() => setState({ showProfile: true })}
            >
              Profile{" "}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link border-0 ${
                state.showProfile ? "" : "active"
              } border-0`}
              id="setting-tab"
              data-bs-toggle="tab"
              data-bs-target="#setting"
              type="button"
              role="tab"
              aria-controls="setting"
              aria-selected="false"
              onClick={() => setState({ showProfile: false })}
            >
              Settings
            </button>
          </li>
        </ul>
      </div>
      {state?.showProfile ? (
        <ProfileDetails
          user={user}
          isBusinessView={isBusinessView}
          isEditable={state.isEditable}
          state={state}
          personalityTestState={personalityTestState}
          showPremiumFeatures={showPremiumFeatures}
          resumeRef={resumeRef}
          togglePremiumFeatures={togglePremiumFeatures}
          handleStart={handleStart}
          handleRedirectWebsite={handleRedirectWebsite}
          togglePersonalityInfographicPopup={togglePersonalityInfographicPopup}
          closePersonalityInfographicPopup={closePersonalityInfographicPopup}
          handleChange={handleChange}
          handleResumeRef={handleResumeRef}
          handleRemoveResume={handleRemoveResume}
          handleResumeChange={handleResumeChange}
          handleChangeBio={handleChangeBio}
          handleChangeKeySkills={handleChangeKeySkills}
          handleRemoveSkills={handleRemoveSkills}
          handleChangeProfileType={handleChangeProfileType}
        />
      ) : (
        <ProfileSettings
          user={user}
          isBusinessView={isBusinessView}
          state={state}
          handleCreateConnectAccount={handleCreateConnectAccount}
          handleNotificationSettingsChange={handleNotificationSettingsChange}
        />
      )}
    </div>
  );
};

export default Profile;
