import React, { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";
import ProjectStatusComp from "../ProjectStatus";
import ProjectDetails from "../ProjectDetails";
import SelectTimeSlot from "./SelectTimeSlot";
import InterviewLink from "./InterviewLink";
import {
  acceptInterview,
  getMeetingLink,
  declineInterviewSlot,
  getDeclineSlots,
} from "api";
import { Modal } from "react-bootstrap";
import declineSvg from "../../../assets/imgs/decline.svg";
import { useSnackbar, enqueueSnackbar } from "notistack";

type Props = {
  description: string;
  responsibilties: string[];
  qualifications: string[];
  skills: string[];
  timeSlots: object;
  projectId: string;
  studentId: string;
  hourlyRate: number;
  jobDocumentUrl?: string;
  title: string;
  type: string;
  duration: number;
  totalApplicants: number;
  lastUpdate: string;
};

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const arr = ["11:00", "11:30", "12:00", "12:30"];

const RequestInterviewProject = ({
  description,
  responsibilties,
  qualifications,
  skills,
  timeSlots,
  projectId,
  studentId,
  hourlyRate,
  jobDocumentUrl = "",
  title,
  type,
  duration = 0,
  totalApplicants,
  lastUpdate,
}: Props) => {
  const [state, setState] = useMergeState({
    selectedWindow: { day: "", time: "" },
    showDeclineModal: false,
    interviewLink: "",
    interviewDate: "",
    interviewTime: "",
    isLoading: false,
    declineSlots: [[], [], [], [], [], [], []],
    disableButtons: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const updateSelectedWindow = (day: any, time: any) => {
    setState({ selectedWindow: { day, time } });
  };

  const handleSubmit: any = async () => {
    if (state.selectedWindow.day === "" || state.selectedWindow.time === "") {
      enqueueSnackbar({
        variant: "error",
        message: "Please select a time slot.",
      });
    } else {
      setState({ isLoading: true });
      const res = await acceptInterview({
        projectId,
        studentId,
        window: {
          day: days[state?.selectedWindow?.day],
          time: state?.selectedWindow?.time,
        },
      });
      if (res.success) {
        setState({ isLoading: false });
        enqueueSnackbar("interview time submited successfully.", {
          variant: "success",
        });
        window.location.reload();
      }

      // setState({ shouldShowConfirmation: true });
    }
  };

  const handleDeclineinterview = async () => {
    try {
      if (state.selectedWindow.time !== "" && state.selectedWindow.day !== "") {
        setState({ isLoading: true });
        const response = await declineInterviewSlot({
          projectId,
          time: state?.selectedWindow?.time,
          day: days[state?.selectedWindow?.day],
        });
        if (response.success) {
          const temp = state.declineSlots;
          temp[state?.selectedWindow?.day].push(state?.selectedWindow?.time);
          setState({ declineSlots: [...temp], showDeclineModal: false });
          enqueueSnackbar("interview slot declined successfully.", {
            variant: "success",
          });
        }
      } else {
        enqueueSnackbar("Please select time slot.", {
          variant: "error",
        });
      }
    } finally {
      setState({ isLoading: false });
    }
  };

  const init = async () => {
    const response = await getMeetingLink({ projectId, studentId });
    if (response.success && response.data.length > 0) {
      setState({
        interviewLink: response.data[0].link,
        interviewDate: response.data[0].date,
        interviewTime: response.data[0].time,
      });
    }
  };

  const getDeclineSlotsInit = async () => {
    const res = await getDeclineSlots({ projectId });
    if (res.success && res?.data?.length > 0) {
      const temp = state.declineSlots;
      res.data.map((da: any) => {
        const index = days.indexOf(da.day);
        temp[index].push(da.time);
      });
      setState({ declineSlots: [...temp] });
    }
  };

  const setDisableButtons = () => {
    setState({ disableButtons: true });
  };

  useEffect(() => {
    init();
    getDeclineSlotsInit();
  }, []);

  return (
    <div>
      <div className="p-4 bg-white w-100 border border_gray border_r_16px">
        <ProjectStatusComp
          type={1}
          buttonTitle={
            state?.interviewLink?.length === 0
              ? "Schedule Interview"
              : "Interview Scheduled"
          }
          buttonOnClick={
            state?.interviewLink?.length === 0 &&
            state?.disableButtons === false
              ? () => (state?.isLoading ? {} : handleSubmit())
              : undefined
          }
          disablePrimaryButton={state?.isLoading}
          hourlyRate={hourlyRate}
          showDeclineButton={
            state?.interviewLink?.length === 0 &&
            state?.disableButtons === false
              ? true
              : false
          }
          handleDecline={() => setState({ showDeclineModal: true })}
          jobDocumentUrl={state.jobDocumentUrl}
          title={title}
          titleType={type}
          duration={duration}
          totalApplicants={totalApplicants}
          lastUpdate={lastUpdate}
        />
        <div className="border-bottom box_shadow w-100 my-4" />
        {state?.interviewLink?.length === 0 && (
          <SelectTimeSlot
            slots={timeSlots}
            updateSelectedWindow={updateSelectedWindow}
            declineSlot={state.declineSlots}
            setDisableButtons={() => setDisableButtons()}
          />
        )}
        {state?.interviewLink.length !== 0 && (
          <InterviewLink
            type={0}
            link={state?.interviewLink}
            date={state?.interviewDate}
            time={state?.interviewTime}
          />
        )}

        {/* <div className="border-bottom box_shadow w-100 my-4" /> */}
        {/* <InterviewLink type={1} link="http://" /> */}
      </div>
      <div className="p-4 bg-white w-100 border border_gray border_r_16px mt-4 mb-4 mb-md-0">
        <ProjectDetails
          description={description}
          responsibilties={responsibilties}
          qualifications={qualifications}
          skills={skills}
        />
      </div>
      <Modal
        show={state.showDeclineModal}
        onHide={() => setState({ showDeclineModal: false })}
        centered
      >
        <Modal.Body style={{ margin: 0, padding: 0 }}>
          <div>
            <div>
              <div className="modal-content p-3">
                <div className="modal-body">
                  <div className="text-center mb-4 d-flex justify-content-center">
                    <img src={declineSvg} />
                  </div>
                  <p className="fs-4 fw-bold text-center mb-4 text_black lh-sm">
                    Are you sure you want to <br /> decline this slot?
                  </p>
                  <p className="fs-6 text_primary mt-4 text-center">
                    Selected Timeslot will be removed from your Project.
                  </p>
                </div>
                <div className="modal-footer border-0">
                  <button
                    type="button"
                    className="btn bg_fullred text_white w-100"
                    data-bs-dismiss="modal"
                    style={{ cursor: state?.isLoading ? "none" : "pointer" }}
                    onClick={() =>
                      state?.isLoading ? {} : handleDeclineinterview()
                    }
                  >
                    Decline
                  </button>
                  <button
                    type="button"
                    className="btn bg_light_secondary text_secondary w-100"
                    data-bs-dismiss="modal"
                    onClick={() => setState({ showDeclineModal: false })}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestInterviewProject;
