import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMergeState } from "utils/custom-hooks";
import { verifyEmail } from "api";

type Props = {
  setUser: any;
  loggedIn: boolean;
};

export default function EmailVerification({ setUser, loggedIn }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || null;

  const [state, setState] = useMergeState({
    isLoading: true,
    isVerified: false,
    userId: "",
    message: "Your email is verifying...",
  });

  const handleNavigation = () => {
    localStorage.setItem(`${state.userId}`, "true");
    navigate("/signin");
  };

  const handleDisplayMessage = (
    message: string,
    toastMessage: string,
    toastType: any,
    userId: string
  ) => {
    setState({
      isVerified: true,
      isLoading: false,
      userId,
      message,
    });
    enqueueSnackbar(toastMessage, {
      variant: toastType,
    });
  };

  const onVerifyEmail = async () => {
    const params: any = {
      token,
    };
    try {
      const response = await verifyEmail(params);
      if (response.success) {
        handleDisplayMessage(
          "Your email has been verified.",
          response.message,
          "success",
          response.user._id
        );
      } else {
        handleDisplayMessage(
          "Your email has not been verified.",
          response.message,
          "error",
          ""
        );
      }
    } catch (err: any) {
      handleDisplayMessage(
        "Your email has not been verified.",
        err.message,
        "error",
        ""
      );
    }
  };

  useEffect(() => {
    if (token) onVerifyEmail();
    else {
      setState({
        isVerified: true,
        isLoading: false,
        message: "Your email has not been verified.",
      });
    }
  }, []);

  return (
    <div className="middle_box pb-5">
      <div className="bg_white p-4 p-md-5 px-3 px-md-5 border_r_8px">
        <h3 className="fw-bold text_primary mb-4 text-center">
          Welcome to Runway
        </h3>
        <p className="font_18 text_primary text-center mb-3">{state.message}</p>

        <button
          type="button"
          disabled={!state.isVerified}
          className={`btn bg_secondary w-100 mb-3 mt-3 ${
            !state.isVerified ? "bg-rw-disable" : ""
          }`}
          onClick={handleNavigation}
        >
          Go to Login
        </button>
      </div>
    </div>
  );
}
