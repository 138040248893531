import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Images from "assets/images";
import { projectHasStudent } from "utils/common";
// import { projectHasStudent, timesAgo, wrapFullName } from "utils/common";

type Props = {
  user: any;
  projects: any;
};

export default function SearchProjectMobileView({ user, projects }: Props) {
  const navigate = useNavigate();

  const isProjectHasStudent = (project: any) => {
    const { studentProject } = projectHasStudent({
      project,
      studentId: user?._id,
    });
    return studentProject;
  };

  const handleOnClick = (projectId: string) => {
    navigate(`/projects/details?id=${projectId}&explore=${true}`);
  };

  return (
    <div className="bg_light p-3 p-md-4 w-100 overflow-hidden">
      {projects.length > 0 ? (
        <div className="d-flex align-items-start flex-wrap flex-md-nowrap">
          {projects.map((project: any, index: number) => (
            <div
              key={index}
              className="box_window mb-3"
              onClick={() => handleOnClick(project._id)}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img src={Images.Stack} />
                </div>
                <div>
                  <p className="text_black fw-bold fs-6 ms-3 lh-sm text-start">
                    {project.title}
                  </p>
                  <p className="font_12 text_primary ms-3 text-start">
                    {project.type}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap mt-4">
                {project.skills
                  .slice(0, 3)
                  .map((skill: string, index: string) => (
                    <span key={index} className="tag">
                      {skill}
                    </span>
                  ))}
                {project.skills.length > 3 && (
                  <span className="tag">{`+${project.skills.length - 3}`}</span>
                )}
              </div>
              <div className="border-bottom border_gray my-2" />
              <div className="d-flex align-items-center justify-content-between mt-3">
                {!isProjectHasStudent(project) ? null : (
                  <p className="font_14 fw_500 text_secondary bg_light_secondary px-3 py-2 border_r_8px">
                    <span className="me-2">
                      <i className="fa-duotone fa-check" />
                    </span>
                    Applied
                  </p>
                )}
                <p className="font_12 fw-bold text_black">
                  {`$${project.hourlyRate}/HR`}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <p className="text_black fw-bold fs-5 ms-3 lh-base">
            No Projects Found
          </p>
        </div>
      )}
    </div>
  );
}
