import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import UserDetails from "components/UserDetails";
import { validateUserPersonalityTest } from "./personality-tests/helper";
import Images from "../../assets/images";

type Props = {
  user: any;
};

export default function GeneralPersonalityList({ user }: Props) {
  const navigate = useNavigate();
  const [types] = useState([
    { type: "Designer", icon: Images.Designer },
    { type: "Developer", icon: Images.Developer },
    { type: "Engineer", icon: Images.Engineer },
    { type: "Business", icon: Images.Business },
  ]);

  const location = useLocation();
  const isProjectPersonalityTest =
    location.state && location.state?.projectType;
  let projectType = isProjectPersonalityTest
    ? location.state?.projectType
    : null;
  const projectId = isProjectPersonalityTest ? location.state?.projectId : null;
  let project: any = {};
  let testType: string = "";

  if (isProjectPersonalityTest) {
    projectType =
      projectType.charAt(0).toUpperCase() + projectType.slice(1).toLowerCase();

    project = types?.find((item: any) => item.type === projectType);
    testType = project?.type || "";
  }

  const handleCompleteLater = () => {
    navigate("/projects");
  };

  const handleStartTest = (type: string) => {
    if (type === "Designer") {
      navigate("/personality-tests/persona?type=designer", {
        state: { projectType, projectId },
      });
    } else if (type === "Developer") {
      navigate("/personality-tests/persona?type=developer", {
        state: { projectType, projectId },
      });
    } else if (type === "Engineer") {
      navigate("/personality-tests/persona?type=engineer", {
        state: { projectType, projectId },
      });
    } else if (type === "Business") {
      navigate("/personality-tests/persona?type=business", {
        state: { projectType, projectId },
      });
    }
  };

  useEffect(() => {
    // if (validateUserPersonalityTest(user)) {
    //   navigate("/projects");
    // }

    // added else if check for these users who have no personality test for applying project
    if (!isProjectPersonalityTest && validateUserPersonalityTest(user)) {
      navigate("/projects");
    } else if (isProjectPersonalityTest) {
      const isPersonalityTestPerformed =
        user.personality[projectType.toLowerCase()] !== undefined;
      if (isPersonalityTestPerformed) {
        // const projectDetailUrl = `/projects/details?id=${projectId}&view=OVERVIEW&explore=true`;
        // const projectDetailUrl = `/projects/details?id=${projectId}&explore=true`;
        const projectDetailUrl = `/projects?id=${projectId}&explore=true`;
        navigate(projectDetailUrl);
      }
    }
  }, []);

  return (
    <div>
      <div className="h-20 flex items-center px-4">
        {/* w-1/4 width for cancel button */}
        <button
          className="hidden lg:flex items-center"
          style={{ marginRight: 30 }}
          onClick={handleCompleteLater}
        >
          <span className="text-rw-red mr-2">
            <CancelIcon sx={{ width: 30, height: 30 }} />
          </span>
          <div className="text-xl font-semibold">Cancel</div>
        </button>
        <div className="w-full">
          <div className="text-center">
            <div className="text-rw-blue text-center text-sm font-semibold">
              GET STARTED
            </div>
            <div className="text-rw-gray-0 text-center text-2xl font-semibold">
              Personality Test
            </div>
          </div>
        </div>

        <UserDetails user={user} />
      </div>

      <hr />

      <div className="flex flex-col justify-center items-center pt-7">
        <img src={Images.PersonalityTest} className="w-24 h-24 mb-5" />
        <div className="text-black text-center text-3xl font-semibold mb-2">
          Personality Test(s)
        </div>
        <div className="text-rw-gray-0 text-base mb-7">
          12 Questions each (5-10 min)
        </div>
        <div className="text-rw-gray-0 text-center mb-8 md:text-base text-sm w-72 md:w-96">
          To apply to projects, you must complete a quick personality test for
          your selected project types to better understand your work style.
        </div>
        {!isProjectPersonalityTest ? (
          types.map((el) => {
            if (user?.profileTypes.includes(el.type.toUpperCase())) {
              return (
                <div className="w-fit h-fit rounded-lg border-[1px] bg-white border-rw-gray-gainsboro px-4 py-6 mb-5">
                  <div className="flex gap-4 justify-start mb-5">
                    <img src={el.icon} className="w-8 h-8" />
                    <div className="text-[#414141] text-xl font-semibold">
                      {el.type}
                    </div>
                  </div>
                  <div className="w-72 md:w-96 gap-2">
                    {user?.personality[el.type.toLowerCase()] === undefined ? (
                      <button
                        className="w-[100%] text-white py-4 border-none justify-center items-center bg-rw-blue rounded-lg text-lg text-center font-medium"
                        onClick={() => {
                          handleStartTest(el.type);
                        }}
                      >
                        Start Personality Test
                      </button>
                    ) : (
                      <button
                        className="w-[100%] text-white py-4 border-none justify-center items-center bg-rw-disabled-gray rounded-lg text-lg text-center font-medium"
                        disabled
                      >
                        Completed
                      </button>
                    )}
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className="w-fit h-fit rounded-lg border-[1px] bg-white border-rw-gray-gainsboro px-4 py-6 mb-5">
            <div className="flex gap-4 justify-start mb-5">
              <img src={project?.icon} className="w-8 h-8" />
              <div className="text-[#414141] text-xl font-semibold">
                {project?.type}
              </div>
            </div>
            <div className="w-72 md:w-96 gap-2">
              {user?.personality[testType.toLowerCase()] === undefined ? (
                <button
                  className="w-[100%] text-white py-4 border-none justify-center items-center bg-rw-blue rounded-lg text-lg text-center font-medium"
                  onClick={() => {
                    handleStartTest(testType);
                  }}
                >
                  Start Personality Test
                </button>
              ) : (
                <button
                  className="w-[100%] text-white py-4 border-none justify-center items-center bg-rw-disabled-gray rounded-lg text-lg text-center font-medium"
                  disabled
                >
                  Completed
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center my-8">
        <button
          className="w-72 md:w-96 py-[16px] px-[50px] border-[2px] border-rw-blue border-solid justify-center items-center bg-white rounded-md gap-3 text-rw-blue text-lg text-center font-medium"
          onClick={handleCompleteLater}
        >
          Complete Later
        </button>
      </div>
    </div>
  );
}
