import { useNavigate, useLocation } from "react-router-dom";
import Images from "assets/images";

export default function ExternalNav() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleRedirectGetStarted = () => {
    navigate("/signup");
  };

  const handleRedirectLogin = () => {
    navigate("/signin");
  };

  // const hidden = pathname.includes("signup");
  const isAuthScreen =
    pathname.includes("signup") ||
    pathname.includes("signin") ||
    pathname.includes("email-verification");

  const isProjectsOrCareerFairPage =
    pathname.includes("projects") || pathname.includes("career-fair");

  return !isAuthScreen ? (
    <header className="header body_pd" id="header">
      <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
        <span className="d-block d-md-none me-3">
          <img src={Images.Runaway_logo} />
        </span>
        <nav className="navbar navbar-expand-lg w-100">
          <div className="container-fluid">
            <button
              className="navbar-toggler w-100 text-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main_navbar"
              aria-controls="main_navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text_secondary mt-2">
                <i className="fa-regular fa-bars" />
              </span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between w-100 align-items-center py-3"
              id="main_navbar"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-start align-items-md-center">
                <li className="nav-item d-none d-md-block">
                  <a className="nav-link active" href="/">
                    <img src={Images.Runaway_logo} />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Projects
                  </a>
                </li>
              </ul>
              <ul className="menu2">
                <li>
                  <a
                    href="/signup"
                    className="btn btn_small bg_light_secondary rounded-pill text_secondary"
                  >
                    For Business
                  </a>
                </li>
                <li>
                  <a
                    href="/signup"
                    className="btn btn_small bg_secondary mx-0 mx-md-3"
                  >
                    Apply as Student
                  </a>
                </li>
                <li>
                  <a
                    href="/signin"
                    className="btn btn_small bg_darkish text-white"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  ) : (
    <div className="text-center p-4 p-md-5">
      <a
        href={process.env.REACT_APP_HOME_BASE_URL}
        className="mx-auto d-inline-block full_logo"
      >
        <img src={require("../../assets/imgs/full_logo.png")} />
      </a>
    </div>
  );

  // return isProjectsOrCareerFairPage ? (
  //   <div className="bg-white">
  //     <div className="flex justify-center h-24">
  //       <div className="w-11/12 lg:w-10/12 flex justify-center lg:justify-start items-center">
  //         <div className="flex justify-between items-center w-full">
  //           <div>
  //             <a
  //               href={process.env.REACT_APP_HOME_BASE_URL}
  //               className="flex items-center"
  //             >
  //               <img src={Images.Logo} width={24} height={24} />
  //               <span className="text-2xl font-bold ml-2">Runway</span>
  //             </a>
  //           </div>

  //           <div className="flex items-center">
  //             <button
  //               className="text-white text-center font-bold p-2 lg:py-3 lg:px-6 bg-rw-blue-dark rounded-[4px] mr-4 lg:mr-8"
  //               onClick={handleRedirectGetStarted}
  //             >
  //               Get Started
  //             </button>

  //             <button
  //               className="text-white text-center font-bold p-2 lg:py-3 lg:px-6 bg-rw-gray-darker rounded-[4px]"
  //               onClick={handleRedirectLogin}
  //             >
  //               Login
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   // <div className={`bg-rw-blue-lightest h-fit ${hidden ? "hidden" : ""}`}>
  //   //   <div className="flex justify-center h-24">
  //   //     <div className="w-11/12 lg:w-10/12 flex justify-center lg:justify-start items-center">
  //   //       <a
  //   //         href={process.env.REACT_APP_HOME_BASE_URL}
  //   //         className="flex items-center"
  //   //       >
  //   //         <img src={Images.Logo} width={24} height={24} />
  //   //         <span className="text-2xl font-bold ml-2">Runway</span>
  //   //       </a>
  //   //     </div>
  //   //   </div>
  //   // </div>
  //   <div className="text-center p-4 p-md-5">
  //     <a
  //       href={process.env.REACT_APP_HOME_BASE_URL}
  //       className="mx-auto d-inline-block full_logo"
  //     >
  //       <img src={require("../../assets/imgs/full_logo.png")} />
  //     </a>
  //   </div>
  // );
}
