// eslint-disable-next-line import/no-extraneous-dependencies
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import Button from "components/Button";
import CardSection from "./CardSection";
import { useMergeState } from "utils/custom-hooks";
import { addPaymentMethod } from "api";

type Props = {
  user: any;
  clientSecret: string;
  onClose: (param?: boolean) => void;
};

export default function CardSetupForm({
  user,
  clientSecret = "",
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();

  const elements = useElements();

  const [state, setState] = useMergeState({ isSavingCardDetails: false });

  const handleSaveCard = async () => {
    if (
      !stripe ||
      !elements ||
      !clientSecret ||
      !user?.business?.platformCustomerId
    ) {
      return;
    }

    const response = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        // @ts-ignore
        card: elements.getElement(CardElement),
        // billing_details: {
        // },
      },
    });

    if (response?.error) {
      setState({ isSavingCardDetails: false });

      enqueueSnackbar(
        `${response?.error?.code} - ${response?.error?.decline_code || ""} - ${
          response?.error?.message
        }`,
        { variant: "error" }
      );

      onClose();

      return;
    }

    if (response?.setupIntent?.payment_method) {
      const paymentMethodResponse = await addPaymentMethod({
        id: response?.setupIntent?.payment_method,
      });

      if (paymentMethodResponse?.success) {
        setState({ isSavingCardDetails: false });

        enqueueSnackbar(paymentMethodResponse?.message, { variant: "success" });

        onClose(true);
      }
    }
  };

  const isLoading =
    !stripe || !elements || !clientSecret || state?.isSavingCardDetails;

  return (
    <div>
      <div className="mb-10" style={{ width: "100%" }}>
        <CardSection />
      </div>

      <div className="flex justify-end mt-10">
        <Button
          onClick={handleSaveCard}
          loading={isLoading}
          disabled={isLoading}
        >
          Save Card
        </Button>
      </div>
    </div>
  );
}
