import React, { useEffect } from "react";
import { getStudentProfileStats, listInvoices } from "api";
import manager from "../../../../assets/imgs/manager.svg";
import hours from "../../../../assets/imgs/hours.svg";
import earning from "../../../../assets/imgs/earning.svg";

type Props = {
  user: any;
  firstName: string;
  lastName: string;
  totalHours: number;
  totalEarning: number;
  studentProjects: any;
  handleChangeTab: (index: number) => any;
  goToInvoiceView: (date: string) => any;
};
const Overview = ({
  firstName,
  lastName,
  user,
  totalHours,
  totalEarning,
  studentProjects,
  handleChangeTab,
  goToInvoiceView,
}: Props) => {
  const isStudent = user.accountType === "STUDENT";

  const onCalculateHours = (student: any) => {
    if (isStudent)
      return {
        totalAmountOfStudent: totalEarning,
        totalHoursOfStudent: totalHours,
      };
    const { totalAmountOfStudent, totalHoursOfStudent } =
      student.timesheets.reduce(
        (total: any, item: any) => ({
          totalAmountOfStudent:
            item.totalAmount + (total?.totalAmountOfStudent || 0),
          totalHoursOfStudent:
            item.totalHours + (total?.totalHoursOfStudent || 0),
        }),
        { totalAmountOfStudent: 0, totalHoursOfStudent: 0 }
      );
    return {
      totalAmountOfStudent:
        totalAmountOfStudent + Number(totalAmountOfStudent * 0.03),
      totalHoursOfStudent,
    };
  };
  return (
    <div>
      {studentProjects.map((item: any) => (
        <div className="row mb-3" key={item._id}>
          <div className="col-md-4">
            <div className="bg_lightgray px-4 px-sm-3 py-4 py-md-5 text-center mb-3 mb-md-0">
              <div
                className="project_icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={manager} />
              </div>
              <p className="fs-6">{isStudent ? "Manager" : "Student"}</p>
              {isStudent ? (
                <p className="text_black fw-bold fs-5">{`${firstName} ${lastName}`}</p>
              ) : (
                <p className="text_black fw-bold fs-5">{`${item.student.firstName} ${item.student.lastName}`}</p>
              )}
              <div className="w-50 text-center mx-auto mt-3 mob_100 invisible">
                <button
                  type="button"
                  className="btn btn_small bg_black text-white w-100 px-2 px-sm-2"
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="bg_lightblue p-4 p-sm-3 py-4 py-md-5 text-center mb-3 mb-md-0">
              <div
                className="project_icon "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={hours} />
              </div>
              <p className="fs-6">Total Hours</p>
              <p className="text_black fw-bold fs-5">
                {onCalculateHours(item)?.totalHoursOfStudent} Hours
              </p>
              <div className="w-50 text-center mx-auto mt-3 mob_100">
                <button
                  type="button"
                  className="btn btn_small bg_secondary text-white w-100 px-2 px-sm-2"
                  onClick={() =>
                    isStudent
                      ? handleChangeTab(1)
                      : goToInvoiceView(item.timesheets[0]?.weekStart)
                  }
                >
                  View Timesheets
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="bg_lightorange p-4 p-sm-3 py-4 py-md-5 text-center mb-0">
              <div
                className="project_icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={earning} />
              </div>
              <p className="fs-6">
                {isStudent ? "Total Earnings" : "Amount Due"}
              </p>
              <p className="text_black fw-bold fs-5">
                ${onCalculateHours(item)?.totalAmountOfStudent}
              </p>
              <div className="w-50 text-center mx-auto mt-3 mob_100">
                <button
                  type="button"
                  className="btn btn_small bg_orange text-white w-100 px-2 px-sm-2"
                  onClick={() => goToInvoiceView(item.timesheets[0]?.weekStart)}
                >
                  {isStudent ? "View Stripe" : "Pay Student"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Overview;
