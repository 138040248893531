import React, { useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
// import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import RichTextEditorField from "components/RichTextEditorField";
import { validateEmail, wrapHTMLContent } from "utils/string";
import {
  AccountType,
  RUNWAY_TOKEN,
  StudentTypes,
  USAUniversities,
} from "utils/constants";
import {
  isValidUrl,
  toBase64,
  validatePhone,
  wrapNameTwoLetters,
} from "utils/common";
import { getSignedUrlOfS3, signup } from "api";
import UserInfoComp from "components/UserDetails/userInfoComp";

const CustomTextField = styled(MuiTextField)(() => ({
  "& .MuiInputLabel-root": {
    marginTop: 8,
    fontSize: 14,
    width: "100%",
    "&.Mui-focused": {
      color: "#363333",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
    "& legend": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      border: "0.8px solid rgba(112, 110, 110, 0.4)",
    },
  },
}));

const GenderOptions = ["Female", "Male", "Not Declared"];

const internationalStudentList = ["Yes", "No"];

const RaceOptions = [
  "I do not wish to disclose",
  "Asian",
  "Black or African American",
  "Hispanic or Latino",
  "Native American or Alaska Native",
  "Native Hawaiian or Other Pacific Islander",
  "Two or More Races",
  "White/Caucasian",
];

const StepLabels = {
  EMAIL: "EMAIL",
  TYPE: "TYPE",
  SCHOOL: "SCHOOL",
  OPPORTUNITY_TYPE: "OPPORTUNITY_TYPE",
  LOGIN: "DETAILS",
  PROFILE: "PROFILE",
  SELF_IDENTITY: "SELF_IDENTITY",
  CONFIRM: "CONFIRM",
};

const StudentSteps = [
  {
    id: 1,
    label: StepLabels.EMAIL,
    title: "Email",
    show: true,
  },
  {
    id: 2,
    label: StepLabels.TYPE,
    title: "Account Type",
    show: true,
  },
  {
    id: 3,
    label: StepLabels.SCHOOL,
    title: "School Selection",
    show: true,
  },
  {
    id: 4,
    label: StepLabels.OPPORTUNITY_TYPE,
    title: "Opportunity type",
    show: true,
  },
  {
    id: 5,
    label: StepLabels.LOGIN,
    title: "Login Details",
    show: true,
  },
  {
    id: 6,
    label: StepLabels.PROFILE,
    title: "Profile Details",
    show: true,
  },
  {
    id: 7,
    label: StepLabels.SELF_IDENTITY,
    title: "Self Identity",
    show: false,
  },
  {
    id: 8,
    label: StepLabels.CONFIRM,
    title: "Confirm Account",
    show: true,
  },
];

const BusinessSteps = [
  {
    id: 1,
    label: StepLabels.EMAIL,
    title: "Email",
    show: true,
  },
  {
    id: 2,
    label: StepLabels.TYPE,
    title: "Account Type",
    show: true,
  },
  {
    id: 3,
    label: StepLabels.LOGIN,
    title: "Login Details",
    show: true,
  },
  {
    id: 4,
    label: StepLabels.CONFIRM,
    title: "Confirm Account",
    show: true,
  },
];

let Steps = StudentSteps;

const SMS_UPDATES_CONSENT = {
  YES: "YES",
  NO: "NO",
};

type Props = {
  setUser: any;
};

export default function SignUpContainer({ setUser }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    accountType: "",
    isContinueBtnPress: false,
    currentStep: Steps[0],

    // school
    university: "",

    // type
    profileTypes: [],

    // login details
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    smsOptIn: SMS_UPDATES_CONSENT.YES,

    // self identity
    race: "",
    gender: "",
    internationalStudent: "",

    // profile details
    avatarFile: null,
    avatarBase64: null,

    resumeFile: null,
    resumeBase64: null,

    website: "",
    expectedYear: "",
    bio: "",
    keySkills: "",
    skills: [],

    // business user

    companyName: "",
    atv: {
      isATV: false,
      optedIn: null,
    },

    errors: {},
    isSignedUp: false,
    access_token: null,
    userToken: null,
    userInfo: {},
    profile: {},
    provider: "",
    socialId: "",
    isDisableBtn: false,

    shouldShowRaceDefinitionsDialog: false,
  });

  const isContinueWithGoogle = Object.keys(state.profile).length > 0;
  const isStudentAccount = state.accountType === AccountType.STUDENT;
  Steps = isStudentAccount ? StudentSteps : BusinessSteps;

  const avatarRef = useRef<any>();

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event?.target?.files && event?.target?.files[0];

      if (!file) {
        return;
      }

      event.target.value = "";

      const base64 = await toBase64(file);

      setState({
        avatarFile: file,
        avatarBase64: base64,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleAvatarRef = () => {
    avatarRef?.current?.click();
  };

  const resumeRef = useRef<any>();

  const handleResumeRef = () => {
    resumeRef?.current?.click();
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file) {
      return;
    }

    event.target.value = "";

    const base64 = await toBase64(file);

    setState({
      resumeFile: file,
      resumeBase64: base64,
    });
  };

  const handleRemoveResume = () => {
    setState({
      resumeFile: null,
      resumeBase64: null,
    });
  };

  const handleChangeAccountType = (accountType: string) => {
    setState({
      accountType,
      errors: {
        ...state?.errors,
        accountType: "",
      },
    });
  };

  const handleChangeBio = (bio: string) => {
    setState({
      bio,
      errors: {
        ...state?.errors,
        bio: "",
      },
    });
  };

  const handleChangeKeySkills = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keySkills = event?.target?.value;

    const keySkillsArray = keySkills?.split(",");

    const skills = keySkillsArray.map((elem: string) =>
      String(elem)?.trim()?.toUpperCase()
    );

    setState({
      keySkills,
      skills,
      errors: {
        keySkills: "",
      },
    });
  };

  const handleRemoveSkills = (skill: any) => {
    let skillsCopy = state.skills.slice();
    skillsCopy = skillsCopy.filter((elem: string) => elem !== skill);

    setState({
      keySkills: skillsCopy.map((elem: string) => elem.toLowerCase()).join(","),
      skills: skillsCopy,
      errors: {
        keySkills: "",
      },
    });
  };

  const handleEditStep = (stepLabel: string) => {
    setState({ currentStep: Steps.find((step) => step?.label === stepLabel) });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (state?.currentStep?.label === StepLabels.EMAIL) {
      if (!state?.password) {
        errors = { ...errors, password: "Password is required." };
        isValid = false;
      }

      if (!state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Confirm password is required.",
        };
        isValid = false;
      }

      if (state?.password !== state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Passwords do not match.",
        };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.TYPE) {
      if (!state?.accountType) {
        errors = { ...errors, accountType: "Account type is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.SCHOOL) {
      if (!state?.university) {
        errors = { ...errors, university: "University is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.OPPORTUNITY_TYPE) {
      if (!state?.profileTypes?.length) {
        errors = { ...errors, profileTypes: "Profile type is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.LOGIN) {
      if (!isContinueWithGoogle && !state?.firstName) {
        errors = { ...errors, firstName: "First Name is required." };
        isValid = false;
      }

      if (!isContinueWithGoogle && !state?.lastName) {
        errors = { ...errors, lastName: "Last Name is required." };
        isValid = false;
      }

      // if (!validateEmail(state?.email)) {
      //   isValid = false;
      //   errors = { ...errors, email: "Email is invalid." };
      // }

      // if (!state?.password) {
      //   errors = { ...errors, password: "Password is required." };
      //   isValid = false;
      // }

      // if (!state?.confirmPassword) {
      //   errors = {
      //     ...errors,
      //     confirmPassword: "Confirm password is required.",
      //   };
      //   isValid = false;
      // }

      // if (state?.password !== state?.confirmPassword) {
      //   errors = {
      //     ...errors,
      //     confirmPassword: "Passwords do not match.",
      //   };
      //   isValid = false;
      // }

      if (!validatePhone(state?.phone)) {
        errors = { ...errors, phone: "Phone is invalid." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.PROFILE) {
      if (!state?.resumeFile) {
        errors = { ...errors, resumeFile: "Resume is required." };
        isValid = false;
      }
      if (!state?.avatarFile) {
        errors = { ...errors, avatarFile: "profile pic is required." };
        isValid = false;
      }

      if (state?.website && !isValidUrl(state?.website)) {
        isValid = false;
        errors = { ...errors, website: "Website is invalid." };
      }

      if (!state?.expectedYear) {
        isValid = false;
        errors = { ...errors, expectedYear: "Expected year is required." };
      }

      if (!state?.bio) {
        errors = { ...errors, bio: "Bio is required." };
        isValid = false;
      }

      if (!state?.keySkills) {
        errors = { ...errors, keySkills: "Skills is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.SELF_IDENTITY) {
      if (!state?.race) {
        errors = { ...errors, race: "Race is required." };
        isValid = false;
      }

      if (!state?.gender) {
        errors = { ...errors, gender: "Gender is required." };
        isValid = false;
      }
      if (!state?.internationalStudent) {
        errors = {
          ...errors,
          internationalStudent: "international student field is required.",
        };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const getNextStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) + 1];

  const getPrevStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 1];

  const handleContinue = () => {
    if (!isFormValid()) {
      return;
    }

    const nextStep = getNextStep();
    setState({ currentStep: nextStep });
  };

  const handleBack = () => {
    const prevStep = getPrevStep();
    if (prevStep.id === 1) {
      setState({
        currentStep: prevStep,
        profile: {},
        userInfo: {},
      });
    } else {
      setState({ currentStep: prevStep });
    }
  };

  const handleContinueBtn = () => {
    let isValid = true;

    let errors = {};
    if (!state?.email) {
      isValid = false;
      errors = { ...errors, email: "Email is required." };
    } else if (!validateEmail(state?.email)) {
      isValid = false;
      errors = { ...errors, email: "Email is invalid." };
    }
    if (!isValid) {
      setState({ errors });
      return;
    }

    if (!state.isContinueBtnPress) {
      setState({ isContinueBtnPress: true });
    } else {
      handleContinue();
      // const nextStep = getNextStep();
      // setState({ currentStep: nextStep });
    }
  };

  const handleBackBtn = () => {
    setState({ isContinueBtnPress: false });
  };

  const handleOpenRaceDefinitionsDialog = () => {
    setState({ shouldShowRaceDefinitionsDialog: true });
  };

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleChangeUniversity = (university: string) => {
    setState({
      university,
      errors: {
        ...state?.errors,
        university: "",
      },
    });
  };

  const handleChangeSmsOptIn = (smsOptIn: string) => {
    setState({
      smsOptIn,
    });
  };

  const handleChangeProfileType = (profileType: string) => {
    const updatedProfileTypes = [...state.profileTypes];

    const index = state.profileTypes.findIndex(
      (elem: string) => elem === profileType
    );

    if (index === -1) {
      updatedProfileTypes.push(profileType);
    } else {
      updatedProfileTypes.splice(index, 1);
    }

    setState({
      profileTypes: updatedProfileTypes,
      errors: {
        ...state?.errors,
        profileTypes: "",
      },
    });
  };

  const handleStudentSignup = async () => {
    try {
      const payload: any = isStudentAccount
        ? {
            // accountType: AccountType.STUDENT,
            accountType: state.accountType,
            firstName: state?.firstName,
            lastName: state?.lastName,
            email: String(state?.email).trim().toLowerCase(),
            password: state?.password,
            confirmPassword: state?.confirmPassword,
            phone: state?.phone,
            university: state?.university,
            profileTypes: state?.profileTypes,
            smsOptIn: state?.smsOptIn === SMS_UPDATES_CONSENT.YES,
            race: state?.race,
            gender: state?.gender,
            internationalStudent:
              state?.internationalStudent === "Yes" ? true : false,
            avatarBase64: state?.avatarBase64,
            resumeBase64: state?.resumeBase64,
            website: state?.website,
            bio: state?.bio,
            skills: state?.skills,
            expectedYear: state.expectedYear,
          }
        : {
            accountType: state.accountType,
            firstName: state?.firstName,
            lastName: state?.lastName,
            email: String(state?.email).trim().toLowerCase(),
            password: state?.password,
            confirmPassword: state?.confirmPassword,
            phone: state?.phone,
            companyName: state?.companyName,
            atv: state.atv,
          };

      if (isContinueWithGoogle) {
        payload.provider = state.provider;
        payload.socialId = state.profile.id;
        payload.firstName = state.profile.given_name;
        payload.lastName = state.profile.family_name;
        payload.email = state.profile.email;
      }
      setState({ isDisableBtn: true });
      const response = await signup(payload);

      if (response?.success) {
        const message = isStudentAccount
          ? "Student Sign Up"
          : "Business Sign Up";
        amplitude.track(message);
        const userInfoData = response?.data?.user;
        setState({
          isSignedUp: true,
          userToken: response?.data?.token,
          userInfo: userInfoData,
          isDisableBtn: false,
        });
      } else {
        setState({ isDisableBtn: false });
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      setState({
        access_token: codeResponse.access_token,
        provider: "google",
      });
    }, // setUserInfo(codeResponse),
    onError: (error: any) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    // if (Object.keys(state.userInfo).length > 0) {
    if (state.access_token) {
      const token = state.access_token;
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res: any) => {
          setState({
            profile: res.data,
            currentStep: Steps[1],
          }); // setProfile(res.data);
        })
        .catch((err: any) => console.log(err));
    }
  }, [state.access_token]);

  return (
    <div className="middle_box pb-5">
      {!state.isSignedUp ? (
        <div className="bg_white p-4 p-md-5 border_r_8px">
          {state?.currentStep?.label === StepLabels.EMAIL && (
            <>
              <h3 className="fw-bold text_primary mb-4">Signup</h3>
              <TextField
                type="text"
                name="email"
                placeholder="Email address"
                value={state?.email}
                onChange={handleChange}
                error={state?.errors?.email}
              />
              {state.isContinueBtnPress && (
                <>
                  <TextField
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={state?.password}
                    onChange={handleChange}
                    error={state?.errors?.password}
                  />
                  <TextField
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={state?.confirmPassword}
                    onChange={handleChange}
                    error={state?.errors?.confirmPassword}
                  />
                </>
              )}
              <button
                type="button"
                className="btn bg_secondary w-100 mb-3"
                onClick={handleContinueBtn}
              >
                Continue
              </button>
              {state.isContinueBtnPress && (
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  onClick={handleBackBtn}
                >
                  Back
                </button>
              )}
              <p className="text_main font_14 text-center pt-4">
                Already have an account?
                <Link
                  to="/signin"
                  className="text_secondary text-decoration-none"
                >
                  {" "}
                  Login
                </Link>
              </p>

              {!state.isContinueBtnPress && (
                <>
                  <div className="border_line">
                    <span>or</span>
                  </div>
                  {/* <a
                    href="/"
                    className="text-center p-3 d-flex w-100 btn bg_transparent align-items-center mt-4 mb-3 bg_main border_r_8px"
                  >
                    <div className="text-center w-100 d-flex align-items-center justify-content-center">
                      <img src={require("../../../assets/imgs/apple.png")} />
                      <span className="text-center ms-3">
                        Continue with Apple
                      </span>
                    </div>
                  </a> */}
                  <div
                    className="text-center p-3 d-flex w-100 btn bg_transparent align-items-center mt-4 bg_main border_r_8px"
                    onClick={() => login()}
                  >
                    <div className="text-center w-100 d-flex align-items-center justify-content-center">
                      <img src={Images.GoogleIcon} />
                      <span className="text-center ms-3">
                        Continue with Google
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {state?.currentStep?.label === StepLabels.TYPE && (
            <>
              <h3 className="fw-bold text_primary mb-4">Select Account Type</h3>
              <div
                className="d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-3 cursor-pointer"
                onClick={() => handleChangeAccountType(AccountType.STUDENT)}
              >
                <div className="d-flex align-items-center">
                  <img src={Images.StudentLogo} />
                  <div className="ms-4">
                    <p className="font_18 fw-bold text_primary">
                      I’m a Student
                    </p>
                    <p className="text_primary font_12">
                      Looking to apply to projects.
                    </p>
                  </div>
                </div>

                <div>
                  <Radio
                    checked={state?.accountType === AccountType.STUDENT}
                    value={AccountType.STUDENT}
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-3 cursor-pointer"
                onClick={() => handleChangeAccountType(AccountType.BUSINESS)}
              >
                <div className="d-flex align-items-center">
                  <img src={Images.Bag} />
                  <div className="ms-4">
                    <p className="font_18 fw-bold text_primary">
                      I’m a Business
                    </p>
                    <p className="text_primary font_12">
                      Looking to post projects.
                    </p>
                  </div>
                </div>

                <div>
                  <Radio
                    checked={state?.accountType === AccountType.BUSINESS}
                    value={AccountType.BUSINESS}
                  />
                </div>
              </div>
              {state?.errors?.accountType && (
                <ErrorMessage message={state?.errors?.accountType} />
              )}
            </>
          )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.SCHOOL && (
              <div>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-3">School Selection</h3>
                <p className="font_18 text_primary mb-4">
                  What university do you attend?
                </p>

                <div className="mt-4 mb-3">
                  <Autocomplete
                    value={state?.university}
                    onChange={(event: any, newValue: string | null) => {
                      handleChangeUniversity(newValue || "");
                    }}
                    options={USAUniversities}
                    renderInput={(params: any) => (
                      <CustomTextField
                        sx={[
                          {
                            "&:hover .MuiAutocomplete-clearIndicator": {
                              visibility: state?.university
                                ? "visible"
                                : "hidden",
                            },
                          },
                        ]}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          required: true,
                          error: state?.errors?.university,
                        }}
                      />
                    )}
                  />

                  {state?.errors?.university && (
                    <ErrorMessage message={state?.errors?.university} />
                  )}
                </div>
              </div>
            )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.OPPORTUNITY_TYPE && (
              <div>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <p className="font_18 text_primary mb-4">
                  What type of opportunities are you looking for?
                </p>

                {StudentTypes.filter((item, index) => index !== 1).map(
                  (profileType) => (
                    <div
                      key={profileType?.title}
                      className="d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-3 cursor-pointer"
                      onClick={() =>
                        handleChangeProfileType(profileType?.value)
                      }
                    >
                      <img src={profileType?.icon} className="w-10 h-8" />

                      <div className="w-full flex justify-between items-center">
                        <div className="px-4">
                          <div
                            className={`${
                              state?.profileType === profileType?.value
                                ? "text-rw-blue"
                                : ""
                            } font_18 fw-bold text_primary`}
                          >
                            {profileType?.title}
                          </div>

                          <div className="text_primary font_12">
                            {profileType?.description}
                          </div>
                        </div>

                        <div>
                          <Checkbox
                            checked={state.profileTypes.includes(
                              profileType?.value
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}

                {state?.errors?.profileTypes && (
                  <ErrorMessage message={state?.errors?.profileTypes} />
                )}
              </div>
            )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.LOGIN && (
              <div>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3
                  className={`fw-bold text_primary ${
                    isContinueWithGoogle ? "mb-2" : "mb-4"
                  } pb-3`}
                >
                  {isContinueWithGoogle ? "Notifications" : "Login Details"}
                </h3>
                {!isContinueWithGoogle && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Name</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                      <TextField
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={state?.firstName}
                        onChange={handleChange}
                        error={state?.errors?.firstName}
                        className="me-0 me-md-1 form-control"
                      />
                      <TextField
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={state?.lastName}
                        onChange={handleChange}
                        error={state?.errors?.lastName}
                        className="ms-0 ms-md-1 form-control"
                      />
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Phone Number</p>
                </div>
                <TextField
                  type="number"
                  name="phone"
                  placeholder="(000) 000-0000"
                  value={state?.phone}
                  onChange={handleChange}
                  error={state?.errors?.phone}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (event?.target?.value) {
                      event.target.value = Math.max(
                        0,
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                />

                <div className="mt-4">
                  <div
                    className="flex items-center form-check radio_fill pl-0 cursor-pointer"
                    onClick={() =>
                      handleChangeSmsOptIn(SMS_UPDATES_CONSENT.YES)
                    }
                  >
                    <div>
                      <Radio
                        checked={state?.smsOptIn === SMS_UPDATES_CONSENT.YES}
                        value={SMS_UPDATES_CONSENT.YES}
                      />
                    </div>
                    <label className="form-check-label">
                      By selecting this box, you agree to recieve SMS updates on
                      new projects.
                      <strong className="text_primary">
                        This is the only way you will be updated on
                        opportunities.
                      </strong>
                    </label>
                  </div>

                  <div
                    className="flex items-center gap-2 mt-2 form-check radio_fill pl-0 cursor-pointer"
                    onClick={() => handleChangeSmsOptIn(SMS_UPDATES_CONSENT.NO)}
                  >
                    <div>
                      <Radio
                        checked={state?.smsOptIn === SMS_UPDATES_CONSENT.NO}
                        value={SMS_UPDATES_CONSENT.NO}
                      />
                    </div>

                    <label className="form-check-label">
                      Opt-out of SMS Project Reminders
                    </label>
                  </div>
                </div>
              </div>
            )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.PROFILE && (
              <div className="w-full mb-2">
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-2">Profile Details</h3>
                <p className="font_18 text_black mb-2 mt-4">
                  Upload Profile Pic
                </p>
                {state?.avatarBase64 ? (
                  <div className="flex justify-center mb-12">
                    <div className="bg-rw-blue rounded-full w-24 h-24 flex justify-center items-center relative">
                      <span className="text-white text-xl font-semibold">
                        <img
                          className="rounded-full object-cover"
                          src={state?.avatarBase64}
                        />
                      </span>

                      <button
                        className="absolute bottom-0 right-0 bg-white rounded-full flex justify-center items-center text-rw-blue w-6 h-6 border-[1px] border-rw-blue"
                        onClick={handleAvatarRef}
                      >
                        <EditOutlinedIcon sx={{ width: 14, height: 14 }} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="border border_gray p-5 text-center border_r_8px cursor-pointer"
                      onClick={handleAvatarRef}
                    >
                      <label className="cursor-pointer">
                        <p className="fs-4 text_light_primary">
                          <i className="fa-light fa-cloud-arrow-up" />
                        </p>
                        <p className="text_light_primary fw-bold fs-6">
                          UPLOAD IMAGE
                        </p>
                        <p className="text_light_primary font_10">
                          Drag and drop files here
                        </p>
                      </label>
                    </div>
                    {state?.errors?.avatarFile && (
                      <ErrorMessage message={state?.errors?.avatarFile} />
                    )}
                  </>
                )}
                <input
                  className="hidden"
                  type="file"
                  accept="image/*"
                  ref={avatarRef}
                  onChange={handleAvatarChange}
                />

                <p className="font_18 text_black mb-2 mt-4">Upload Resume</p>
                <div
                  className="border border_gray p-5 text-center border_r_8px cursor-pointer"
                  onClick={handleResumeRef}
                >
                  <input
                    type="file"
                    accept="application/pdf"
                    id="file"
                    ref={resumeRef}
                    onChange={handleResumeChange}
                  />
                  <label className="cursor-pointer">
                    <p className="fs-4 text_light_primary">
                      <i className="fa-light fa-cloud-arrow-up" />
                    </p>
                    <p className="text_light_primary fw-bold fs-6">
                      UPLOAD PDF FILE
                    </p>
                    <p className="text_light_primary font_10">
                      Drag and drop files here
                    </p>
                  </label>
                </div>
                {state?.errors?.resumeFile && (
                  <ErrorMessage message={state?.errors?.resumeFile} />
                )}

                {state?.resumeFile && (
                  <div className="flex justify-between items-center p-2 bg-[#E3E4E7] rounded-md mt-4">
                    <span className="text-sm font-medium text-rw-gray-75 word-break">
                      {state?.resumeFile?.name}
                    </span>

                    <span
                      className="text-rw-red-dark cursor-pointer"
                      onClick={handleRemoveResume}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </span>
                  </div>
                )}

                <p className="font_18 text_black mb-2 mt-4">
                  Website
                  <span className="text_light_primary">(Optional)</span>
                </p>
                <TextField
                  type="text"
                  name="website"
                  placeholder="www.mywebsite.com"
                  value={state?.website}
                  onChange={handleChange}
                  error={state?.errors?.website}
                />

                <p className="font_18 text_black mb-2 mt-4">
                  Expected Graduation Year
                </p>
                <TextField
                  type="number"
                  name="expectedYear"
                  placeholder="Expected Graduation Year"
                  value={state?.expectedYear}
                  onChange={handleChange}
                  error={state?.errors?.expectedYear}
                />
                <p className="font_18 text_black mb-2 mt-4">Short Bio</p>
                {/* <div className="file_editor"> */}
                <RichTextEditorField
                  value={state?.bio}
                  placeholder="Write a short bio that briefly explains your previous experience..."
                  onChange={handleChangeBio}
                  error={state?.errors?.bio}
                />
                {/* </div> */}

                <div className="mt-8">
                  <p className="font_18 text_black mb-2 mt-4">Skillsets</p>

                  <TextField
                    type="text"
                    name="keySkills"
                    placeholder="Enter key skillsets for you project"
                    value={state?.keySkills}
                    onChange={handleChangeKeySkills}
                    error={state?.errors?.keySkills}
                  />

                  <div className="doted_tags">
                    {state?.skills?.map(
                      (skill: string) =>
                        skill && (
                          // <div
                          //   key={skill}
                          //   className="w-fit bg-rw-blue text-white text-sm font-medium p-2 rounded-md flex justify-center items-center"
                          // >
                          //   {skill}
                          // </div>
                          <span>
                            {skill}
                            <span className="ms-3">
                              <i
                                className="fa-light fa-xmark"
                                onClick={() => handleRemoveSkills(skill)}
                              />
                            </span>
                          </span>
                        )
                    )}
                  </div>
                  <span className="text-[#5E5E5E] font-medium text-xs">
                    Seperate skills by a comma (eg: FIGMA, HUBSPOT, REACT.JS)
                  </span>
                </div>
              </div>
            )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.SELF_IDENTITY && (
              <>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-3">Self Identity</h3>
                <div className="mt-2">
                  <label className="mb-2">What is your race/ethnicity?</label>
                  <select
                    name="race"
                    className={`select_option_style placeholder:text-[#484848] ${
                      !state?.errors?.race ? "mb-3" : ""
                    }`}
                    value={state?.race}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select one
                    </option>
                    {RaceOptions.map((race: any) => (
                      <option key={race} value={race}>
                        {race}
                      </option>
                    ))}
                  </select>
                  {state?.errors?.race && (
                    <ErrorMessage message={state?.errors?.race} />
                  )}
                </div>
                <div className="mt-2">
                  <label className="my-2">What is your gender?</label>
                  <select
                    className={`select_option_style ${
                      !state?.errors?.gender ? "mb-3" : ""
                    }`}
                    name="gender"
                    value={state?.gender}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select one
                    </option>
                    {GenderOptions.map((gender: any) => (
                      <option key={gender} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </select>
                  {state?.errors?.gender && (
                    <ErrorMessage message={state?.errors?.gender} />
                  )}
                </div>
                <div className="mt-2">
                  <label className="my-2">
                    Are you an international student?
                  </label>
                  <select
                    className={`select_option_style ${
                      !state?.errors?.internationalStudent ? "mb-3" : ""
                    }`}
                    name="internationalStudent"
                    value={state?.internationalStudent}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select one
                    </option>
                    {internationalStudentList.map((li: any) => (
                      <option key={li} value={li}>
                        {li}
                      </option>
                    ))}
                  </select>
                  {state?.errors?.internationalStudent && (
                    <ErrorMessage
                      message={state?.errors?.internationalStudent}
                    />
                  )}
                </div>
              </>
            )}
          {isStudentAccount &&
            state?.currentStep?.label === StepLabels.CONFIRM && (
              <>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-4">Confirm Account</h3>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Account Type</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.OPPORTUNITY_TYPE)}
                  >
                    Edit
                  </div>
                </div>

                {state.profileTypes.map((type: any) => {
                  const profileType = StudentTypes.find(
                    (item: any) => item.value === type
                  );
                  return (
                    <div className="d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-4">
                      <div className="d-flex align-items-center">
                        <img src={profileType?.icon} className="w-10 h-8" />
                        <div className="ms-4">
                          <p className="font_18 fw-bold text_primary">
                            {profileType?.title}
                          </p>
                          <p className="text_primary font_12">
                            {profileType?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {!isContinueWithGoogle && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Name</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.LOGIN)}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <input
                        type="text"
                        className="form-control input_style mb-3 m3-2"
                        placeholder=""
                        value={state?.firstName}
                        disabled
                      />
                      <input
                        type="text"
                        className="form-control input_style mb-3 ms-2"
                        placeholder=""
                        value={state?.lastName}
                        disabled
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Email</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.EMAIL)}
                      >
                        Edit
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control input_style mb-3 m3-2"
                      placeholder=""
                      value={state?.email}
                      disabled
                    />

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Password</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.EMAIL)}
                      >
                        Edit
                      </div>
                    </div>
                    <input
                      type="password"
                      className="form-control input_style mb-3 m3-2"
                      placeholder=""
                      value={state?.password}
                      disabled
                    />
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Phone Number</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control input_style mb-3"
                  placeholder=""
                  value={state?.phone}
                  disabled
                />

                <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
                  <p className="font_18 text_black">Image</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.PROFILE)}
                  >
                    Edit
                  </div>
                </div>
                <div className="border border_gray p-5 text-center border_r_8px mb-4">
                  <div>
                    <p className="fs-4 text_primary">
                      <i className="fa-sharp fa-light fa-circle-check" />
                    </p>
                    <p className="text_primary fw-bold fs-6">Image Uploaded</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Resume</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.PROFILE)}
                  >
                    Edit
                  </div>
                </div>
                <div className="border border_gray p-5 text-center border_r_8px mb-4">
                  <div>
                    <p className="fs-4 text_primary">
                      <i className="fa-sharp fa-light fa-circle-check" />
                    </p>
                    <p className="text_primary fw-bold fs-6">File Uploaded</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Website</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.PROFILE)}
                  >
                    Edit
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control input_style mb-3"
                  placeholder=""
                  value={state?.website}
                  disabled
                />

                <p className="font_18 text_black mb-2 mt-4">Short Bio</p>
                {/* <div className="file_editor"> */}
                {/* <textarea className="text_editor" placeholder="">
                  {state?.bio}
                </textarea> */}
                <div
                  className="border-[1px] border-[#00000040] file_editor rounded-md mt-2 p-4 text-sm font-medium h-40 overflow-y-scroll"
                  dangerouslySetInnerHTML={{
                    __html: wrapHTMLContent(state?.bio),
                  }}
                />
                {/* </div> */}

                <p className="font_18 text_black mb-2 mt-4">Skillsets</p>
                <div className="doted_tags active">
                  {state?.skills?.map(
                    (skill: string) =>
                      skill && (
                        <span>
                          {skill}
                          <span className="ms-3">
                            <i
                              className="fa-light fa-xmark"
                              onClick={() => handleRemoveSkills(skill)}
                            />
                          </span>
                        </span>
                      )
                  )}
                </div>
                <div className="mt-2">
                  <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
                    <label>What is your race/ethnicity?</label>
                    <div
                      className="font_18 text_secondary cursor_pointer"
                      onClick={() => handleEditStep(StepLabels.SELF_IDENTITY)}
                    >
                      Edit
                    </div>
                  </div>
                  <select
                    disabled
                    name="race"
                    className={`select_option_style placeholder:text-[#484848] ${
                      !state?.errors?.race ? "mb-3" : ""
                    }`}
                  >
                    <option>{state?.race}</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label className="my-2">What is your gender?</label>
                  <select
                    disabled
                    className={`select_option_style ${
                      !state?.errors?.gender ? "mb-3" : ""
                    }`}
                    name="gender"
                  >
                    <option>{state?.gender}</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label className="my-2">
                    Are you an international student?
                  </label>
                  <select
                    disabled
                    className={`select_option_style ${
                      !state?.errors?.internationalStudent ? "mb-3" : ""
                    }`}
                    name="internationalStudent"
                  >
                    <option>{state?.internationalStudent}</option>
                  </select>
                </div>
              </>
            )}
          {!isStudentAccount &&
            state?.currentStep?.label === StepLabels.LOGIN && (
              <div>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-2">Login Details</h3>
                {!isContinueWithGoogle && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Name</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                      <TextField
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={state?.firstName}
                        onChange={handleChange}
                        error={state?.errors?.firstName}
                        className="me-0 me-md-1"
                      />
                      <TextField
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={state?.lastName}
                        onChange={handleChange}
                        error={state?.errors?.lastName}
                        className="ms-0 ms-md-1"
                      />
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Company Name</p>
                </div>
                <TextField
                  type="text"
                  name="companyName"
                  placeholder="Name of your company"
                  value={state?.companyName}
                  onChange={handleChange}
                  error={state?.errors?.companyName}
                />
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Phone Number</p>
                </div>
                <TextField
                  type="number"
                  name="phone"
                  placeholder="(000) 000-0000"
                  value={state?.phone}
                  onChange={handleChange}
                  error={state?.errors?.phone}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (event?.target?.value) {
                      event.target.value = Math.max(
                        0,
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                />
              </div>
            )}
          {!isStudentAccount &&
            state?.currentStep?.label === StepLabels.CONFIRM && (
              <>
                {isContinueWithGoogle && (
                  <UserInfoComp
                    name={state.profile?.name}
                    email={state.profile?.email}
                  />
                )}
                <h3 className="fw-bold text_primary mb-4">Confirm Account</h3>

                {!isContinueWithGoogle && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Name</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.LOGIN)}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <input
                        type="text"
                        className="form-control input_style mb-3 m3-2"
                        placeholder=""
                        value={state?.firstName}
                        disabled
                      />
                      <input
                        type="text"
                        className="form-control input_style mb-3 ms-2"
                        placeholder=""
                        value={state?.lastName}
                        disabled
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Email</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.EMAIL)}
                      >
                        Edit
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control input_style mb-3 m3-2"
                      placeholder=""
                      value={state?.email}
                      disabled
                    />
                  </>
                )}

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Company Name</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control input_style mb-3 m3-2"
                  placeholder=""
                  value={state?.companyName}
                  disabled
                />

                {!isContinueWithGoogle && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_18 text_black">Password</p>
                      <div
                        className="font_18 text_secondary cursor_pointer"
                        onClick={() => handleEditStep(StepLabels.EMAIL)}
                      >
                        Edit
                      </div>
                    </div>
                    <input
                      type="password"
                      className="form-control input_style mb-3 m3-2"
                      placeholder=""
                      value={state?.password}
                      disabled
                    />
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_18 text_black">Phone Number</p>
                  <div
                    className="font_18 text_secondary cursor_pointer"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control input_style mb-3"
                  placeholder=""
                  value={state?.phone}
                  disabled
                />
              </>
            )}
          {state?.currentStep?.label !== StepLabels.EMAIL && (
            <>
              {state?.currentStep?.label === StepLabels.CONFIRM ? (
                <button
                  disabled={state.isDisableBtn}
                  type="button"
                  className={`btn bg_secondary w-100 mb-3 ${
                    state.isDisableBtn ? "bg-rw-disable" : ""
                  }`}
                  onClick={handleStudentSignup}
                >
                  Confirm
                </button>
              ) : (
                <button
                  type="button"
                  className="btn bg_secondary w-100 mb-3"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              )}
              <button
                type="button"
                className="btn bg_light_secondary text_secondary w-100"
                onClick={handleBack}
              >
                Back
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="bg_white p-4 p-md-5 border_r_8px">
          <h3 className="fw-bold text_primary mb-4 text-center">
            Confirm Your Email
          </h3>
          <p className="font_18 text_primary text-center mb-3">
            A confirmation email has been sent to the email address you used to
            sign up.
          </p>
          <p className="font_18 text_primary text-center mb-4">
            Please check your email to confirm your account.
          </p>

          <button
            type="button"
            className="btn bg_secondary w-100 mb-3 mt-3"
            onClick={() => navigate("/signin")}
          >
            Login
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            onClick={() => navigate("/signin")}
          >
            Back to Home
          </button>
        </div>
      )}
    </div>
  );
}
