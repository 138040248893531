import React from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "utils/custom-hooks";

type props = {
  title: string;
  taskDescription: string;
  img: string;
  link: string;
  changeWidth?: boolean;
};

const AssessmentTask = ({
  title,
  taskDescription,
  img,
  link,
  changeWidth = false,
}: props) => {
  return (
    <Link
      className="col-md-4"
      style={{
        width:
          useWindowSize().width < 700 ? "98%" : changeWidth ? "49%" : "32.5%",
        // marginRight: 20,
      }}
      to={link}
    >
      <div className="box_style me-2">
        <div>
          <p className="text_black font_12">{taskDescription}</p>
          <p
            className="text_primary fs-6 fw-bold"
            style={{
              fontSize: 16,
              fontWeight: "bold",
              fontFamily: "system-ui",
            }}
          >
            {title}
          </p>
        </div>
        <div className="icon_size_sm">
          <img src={img} />
        </div>
      </div>
    </Link>
  );
};
export default AssessmentTask;
