import React from "react";
import hourLogo from "../../../../assets/imgs/hour_icon.svg";
import handLogo from "../../../../assets/imgs/handshake.svg";
import calendarLogo from "../../../../assets/imgs/calendar_icon.svg";

const ProjectStartedTips = () => {
  return (
    <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 border_sm_r_0 mt-3 p-4">
      <p className="fs-3 text-center fw-bold text_primary">Project Started!</p>
      <p className="text-center">
        Here are some tips for a successful project.
      </p>

      <div className="row mt-4 px-3 px-md-0">
        <div className="col-md-12 col-lg-4">
          <div className="project_box mb-4 mb-lg-0">
            <div className="img_icon">
              <img src={hourLogo} />
            </div>
            <p className="fw-bold text_black font_18 text-center">
              Limit the number of hours your student can work during their first
              2 weeks to gauge their productivity.
            </p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="project_box mb-4 mb-lg-0">
            <div className="img_icon">
              <img src={handLogo} />
            </div>
            <p className="fw-bold text_black font_18 text-center">
              Create a welcoming environment for your student to answer any
              questions.
            </p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="project_box mb-4 mb-lg-0">
            <div className="img_icon">
              <img src={calendarLogo} />
            </div>
            <p className="fw-bold text_black font_18 text-center">
              Setup weekly touchpoints with your student to assess progress and
              give feedback
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStartedTips;
