import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { offsetDates } from "utils/common";
import { InvoiceStatus } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import { formatDate } from "utils/date";

const paymentTable = [
  {
    paymentStatus: 0,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 1,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 2,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 2,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
];
const statusClass = [
  "btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm",
  "btn btn_small bg_lightorange text-uppercase text_orange px-3 py-2 lh-sm",
  "btn btn_small bg_light_green text-uppercase text_green px-3 py-2 lh-sm",
];

const buttonClass = [
  "btn btn_small bg_secondary px-3 py-2 lh-sm text-uppercase",
  "btn btn_small bg_light_secondary text_secondary text-uppercase px-3 py-2 lh-sm",
];
type Props = {
  invoice: any;
  totalEarning: number;
  hourlyRate: number;
  hoursPerWeek: number;
  isStudent: boolean;
  invoicesList: any;
  goToInvoiceView: (weekstart: string) => any;
  getTimeSheetHours: any;
  getHourlyRateOfTimeSheet: any;
  onCalculateTotalAmountOfInvoice: any;
};

const PayoutTable = ({
  invoice,
  totalEarning,
  hourlyRate,
  hoursPerWeek,
  isStudent,
  invoicesList,
  goToInvoiceView,
  getTimeSheetHours,
  getHourlyRateOfTimeSheet,
  onCalculateTotalAmountOfInvoice,
}: Props) => {
  const getInvoiceProcessingFees = (amount: number) =>
    +Number(amount * 0.03).toFixed(2);

  return (
    <div>
      <div className="table-responsive payout_table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="ps-4">
                Status
              </th>
              <th scope="col">Date Issued</th>
              <th scope="col">Date Due</th>
              <th scope="col">Hours</th>
              <th scope="col">Amount Due</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {state?.payout.map((data: any, index: number) => (
              <tr key={index}>
                <td className="ps-4">
                  <button
                    type="button"
                    className={statusClass[data.paymentStatus]}
                  >
                    {data.paymentStatus === 0
                      ? "UNPAID"
                      : data.paymentStatus === 1
                      ? "PENDING"
                      : "PAID"}
                  </button>
                </td>
                <td className="align-middle">{data.date}</td>
                <td className="align-middle">{data.due}</td>
                <td className="text_secondary align-middle">
                  ${hourlyRate} x {hoursPerWeek}hrs
                </td>
                <td className="align-middle text_redish">
                  ${hourlyRate * hoursPerWeek} Due
                </td>
                <td>
                  <button
                    type="button"
                    className={
                      data.paymentStatus === 2 ? buttonClass[1] : buttonClass[0]
                    }
                    onClick={() => data.action()}
                  >
                    {!isStudent
                      ? data.paymentStatus === 2
                        ? "view invoice"
                        : "Pay Invoice"
                      : "STRIPE LOGIN"}
                  </button>
                </td>
              </tr>
            ))} */}
            {invoicesList.map((invoice: any, index: string) => (
              <tr key={index}>
                <td className="ps-4">
                  <button
                    type="button"
                    className={
                      invoice.status === InvoiceStatus.PENDING
                        ? statusClass[1]
                        : invoice.status === InvoiceStatus.PAID
                        ? statusClass[2]
                        : statusClass[0]
                    }
                  >
                    {invoice.status}
                  </button>
                </td>
                <td className="align-middle">
                  {" "}
                  {formatDate(invoice?.weekStart, "MM-DD-YYYY")}
                </td>
                <td className="align-middle">
                  {formatDate(offsetDates(invoice?.due, 0), "MM-DD-YYYY")}
                </td>
                <td className="text_secondary align-middle">
                  {invoice.timesheets.map((item: any, index: any) =>
                    getHourlyRateOfTimeSheet(item) ? (
                      <React.Fragment key={index}>
                        ${getHourlyRateOfTimeSheet(item).toFixed(2)} x{" "}
                        {getTimeSheetHours(item)}
                        hrs
                        {index + 1 !== invoice.timesheets.length &&
                          !isStudent && (
                            <div className="w-6/12 text-rw-blue text-center font-semibold">
                              +
                            </div>
                          )}
                      </React.Fragment>
                    ) : null
                  )}
                </td>
                <td
                  className={`align-middle ${
                    invoice.status === InvoiceStatus.PENDING
                      ? "text_orange"
                      : invoice.status === InvoiceStatus.PAID
                      ? "text_green"
                      : "text_redish"
                  } `}
                >
                  $
                  {Number(onCalculateTotalAmountOfInvoice(invoice)) +
                    (isStudent
                      ? 0
                      : getInvoiceProcessingFees(
                          onCalculateTotalAmountOfInvoice(invoice)
                        ))}{" "}
                  {invoice.status === InvoiceStatus.PENDING
                    ? "Pending"
                    : invoice.status === InvoiceStatus.PAID
                    ? "Paid"
                    : "Due"}
                </td>
                <td>
                  <button
                    type="button"
                    className={
                      invoice.status === InvoiceStatus.PENDING
                        ? buttonClass[0]
                        : buttonClass[1]
                    }
                    onClick={() => goToInvoiceView(invoice.weekStart)}
                  >
                    {!isStudent
                      ? invoice.status === InvoiceStatus.PENDING
                        ? "Pay Invoice"
                        : "view invoice"
                      : "STRIPE LOGIN"}
                  </button>
                </td>
              </tr>
            ))}
            {isStudent && (
              <tr className="total_hours">
                <td colSpan={6} className="text-center fw-bold">
                  Total: ${totalEarning}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <div className="table-responsive payout_table"> 
      <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="ps-4">
                Status
              </th>
              <th scope="col">Date Issued</th>
              <th scope="col">Date Due</th>
              <th scope="col">Hours</th>
              <th scope="col">Amount Due</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="ps-4">
                <button
                  type="button"
                  className="btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm"
                >
                  Unpaid
                </button>
              </td>
              <td className="align-middle">06-28-2023</td>
              <td className="align-middle">06-06-2023</td>
              <td className="text_secondary align-middle">$35 x 10hrs</td>
              <td className="align-middle">$360.50</td>
              <td>
                <button
                  type="button"
                  className="btn btn_small bg_secondary px-3 py-2 lh-sm"
                >
                  STRIPE LOGIN
                </button>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <button
                  type="button"
                  className="btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm"
                >
                  Unpaid
                </button>
              </td>
              <td className="align-middle">06-28-2023</td>
              <td className="align-middle">06-06-2023</td>
              <td className="text_secondary align-middle">$35 x 10hrs</td>
              <td className="align-middle">$360.50</td>
              <td>
                <button
                  type="button"
                  className="btn btn_small bg_secondary px-3 py-2 lh-sm"
                >
                  STRIPE LOGIN
                </button>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <button
                  type="button"
                  className="btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm"
                >
                  Unpaid
                </button>
              </td>
              <td className="align-middle">06-28-2023</td>
              <td className="align-middle">06-06-2023</td>
              <td className="text_secondary align-middle">$35 x 10hrs</td>
              <td className="align-middle">$360.50</td>
              <td>
                <button
                  type="button"
                  className="btn btn_small bg_secondary px-3 py-2 lh-sm"
                >
                  STRIPE LOGIN
                </button>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <button
                  type="button"
                  className="btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm"
                >
                  Unpaid
                </button>
              </td>
              <td className="align-middle">06-28-2023</td>
              <td className="align-middle">06-06-2023</td>
              <td className="text_secondary align-middle">$35 x 10hrs</td>
              <td className="align-middle">$360.50</td>
              <td>
                <button
                  type="button"
                  className="btn btn_small bg_secondary px-3 py-2 lh-sm"
                >
                  STRIPE LOGIN
                </button>
              </td>
            </tr>
            <tr className="total_hours">
              <td className="text-center fw-bold">Total: $1234.56</td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default PayoutTable;
