import React, { useEffect } from "react";
import ProjectCard from "../ProjectCard";
import useEmblaCarousel from "embla-carousel-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import { ProjectStatus } from "utils/constants";

const TitleLogo = ({
  title,
  tittleText,
}: {
  title: number;
  tittleText: string;
}) => {
  const textSize = useWindowSize().width < 460 ? 16 : 24;
  return title === 0 ? (
    <h4
      className="fs-4 fw_500 text_black mb-3"
      style={{
        fontSize: textSize,
        fontFamily: "system-ui",
      }}
    >
      <span className="refresh_icon">
        <i className="far fa-redo-alt" aria-hidden="true" />
      </span>
      {"  "}
      {tittleText}{" "}
    </h4>
  ) : title === 1 ? (
    <h4
      className="fs-4 fw_500 text_black mb-3"
      style={{
        fontSize: textSize,
        fontFamily: "system-ui",
      }}
    >
      <span className={`${"text_orange"} me-3`}>
        <i className="fal fa-clock" aria-hidden="true" />
      </span>
      {tittleText}{" "}
    </h4>
  ) : (
    <h4
      className="fs-4 fw_500 text_black mb-3"
      style={{
        fontSize: textSize,
        fontFamily: "system-ui",
      }}
    >
      <span className={`${title < 6 ? "text_secondary" : "text_redish"} me-3`}>
        {title < 6 ? (
          <i className="fal fa-clock" aria-hidden="true" />
        ) : (
          <CheckIcon style={{ width: 30, height: 30 }} />
        )}
        {/* <CheckIcon style={{ width: 30, height: 30 }} /> */}
      </span>
      {tittleText}{" "}
    </h4>
  );
};

const Carousel = ({
  list,
  title,
  tittleText,
  isStudent,
  link = "/projects/details?",
}: {
  list: any;
  title: number;
  tittleText: string;
  isStudent: boolean;
  link?: string;
}) => {
  const [state, setState] = useMergeState({
    isEmpty: false,
  });
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    slidesToScroll: 1,
  });

  // const isAllInactive = () => {
  //   let isEmpty = true;
  //   const res = list.map((data: any) => {
  //     if (data.projectStatus !== ProjectStatus.IN_ACTIVE) {
  //       isEmpty = false;
  //     }
  //   });
  //   setState({ isEmpty });
  // };

  // useEffect(() => {
  //   isAllInactive();
  // }, []);

  return (
    <div className="bg_white p-3 p-md-4 border_r_16px mt-4 border border_gray embla carouselMainDiv ">
      <div className="carouselTopDiv">
        <TitleLogo title={title} tittleText={tittleText} />
        <div>
          <button className="owl-prev" onClick={() => emblaApi?.scrollPrev()}>
            <ChevronLeftIcon
              className="carouselArrow"
              sx={{ width: 40, height: 40 }}
              style={{ marginRight: -10 }}
            />
          </button>
          <button type="button" onClick={() => emblaApi?.scrollNext()}>
            <ChevronRightIcon
              className="carouselArrow"
              sx={{ width: 40, height: 40 }}
            />
          </button>
        </div>
      </div>
      <div
        className="embla__viewport"
        style={{ overflow: "hidden" }}
        ref={emblaRef}
      >
        <div className="embla__container">
          {list.map((data: any, index: any) => (
            <div className="embla__slide" key={index}>
              <ProjectCard
                projectName={data.projectName}
                description={data.description}
                skillList={data.skillList}
                projectStatus={title}
                hourlyRate={title === 1 ? undefined : data.hourlyRate}
                updatedAt={data.updatedAt}
                isStudent={isStudent}
                link={`${link}id=${data.id}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
