import React from "react";
import { useMergeState } from "utils/custom-hooks";
import ProjectDetails from "../ProjectDetails";
import ProjectStatusComp from "../ProjectStatus";
import { updateStudentProject } from "api";
import { StudentProjectIntent, AccountType } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

type Props = {
  description: string;
  responsibilties: string[];
  qualifications: string[];
  skills: string[];
  projectId: string;
  jobDocumentUrl?: string;
  title: string;
  type: string;
  duration: number;
  hourlyRate: number;
  lastUpdate?: string;
};

const AppliedProject = ({
  description,
  responsibilties,
  qualifications,
  skills,
  projectId,
  jobDocumentUrl = "",
  title,
  type,
  duration = 0,
  hourlyRate = 0,
  lastUpdate,
}: Props) => {
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    applied: true,
  });

  const handleUpdateStudentProjectStatus = async (
    intent: string,
    projectId: string
  ) => {
    try {
      const response = await updateStudentProject({ id: projectId, intent });
      if (response?.success) {
        enqueueSnackbar("project UnApplied.", { variant: "success" });
        setState({
          applied: !state?.applied,
        });
        navigation("/projects");
      }
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  return (
    <div className="bg_light w-100 overflow-hidden mb-4">
      <div className="p-4 bg-white w-100 border border_gray border_r_16px">
        <ProjectStatusComp
          type={0}
          buttonTitle={`${state?.applied ? "UnApply" : "Apply"}`}
          buttonOnClick={() => {
            handleUpdateStudentProjectStatus(
              state?.applied
                ? StudentProjectIntent.UN_APPLY
                : StudentProjectIntent.APPLY,
              projectId
            );
          }}
          jobDocumentUrl={jobDocumentUrl}
          title={title}
          titleType={type}
          duration={duration}
          hourlyRate={hourlyRate}
          lastUpdate={lastUpdate}
        />
        <div className="border-bottom box_shadow w-100 my-4" />
        <ProjectDetails
          description={description}
          responsibilties={responsibilties}
          qualifications={qualifications}
          skills={skills}
        />
      </div>
    </div>
  );
};

export default AppliedProject;
