import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Modal from "react-bootstrap/Modal";
import chromeLogo from "../../assets/imgs/chrome.svg";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
// import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import TestMicrophone from "components/TestMicrophone";
import AssessmentOnboarding from "containers/dashboard/AssessmentOnboarding";
import Images from "assets/images";
import {
  getSkills,
  generateQuestions,
  generateAIResult,
  getCurrentUser,
} from "api";

type Props = {
  user: any;
  manuBarExpend: boolean;
  setUser: any;
  manuBarToggle: () => void;
};

// const demoQuestion: any = {
//   DEVELOPER: "What is the use of HTTP cookies?",
//   ENGINEER: "Describe what bearings are?",
//   BUSINESS: "Difference betweem marketing and sales?",
// };

const progressbarHeadings: any = {
  0: "DEFINE SKILLSET",
  1: "Demo Question",
  2: "Question",
  3: "Loading Results",
};

const SkillAssessment = ({
  user,
  manuBarExpend,
  setUser,
  manuBarToggle,
}: Props) => {
  const location = useLocation();
  const fromSignupPage = location.state && location.state?.fromSignupPage;

  // const [showModal, setShowModal] = useState(false);
  // const [chunks, setChunks]: any = useState([]);
  // const [recorder, setRecorder]: any = useState();
  const currentWidth = useWindowSize().width;
  const [state, setState] = useMergeState({
    assessmentState: fromSignupPage ? -2 : -1,
    skillSet: [{ id: 11, skill: "" }],
    showInfoModal: false,
    showAudioTestModal: false,
    showDemoQuestionModal: false,
    showRealQuestionModal: false,
    audioFrequency: 0,
    deskTopMode: true,
    skillsList: [],
    isDisableBtn: false,
    questions: [],
    currentQuestionIndex: 0,
    isStartRecording: false,
    isDemoQuestionPerformed: false,
    isPlaying: false,
    timeInterval: 0,
  });

  const realQuestions = useRef<any>([]);
  const recordedAudio = useRef<any>(null);
  const audioInstance = useRef<any>(new Audio());
  const timeIntervalRef = useRef<any>(null);
  const isDisbaleRecordingBtn = useRef<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isChrome = !!window.chrome;

  // const {
  //   startRecording,
  //   stopRecording,
  //   // togglePauseResume,
  //   recordingBlob,
  //   isRecording,
  //   // isPaused,
  //   recordingTime,
  //   // mediaRecorder,
  // } = useAudioRecorder();

  const increaseAssessmentState = async () => {
    const temp = state?.assessmentState;
    if (state.assessmentState === -1) {
      setState({ isDisableBtn: true });
      const title = user.profileTypes
        .map((item: any) => item.toLowerCase())
        .join(",");
      const response = await getSkills({ title });
      if (response.success) {
        let skill_list = response.data.skills;
        let skillsData: any = [];
        const splitData = (data: any) =>
          data.split(/\d+/).map((item: any) => item.replace(/\./g, "").trim());
        if (skill_list.find((skill: any) => skill.includes("\n"))) {
          skill_list.map((item: any) => {
            skillsData = [...skillsData, ...splitData(item)];
          });
          skill_list = skillsData;
        }
        setState({
          skillsList: skill_list,
          isDisableBtn: false,
        });
      } else {
        setState({ isDisableBtn: false });
      }
    } else if (state.assessmentState === 0) {
      const selectedSkills = state.skillSet.map((item: any) => item.skill);
      setState({ isDisableBtn: true });
      generateQuestions({ skills: selectedSkills })
        .then((res) => {
          if (res.success) {
            const arr: any = [];
            Object.keys(res.data.questions).map((key: string) =>
              res.data.questions[key].map((ques: string) => {
                arr.push({
                  skill: key,
                  question: ques,
                });
              })
            );
            realQuestions.current = arr;
            setState({
              questions: res.data.questions,
              isDisableBtn: false,
            });
          }
        })
        .catch((err) => {
          setState({ isDisableBtn: false });
        });
    }
    setState({ assessmentState: temp + 1 });
  };

  const decreaseAssessmentState = () => {
    const temp = state?.assessmentState;
    setState({ assessmentState: temp - 1 });
  };

  const updateSkillSet = (index: number, skill: string) => {
    if (state?.skillSet.length === 1 && state?.skillSet[0].skill === "") {
      return setState({ skillSet: [{ id: index, skill }] });
    }
    const filteredSkillSet = state?.skillSet.filter(
      (item: any) => item.skill !== skill
    );
    if (filteredSkillSet.length === state.skillSet.length) {
      filteredSkillSet.push({ id: index, skill, level: "Low-Level" });
    }

    if (filteredSkillSet.length > 3) return;
    // const tempIndex = tempSkills.findIndex((op: any) => op.id === index);
    // if (tempIndex !== -1) {
    //   temp.splice(tempIndex, 1);
    // } else {
    //   temp.push({ id: index, level: 0 });
    // }
    setState({ skillSet: filteredSkillSet });
  };

  const handleChangeSkill = (value: string, index: number) => {
    const skillSetCopy = state.skillSet.slice();
    const findedIndex = state?.skillSet.findIndex(
      (item: any) => item.id === index
    );
    skillSetCopy[findedIndex] = { id: index, skill: value };

    setState({ skillSet: skillSetCopy });
  };

  // const playAudio = (audioBlob: any) => {
  //   if (recordingBlob) {
  //     audioInstance.current.src = URL.createObjectURL(recordingBlob);
  //     if (state.isPlaying) {
  //       setState({ isPlaying: false });
  //       audioInstance.current.pause();
  //     } else {
  //       setState({ isPlaying: true });
  //       audioInstance.current.play();
  //     }
  //   }
  // };

  const compressAudioFile = async (inputFile: any) => {
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();

    await ffmpeg.writeFile("input.wav", await fetchFile(inputFile));

    await ffmpeg.exec(["-i", "input.wav", "output.mp3"]);

    const data = await ffmpeg.readFile("output.mp3");

    // Create a Blob from the compressed file data
    const compressedAudioBlob = new Blob([data], { type: "audio/mp3" });

    // Create a File from the Blob (if you need a File object)
    const compressedAudioFile = new File(
      [compressedAudioBlob],
      "compressed_voice.mp3",
      {
        type: "audio/mp3",
      }
    );

    return compressedAudioFile;
  };

  const generateResult = async () => {
    const selectedSkills: any = state.skillSet.map((item: any) => item.skill);
    const testQuestions = realQuestions.current.map(
      (item: any) => item.question
    );

    const formData = new FormData(); // preparing to send to the server
    formData.append("userId", user._id);
    formData.append("testQuestions", JSON.stringify(testQuestions));
    formData.append("skills", JSON.stringify(selectedSkills));
    for (let i = 0; i < realQuestions.current.length; i++) {
      const file = realQuestions.current[i].audioFile;
      formData.append("recordings", file, "voice.wav");
    }
    try {
      const result = await generateAIResult(formData);
      if (result) {
        let userInfo: any = {};
        const userResponse = await getCurrentUser();
        if (userResponse?.success) {
          userInfo = userResponse?.data?.user;
          setUser(userInfo);
        }
        enqueueSnackbar("Your test has successfully completed.", {
          variant: "success",
        });
        navigate("/settings");
      } else {
        recordedAudio.current = null;
        isDisbaleRecordingBtn.current = false;
        setState({
          assessmentState: -1,
          skillsList: [],
          skillSet: [{ id: 11, skill: "" }],
          isDemoQuestionPerformed: false,
          timeInterval: 0,
          currentQuestionIndex: 0,
        });
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });
      }
    } catch (err: any) {
      recordedAudio.current = null;
      isDisbaleRecordingBtn.current = false;
      setState({
        assessmentState: -1,
        skillsList: [],
        skillSet: [{ id: 11, skill: "" }],
        isDemoQuestionPerformed: false,
        timeInterval: 0,
        currentQuestionIndex: 0,
      });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  };

  const handlerTimer = () => {
    let startingTime = 0;
    timeIntervalRef.current = setInterval(() => {
      setState({ timeInterval: startingTime });
      startingTime += 1;
    }, 1000);
  };

  const onNextQuestion = (audioFile: any) => {
    if (state.isDemoQuestionPerformed) {
      realQuestions.current[state.currentQuestionIndex].audioFile = audioFile;
      const nextIndex = state.currentQuestionIndex + 1;
      if (nextIndex === realQuestions.current.length) {
        generateResult();
        setState({ assessmentState: 3 });
      } else {
        handlerTimer();
        setState({
          currentQuestionIndex: nextIndex,
          timeInterval: 0,
        });
        setTimeout(() => {
          isDisbaleRecordingBtn.current = false;
        }, 500);
      }
    }
  };

  const handleSave = async (mediaBlobUrl: any) => {
    const audioURL = URL.createObjectURL(mediaBlobUrl);
    const audioBlob = await fetch(audioURL).then((r) => r.blob());

    let audioFile = new File([audioBlob], "voice.wav", {
      type: "audio/wav",
    });

    audioFile = await compressAudioFile(audioFile);
    onNextQuestion(audioFile);
  };

  const fetchLocalAudioFileBlob = async () => {
    const response = await fetch("/assets/audio/Recording.wav");
    const audioBlob = await response.blob();
    const audioFile = new File([audioBlob], "Recording.wav", {
      type: "audio/wav",
    });
    return audioFile;
  };

  // const onStopCountDown = async () => {
  //   clearInterval(timeIntervalRef.current);
  //   timeIntervalRef.current = null;
  //   setTimeout(async () => {
  //     const localFile = await fetchLocalAudioFileBlob();
  //     onNextQuestion(localFile);
  //   }, 250);
  // };

  // const formateRecordingTime = (second: any) => {
  //   second = 120 - second - state.timeInterval;
  //   if (isRecording && second === 0) {
  //     if (state.isDemoQuestionPerformed) isDisbaleRecordingBtn.current = true;
  //     stopRecording();
  //     setState({ isPlaying: false, timeInterval: 0 });
  //   } else if (!isRecording && second === 0) {
  //     onStopCountDown();
  //   }
  //   if (second < 60) {
  //     second = second.toString();
  //     return `0:${second.padStart(2, "0")}`;
  //   }
  //   const minutes = Math.floor(second / 60);
  //   const seconds = (second % 60).toString().padStart(2, "0");
  //   return `${minutes}:${seconds}`;
  // };

  // const handleStartRecording = () => {
  //   if (isDisbaleRecordingBtn.current) return;
  //   if (state.isDemoQuestionPerformed && isRecording) return;
  //   if (!state.isDemoQuestionPerformed && isRecording) {
  //     stopRecording();
  //   } else {
  //     recordedAudio.current = null;
  //     clearInterval(timeIntervalRef.current);
  //     timeIntervalRef.current = null;
  //     setTimeout(async () => {
  //       startRecording();
  //     }, 250);
  //   }
  // };

  // const handleSubmitAndContinue = async () => {
  //   if (!state.isDemoQuestionPerformed) {
  //     stopRecording();
  //     setState({
  //       showRealQuestionModal: true,
  //     });
  //   } else if (state.isDemoQuestionPerformed) {
  //     stopRecording();
  //   }
  // };

  // useEffect(() => {
  //   if (!recordingBlob) return;
  //   recordedAudio.current = recordingBlob;
  //   // if (!state.isDemoQuestionPerformed) {
  //   //   setState({ isStartRecording: false });
  //   // }
  //   handleSave(recordingBlob);
  // }, [recordingBlob]);

  // useEffect(() => {
  //   audioInstance.current.addEventListener("ended", function () {
  //     setState({ isPlaying: false });
  //   });
  //   return () => {
  //     if (isRecording) stopRecording();
  //     audioInstance.current.removeEventListener("ended", null);
  //   };
  // }, []);

  useEffect(() => {
    if (currentWidth > 650) {
      setState({ desktopMode: true });
    } else {
      setState({ desktopMode: false });
    }
  }, [useWindowSize().width]);

  return (
    <>
      <header className={`header ${manuBarExpend ? "body_pd" : ""}`}>
        <div className="w-25 d-flex align-items-center mob_100">
          <div className="header_toggle" onClick={() => manuBarToggle()}>
            {" "}
            <i
              className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
              id="header_toggle"
            />{" "}
          </div>
          <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center">
            <span className="d-block d-md-none me-3">
              <img src={Images.Logo_png} />
            </span>
            <span>Skills Assessment</span>
          </div>
        </div>
        {state?.assessmentState >= 0 && (
          <div className="w-50 text-center mob_100">
            <p className="text-uppercase">
              {/* {state?.assessmentState > 0 ? "Demo Question" : "Define Skillset"} */}
              {state?.assessmentState === 2
                ? `${progressbarHeadings[state?.assessmentState]} ${
                    state.currentQuestionIndex + 1
                  }`
                : progressbarHeadings[state?.assessmentState]}
            </p>
            <div>
              {Array(4)
                .fill(0)
                .map((da, index) => (
                  <span
                    key={index}
                    className={`bar ${
                      state?.assessmentState >= index ? "active" : ""
                    }`}
                  />
                ))}
            </div>
          </div>
        )}
      </header>

      {state?.assessmentState === -2 && (
        <AssessmentOnboarding handleAssessmentState={increaseAssessmentState} />
      )}

      {state?.assessmentState === -1 && (
        <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 mt-0 mt-md-3">
          <div className="p-3 p-md-5 bg_white justify-content-center border border_gray border_r_16px border_sm_r_0 w-100 middle_align">
            <div className="middle_box">
              <div className="d-flex justify-content-center icon_size_sm text-center mb-4">
                <img src={require("../../assets/imgs/skill.png")} />
              </div>
              <p className="fs-3 fw-bold text-center mb-4 text_black lh-sm">
                {state?.desktopMode
                  ? "Increase your chances of getting an interview by 4x by completing a 15-minute assessment"
                  : "You can only complete this test on desktop."}
              </p>
              <p className="fs-6 text_primary mt-4 text-center">
                {state?.desktopMode
                  ? "This test will assess your skills and will answer each question using your voice. Best of luck!"
                  : "Login on your computer to continue"}
              </p>
              {state?.desktopMode ? (
                <div className="text-center mt-4">
                  <button
                    disabled={state.isDisableBtn}
                    type="button"
                    className={`btn bg_secondary text_secondary mb-2 text_white w-100 ${
                      state.isDisableBtn ? "bg-rw-disable" : ""
                    }`}
                    onClick={() => {
                      increaseAssessmentState();
                      // setShowModal(true);
                    }}
                  >
                    Start Test
                  </button>
                  <button
                    type="button"
                    className="btn bg_light_secondary text_secondary w-100"
                    onClick={() => navigate("/settings")}
                  >
                    Complete Later
                  </button>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn bg_light_secondary text_secondary w-100"
                    onClick={() => navigate("/dashboard")}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {state?.assessmentState === 0 && (
        <div className="bg_light p-3 p-md-4 w-100 overflow-hidden">
          <div className="p-2 p-md-5 bg_white justify-content-center border border_gray border_r_16px">
            <div className="middle_box">
              <p className="text_primary fs-6 text-center pt-4 pt-md-0">
                Select up to 3 skills that you want to focus on for your
                assessment. Expect two questions per skill. Each question will
                allow 2 minutes for a response.
              </p>
              <p className="fw-bold fs-5 text_black text-center mt-5">
                Skills Examples
              </p>
              <div className="mt-3">
                {state.skillsList.map((data: string, index: number) => (
                  <span
                    key={index}
                    onClick={() => updateSkillSet(index, data)}
                    className={`skill_tags cursor_pointer ${
                      state?.skillSet?.find((item: any) => item.skill === data)
                        ? "active"
                        : ""
                    }`}
                  >
                    {data}
                  </span>
                ))}
              </div>
              {state?.skillSet?.length > 0 && (
                <div className="d-flex mt-4">
                  <div className="w-100">
                    <p className="fw-bold text_black font_18 mb-3">
                      Main Skills
                    </p>
                    {state?.skillSet.map((da: any, index: number) => (
                      <div key={index} className="input-group mb-3">
                        <input
                          // disabled
                          value={da.skill}
                          type="text"
                          className="form-control input_skills"
                          placeholder={da.skill}
                          onChange={(e) =>
                            handleChangeSkill(e.target.value, da.id)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {/* <div className="w-25">
                    <p className="fw-bold text_black font_18 mb-3">Rating</p>

                    {state?.skillSet.map((da: any, index: number) => (
                      <select
                        key={index}
                        value={skillLevel[da.level]}
                        onChange={(e) =>
                          updateSkillLevel(index, parseInt(e.target.value, 10))
                        }
                        className="select_style"
                      >
                        <option disabled>Level</option>
                        <option value="0">Low-Level</option>
                        <option value="1">Mid-Level</option>
                        <option value="2">High-Level</option>
                      </select>
                    ))}
                  </div> */}
                </div>
              )}
              <div className="text-center mt-4">
                <button
                  disabled={state.isDisableBtn}
                  type="button"
                  className={`btn bg_secondary text_secondary mb-2 text_white w-100 ${
                    state.isDisableBtn ? "bg-rw-disable" : ""
                  }`}
                  data-bs-toggle="modal"
                  onClick={() =>
                    state.skillSet[0].skill !== ""
                      ? setState({ showInfoModal: true })
                      : null
                  }
                  // onClick={() => increaseAssessmentState()}
                >
                  Start Test
                </button>
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  onClick={() => decreaseAssessmentState()}
                >
                  Back
                </button>
              </div>
              <p className="text_primary font_14 mt-2 pb-5 pb-md-0 mb-5 mb-md-0">
                Note: Please do not refresh the page or you’ll lose all of your
                data
              </p>
            </div>
          </div>
        </div>
      )}

      {(state?.assessmentState === 1 || state?.assessmentState === 2) && (
        <div className="bg_light p-3 p-md-4 w-100 overflow-hidden ">
          <div className="p-3 p-md-5 bg_white justify-content-center border border_gray border_r_16px min-vh-100">
            <div className="middle_box">
              <div className="recording_time">
                <span className="fs-3 me-3 text_secondary ps-2">
                  <i className="far fa-clock" aria-hidden="true" />
                </span>
                <span className="fw-bold text_black fs-5">
                  0:00
                  {/* {formateRecordingTime(recordingTime)} */}
                </span>
              </div>
              <p className="fs-5 fw-bold text-center text_black mt-4">
                {!state.isDemoQuestionPerformed
                  ? "Briefly explain what you enjoy doing in your free time."
                  : realQuestions.current[state.currentQuestionIndex]?.question}
              </p>

              <div className="bg_light p-5 text-center mt-5 border_r_8px">
                <span
                  // onClick={handleStartRecording}
                  // className={isRecording ? "mic start_recording" : "mic"}
                  className="mic"
                >
                  <i className="far fa-microphone-alt" />
                </span>
                <p className="font_14 fw-bold text_black mt-4">
                  {/* {!isRecording ? "Click to start recording" : "Recording..."} */}
                  "Click to start recording"
                </p>
              </div>
              <button
                // disabled={!isRecording}
                type="button"
                // className={`btn text_light_primary mt-4 mb-2 w-100 d-flex justify-content-center ${
                //   isRecording ? "bg_secondary" : "bg_primary_dark"
                // }`}
                className="btn text_light_primary mt-4 mb-2 w-100 d-flex justify-content-center bg_primary_dark"
                data-bs-toggle="modal"
                data-bs-target="#greatjobModal"
                // onClick={handleSubmitAndContinue}
              >
                Submit & Continue
                {/* {isRecording && (
                  <img src={Images.Submit_icon} className="ms-3" />
                )} */}
              </button>
              {!state.isDemoQuestionPerformed && recordedAudio.current && (
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary mt-2 mb-2 w-100"
                  // onClick={playAudio}
                >
                  {`${!state.isPlaying ? "Play" : "Pause"} Animation`}
                  <span className="text_secondary ms-2">
                    <i
                      className={`fa-sharp fa-solid fa-circle-${
                        state.isPlaying ? "pause" : "play"
                      }`}
                    />
                  </span>
                </button>
              )}
              <p className="text_primary font_14 mt-2 pb-5 pb-md-0 mb-5 mb-md-0">
                Note: Please do not refresh the page or you’ll lose all of your
                data
              </p>
            </div>
          </div>
        </div>
      )}

      {state.assessmentState === 3 && (
        <div className="bg_light p-3 p-md-4 w-100 overflow-hidden min-vh-100 ">
          <div className="middle_box">
            <div className="p-3 p-md-5 bg_white justify-content-center border border_gray border_r_16px">
              <p className="fs-5 fw-bold text-center text_black mt-4">
                Assessment Completed
              </p>
              <p className="fs-3 fw-bold text-center">
                Generating your Results
              </p>

              <div className="d-flex justify-content-center text-center">
                <img
                  src={Images.AppLoaderGif}
                  className=""
                  style={{ width: 120, height: 120, marginTop: 20 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}

      {!isChrome && (
        <Modal show={!isChrome} centered>
          <Modal.Body style={{ padding: 0, margin: 0 }}>
            <div className="modal-content p-4">
              <div className="modal-body">
                <div className="d-flex justify-content-center text-center mb-4">
                  <img src={chromeLogo} />
                </div>
                <p className="fs-4 fw-bold text-center mb-4 text_black lh-sm">
                  This test is only available on Google Chrome.
                </p>
                <p className="fs-6 text_primary mt-4 text-center">
                  We apologize for the inconvenience!
                </p>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn bg_secondary text_secondary text_white w-100"
                  data-bs-dismiss="modal"
                  onClick={() => navigate("/settings")}
                >
                  Back to Profile
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {state?.showInfoModal && (
        <Modal
          show={state?.showInfoModal}
          centered
          onHide={() => setState({ showInfoModal: false })}
        >
          <Modal.Body
            style={{
              padding: 0,
              paddingBottom: 0,
              margin: 0,
            }}
          >
            <div className="modal-content p-4 border_r_8px">
              <div className="modal-body p-0">
                <p className="fw-bold text-center text_black fs-4 mb-3">
                  Important Points
                </p>
                <ul>
                  <li className="mb-3">
                    Please note: This test is timed and you will have two
                    minutes to answer each question.
                  </li>
                  <li className="mb-3">
                    You will answer verbally, so ensure you are in a quiet
                    environment.
                  </li>
                  <li className="mb-3">
                    You can’t re-record the questions, please take a moment and
                    think about your answers per questions.
                  </li>
                </ul>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn bg_secondary text_secondary text_white w-100"
                  data-bs-target="#important_pointsToggle2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    setState({ showAudioTestModal: true, showInfoModal: false })
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {state?.showAudioTestModal && (
        <TestMicrophone
          showAudioTestModal={state?.showAudioTestModal}
          hideModal={() => setState({ showAudioTestModal: false })}
          onContinue={() => {
            setState({
              showDemoQuestionModal: true,
              showAudioTestModal: false,
            });
          }}
        />
      )}

      {state?.showDemoQuestionModal && (
        <Modal
          show={state?.showDemoQuestionModal}
          centered
          onHide={() => setState({ showDemoQuestionModal: false })}
        >
          <Modal.Body
            style={{
              padding: 0,
              paddingBottom: 0,
              margin: 0,
            }}
          >
            <div className="modal-content p-4 border_r_8px">
              <div className="modal-body p-0">
                <p className="fw-bold text-center text_black fs-4 mb-3">
                  First question is a demo question.
                </p>
                <ul className="number_style">
                  <li>
                    <span>1</span>
                    <p>First, think about your answer.</p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>The click on the mic icon to start recording.</p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>
                      Once you finish, you can click “submit & continue” button
                      and move to the next question which will be a real one.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn bg_secondary text_secondary text_white w-100"
                  data-bs-target=""
                  onClick={() => {
                    increaseAssessmentState();
                    setState({ showDemoQuestionModal: false });
                  }}
                >
                  Continue to Demo Question
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {state?.showRealQuestionModal && (
        <Modal
          show={state?.showRealQuestionModal}
          centered
          onHide={() => setState({ showRealQuestionModal: false })}
        >
          <Modal.Body
            style={{
              padding: 0,
              paddingBottom: 0,
              margin: 0,
            }}
          >
            <div className="modal-content p-4">
              <div className="modal-body">
                <div className="d-flex justify-content-center text-center mb-4">
                  <img src={require("../../assets/imgs/fire.png")} />
                </div>
                <p className="fs-4 fw-bold text-center mb-4 text_black lh-sm">
                  Great Job
                </p>
                <p className="fs-6 text_primary mt-4 text-center">
                  You have successfully completed the demo question! now you are
                  ready to answer real questions.
                </p>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn bg_secondary text_secondary text_white w-100"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    increaseAssessmentState();
                    handlerTimer();
                    setState({
                      showRealQuestionModal: false,
                      isDemoQuestionPerformed: true,
                    });
                  }}
                >
                  Continue to real questions
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default SkillAssessment;
