import React from "react";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import AssessmentTask from "../AssessmentTask";

type Props = {
  isStudent: boolean;
  assessmentTaskList: any;
};

const GetStarted = ({ assessmentTaskList, isStudent }: Props) => {
  const [state, setState] = useMergeState({
    showAssessmentTasks: true,
  });
  return (
    <div
      className="bg_white px-4 py-3 border_r_16px border border_gray mb-4"
      style={{ paddingTop: 26 }}
    >
      <div className="collapseDiv">
        <div>
          <p className="font_14 text_black">GET STARTED</p>
          <p
            className={`text_black fw-bold fs-5 ${
              state?.showAssessmentTasks ? "mb-3" : ""
            }`}
            style={{
              fontSize: 18,
              fontWeight: "bold",
              fontFamily: "system-ui",
            }}
          >
            {isStudent
              ? "Complete these items to stand out"
              : "Complete these items to complete your profile."}
          </p>
        </div>
        <div
          className="text_secondary fw_500 fs-6"
          role="button"
          tabIndex={0}
          aria-expanded="false"
          aria-controls="collapseExample"
          style={{
            position: "sticky",
            right: 30,
          }}
          onClick={() =>
            setState({ showAssessmentTasks: !state.showAssessmentTasks })
          }
        >
          <p>{state.showAssessmentTasks ? "COLLAPSE" : "EXPAND"}</p>
        </div>
      </div>

      <div
        className="assessmentTaskParent"
        style={{
          flexDirection: useWindowSize().width < 700 ? "column" : "row",
          // justifyContent:
          //   user?.accountType === "STUDENT" ? "normal" : "space-between",
        }}
      >
        {state?.showAssessmentTasks
          ? assessmentTaskList.map((data: any, index: number) => (
              <AssessmentTask
                key={index}
                title={data.title}
                taskDescription={data.taskDescription}
                link={data.link}
                img={data.imageLink}
                changeWidth={
                  isStudent || assessmentTaskList.length === 2 ? true : false
                }
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default GetStarted;
